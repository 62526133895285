/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface Multimedia { 
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    path?: string;
    /**
     * 
     */
    width?: number;
    /**
     * 
     */
    height?: number;
    /**
     * 
     */
    size?: number;
    /**
     * 
     */
    type?: Multimedia.TypeEnum;
    /**
     * Fit types are described here:  https://api.flutter.dev/flutter/painting/BoxFit.html
     */
    fit_type?: Multimedia.FitTypeEnum;
    /**
     * 
     */
    url?: string;
}
export namespace Multimedia {
    export type TypeEnum = 'IMAGE' | 'VIDEO';
    export const TypeEnum = {
        Image: 'IMAGE' as TypeEnum,
        Video: 'VIDEO' as TypeEnum
    };
    export type FitTypeEnum = 'FILL' | 'CONTAIN' | 'COVER' | 'FIT_WIDTH' | 'FIT_HEIGHT' | 'NONE' | 'SCALE_DOWN';
    export const FitTypeEnum = {
        Fill: 'FILL' as FitTypeEnum,
        Contain: 'CONTAIN' as FitTypeEnum,
        Cover: 'COVER' as FitTypeEnum,
        FitWidth: 'FIT_WIDTH' as FitTypeEnum,
        FitHeight: 'FIT_HEIGHT' as FitTypeEnum,
        None: 'NONE' as FitTypeEnum,
        ScaleDown: 'SCALE_DOWN' as FitTypeEnum
    };
}


