<div class="flexible-column-centered content" *transloco="let t">
  <div class="icon-close-container" (click)="onClickCloseDialog()">
    <mat-icon class="icon-close" svgIcon="icon_x"></mat-icon>
  </div>

  <div class="icon-container">
    <mat-icon class="icon" svgIcon="icon_hourglass"></mat-icon>
  </div>

  <h2
    mat-dialog-title
    class="title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("products.products_dialog_incoming.title") }}
  </h2>

  <p
    class="description"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
  >
    {{ t("products.products_dialog_incoming.description") }}
  </p>

  <form
    *ngIf="!loading"
    [formGroup]="form"
    class="fill-width form"
    (ngSubmit)="submit()"
  >
    <app-form-input-field
      class="input"
      [input]="{
        label: t('shared.popup_request_mail.email'),
        errorMessage: t('shared.popup_request_mail.invalid_email'),
        controller: getFormController('email'),
        maxLength: '100',
        type: 'text'
      }"
    >
    </app-form-input-field>

    <button
      type="submit"
      class="button"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small': isSmall,
        'button-2-xsmall': isXSmall
      }"
      mat-stroked-button
    >
      {{ t("shared.popup_request_mail.button") }}
    </button>
  </form>

  <app-circular-progress-indicator
    *ngIf="loading"
    class="loading"
  ></app-circular-progress-indicator>
</div>
