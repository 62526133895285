import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-search-empty',
  templateUrl: './products-search-empty.component.html',
  styleUrls: ['./products-search-empty.component.scss']
})
export class ProductsSearchEmptyComponent extends BaseComponent implements OnInit {
  @Input()
  query: string;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }
}
