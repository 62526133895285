import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupRequestMailComponent } from '../../../popup-request-mail/components/popup-request-mail/popup-request-mail.component';
import { CookieService } from '../../services/cookie/cookie.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<PopupRequestMailComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cookieService: CookieService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {}

  acceptCookie() {
    this.cookieService.acceptAll();
  }

  launchPopup() {
    this.cookieService.launchPopup();
  }
}
