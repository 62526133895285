import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { MatExpansionPanelItem } from '../home-mat-expansion-panel/home-mat-expansion-panel.component';

@Component({
  selector: 'app-home-title-list-button',
  templateUrl: './home-title-list-button.component.html',
  styleUrls: ['./home-title-list-button.component.scss']
})
export class HomeTitleListButtonComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router 
  ) {
    super(responsive, transloco);
  }

  @Input()
  titleTranslocoKey: string;
  @Input()
  items: MatExpansionPanelItem[];
  @Input()
  btnRouterLink: string;
  @Input()
  btnLabelTranslocoKey: string;
  @Input()
  isBtnFlat = false;

  onBtnClick(): void {
    if (this.btnRouterLink != null) {
      this.router.navigate([this.btnRouterLink]);
    }
  }

  ngOnInit(): void {}
}
