<app-home-info-item
  *transloco="let t"
  [item]="{
    image: 'assets/images/img_i_nostri_gioielli.jpg',
    title: t('home.shared.jewels.title'),
    description: t('home.shared.jewels.description'),
    button: t('home.shared.jewels.button'),
    route: '/products'
  }"
  [imageFirst]="true"
></app-home-info-item>

<!--div *transloco="let t" [ngClass]="{'content': !isMobile}">
    <ng-container *ngIf="!isMobile; else mobile">
        <div class="flexible fill-height">
            <div class="half-width-container">
                <img class="image-fill-cover" src="assets/images/img_i_nostri_gioielli.jpg">
            </div>
            <div class="half-width-container">
                <div class="content-desktop flexible-column-centered">
                    <h2 class="text-3 text-align-left">{{ t('home.shared.jewels.title') }}</h2>
                    <p class="text-5 text-align-left description"
                        [innerHTML]="'home.shared.jewels.description' | transloco"></p>
                    <button class="button-3" routerLink="/products" mat-stroked-button>{{ t('home.shared.jewels.button')
                        }}</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #mobile>
        <div class="flexible-column-centered">
            <div [ngClass]="{'image-mobile-small': isSmall, 'image-mobile-xsmall': isXSmall}">
                <img class="image-fill-cover" src="assets/images/img_i_nostri_gioielli.jpg">
            </div>

            <div class="content-mobile ">
                <h2 class="text-align-left" [ngClass]="{'text-3-small': isSmall, 'text-3-xsmall': isXSmall}">{{
                    t('home.shared.jewels.title') }}</h2>
                <p class="text-align-left description"
                    [ngClass]="{'text-5-small': isSmall, 'text-5-xsmall': isXSmall}"
                    [innerHTML]="'home.shared.jewels.description' | transloco"></p>
                <button [ngClass]="{'button-3-small': isSmall, 'button-3-xsmall': isXSmall}" routerLink="/products"
                    mat-stroked-button>{{ t('home.shared.jewels.button')
                    }}</button>
            </div>
        </div>
    </ng-template>
</div-->
