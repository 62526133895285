<div [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'" class="flexible-column-centered"
    *transloco="let t">

    <img [ngClass]="{'image': !isMobile, 'image-mobile-small': isSmall, 'image-mobile-xsmall': isXSmall}" src="{{header.imgSrc}}">
    
    <div [ngClass]="!isMobile ? 'content' : 'content-mobile'">
        <h2 [ngClass]="{'text-3': !isMobile, 'text-3-small': isSmall, 'text-3-xsmall': isXSmall}"
            class="title">{{ t(header.titleTranslocoKey) }}</h2>
        <div class="separator"></div>
    </div>

</div>