<div *transloco="let t"
    [ngClass]="{'content header-margin': !isMobile, 'header-margin-mobile': isMobile, 'content-small': isSmall, 'content-xsmall': isXSmall}">

    <div class="flexible-column-centered">
        <h1 class="title"
            [ngClass]="{'text-3': !isMobile, 'text-3-small': isSmall, 'text-3-xsmall': isXSmall}">
            {{ t('home.page_not_found.title') }}
        </h1>

        <div class="flexible-column-centered"
            [ngClass]="{'content-description': !isMobile, 'content-description-small': isSmall, 'content-description-xsmall': isXSmall}">
            <p
                [ngClass]="{'text-5 description': !isMobile, 'description-mobile': isMobile, 'text-5-small': isSmall, 'text-5-xsmall': isXSmall}">
                {{ t('home.page_not_found.description') }}
            </p>
            <button class="button"
                [ngClass]="{'button-3': !isMobile, 'button-3-small': isSmall, 'button-3-xsmall': isXSmall}"
                routerLink="/home" mat-stroked-button>
                {{ t('home.page_not_found.button') }}
            </button>
        </div>
    </div>


</div>