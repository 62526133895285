<div class="header-margin flexible-column-centered" *transloco="let t">
  <h1
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-2': !isMobile,
      'text-2-small': isSmall,
      'text-2-xsmall': isXSmall
    }"
  >
    {{ t("home.about_us.section_c.title") }}
  </h1>

  <!-- figaggine con l'animazione scalata sul numero di item (le immagini sono pesanti e lagga quindi commento)-->
  <!--div
      *ngFor="let item of items; let i = index"
      class="item-flex-properties animated-fade-in-from-bottom to-animate"
      [ngClass]="'item-animation-delay-' + (i + 2)"
    >
      <app-home-about-us-section-c-item [item]="item">
      </app-home-about-us-section-c-item>
    </div-->

  <div
    *ngIf="items != undefined"
    [ngClass]="{ 'flexible content-items': !isMobile }"
    class="animated-fade-in-from-bottom animation-delay-2 to-animate"
  >
    <app-home-about-us-section-c-item
      class="item-flex-properties"
      *ngFor="let item of items"
      [item]="item"
    >
    </app-home-about-us-section-c-item>
  </div>
</div>
