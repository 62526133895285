import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface ProductsNavigationPathItem {
  title: string;
  route: string;
}

@Component({
  selector: 'app-products-navigation-path',
  templateUrl: './products-navigation-path.component.html',
  styleUrls: ['./products-navigation-path.component.scss']
})
export class ProductsNavigationPathComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  path: ProductsNavigationPathItem[];

  ngOnInit(): void {
    
  }
}
