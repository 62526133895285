/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UserDate { 
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    date?: string;
    /**
     * 
     */
    type?: UserDate.TypeEnum;
}
export namespace UserDate {
    export type TypeEnum = 'BIRTHDAY' | 'ANNIVERSARY' | 'MARRIAGE' | 'OTHER';
    export const TypeEnum = {
        Birthday: 'BIRTHDAY' as TypeEnum,
        Anniversary: 'ANNIVERSARY' as TypeEnum,
        Marriage: 'MARRIAGE' as TypeEnum,
        Other: 'OTHER' as TypeEnum
    };
}


