import { Injectable } from '@angular/core';
import { Configuration, User, UserService } from 'src/app/openapi/api';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Router } from '@angular/router';
import { lastValueFrom, Observable, retry } from 'rxjs';
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { TranslocoService } from '@ngneat/transloco';

export namespace AuthService {
  export type AuthProviderEnum = 'GOOGLE' | 'FACEBOOK' | 'PASSWORD';
  export const AuthProviderEnum = {
      Google: 'GOOGLE' as AuthProviderEnum,
      Facebook: 'FACEBOOK' as AuthProviderEnum,
      Password: 'PASSWORD' as AuthProviderEnum
  };
}

interface ExtraData {
  birthday?: string,
  gender?: User.GenderEnum
}

import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user?: firebase.User;

  apiCredentials: { [key: string]: string | (() => string | undefined) };
  isMobile: boolean;

  constructor(
    private apiConfiguration: Configuration,
    private firebaseAuth: AngularFireAuth,
    public router: Router,
    private userApi: UserService,
    private transloco: TranslocoService,
    protected httpClient: HttpClient
  ) {
    apiConfiguration.credentials = {
      'api-key': environment.apiKey,
    };

    this.firebaseAuth.authState.subscribe(async (user) => {
      if (user != undefined) {
        this.user = user;
      } else {
        this.user = undefined;
      }
    });
  }

  isAuthenticated(): boolean {
    return this.user != undefined;
  }

  getAuthenticationProvider(): AuthService.AuthProviderEnum | undefined {
    if(!this.isAuthenticated()) {
      return undefined;
    }

    const map = [
      {
        key: AuthService.AuthProviderEnum.Google,
        value: "google.com"
      },
      {
        key: AuthService.AuthProviderEnum.Facebook,
        value: "facebook.com"
      },
      {
        key: AuthService.AuthProviderEnum.Password,
        value: "password"
      }
    ];

    return map.filter(e => e.value == this.user?.providerData[0]?.providerId)[0].key;
  }

  async updateApiCredentials() {
    let bearerToken = (await this.user?.getIdToken()) ?? '';

    this.apiConfiguration.credentials = {
      'api-key': environment.apiKey,
      'bearer-auth': bearerToken,
    };
  }

  async signUp(user: User, password: string) {
    //https://stackoverflow.com/questions/67580158/cant-catch-exception-in-angularfireauth
    //const credentials = await this.firebaseAuth.createUserWithEmailAndPassword(user.email!, password);

    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email!, password);
    this.user = credentials.user!;

    await this.postUserProfile(user, credentials);
    //TODO await credentials.user?.sendEmailVerification();
  }

  async googleSignIn() {
    //https://stackoverflow.com/questions/67580158/cant-catch-exception-in-angularfireauth
    //https://github.com/angular/angularfire/issues/3208
    //https://stackoverflow.com/questions/62214822/trying-to-get-birthday-and-gender-with-google-people-api-in-angular

    let provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
    provider.addScope('https://www.googleapis.com/auth/user.gender.read')

    const credentials: firebase.auth.UserCredential = await firebase
      .auth()
      .signInWithPopup(provider);
    this.user = credentials.user!;

    if (credentials.additionalUserInfo?.isNewUser) {
      const profile: any = credentials.additionalUserInfo?.profile;

      let extraData: ExtraData = {
        birthday: undefined,
        gender: undefined
      };

      if (profile != undefined &&
        (credentials.credential as firebase.auth.OAuthCredential).accessToken != undefined) {
          // call Google People API to obtain birthday and gender information
          let accessToken = (credentials.credential as firebase.auth.OAuthCredential).accessToken!;
          extraData = await this.getGoogleExtraData(profile.id, accessToken);
      }

      let user: User = {
        name: profile.given_name ?? '',
        surname: profile.family_name ?? '',
        language: this.transloco.getActiveLang() as User.LanguageEnum,
        email: credentials.user!.email!,
        birthday: extraData.birthday,
        gender: extraData.gender
      };

      await this.postUserProfile(user, credentials);
    }
  }

  async getGoogleExtraData(profileId: string, accessToken: string): Promise<ExtraData> {
    let url = `https://people.googleapis.com/v1/people/${profileId}?personFields=birthdays,genders&access_token=${accessToken}`;
    let response = await lastValueFrom(this.httpClient.get<any>(url));

    let birthday: string | undefined = undefined;
    if (response?.birthdays != undefined) {
      let birthdays: Array<any> = response!.birthdays;
      birthdays.forEach(item => {
        let primary: boolean = item.metadata?.primary ?? false;
        // check if the date is complete (it could be that there is only day and month for instance)
        let date = item.date;
        if (date?.day != undefined && date?.month != undefined && date?.year != undefined) {
          if (primary || birthday == undefined) {
            birthday = new Date(date.year, date.month - 1, date.day, 0, 0, 0).toISOString();
          }
        }
      });
    }

    let gender: string | undefined = undefined;
    if (response?.genders != undefined) {
      let genders: Array<any> = response!.genders;
      genders.forEach(item => {
        let primary: boolean = item.metadata?.primary ?? false;
        let value = item.value;
        if (value != undefined && (primary || gender == undefined)) {
          gender = value;
        }
      });
    }

    return {
      birthday: birthday,
      gender: gender == "male" ? User.GenderEnum.Male :
        gender == "female" ? User.GenderEnum.Female :
        gender != undefined ? User.GenderEnum.Other :
        undefined
    };
  }

  async facebookSignIn() {
    //https://stackoverflow.com/questions/67580158/cant-catch-exception-in-angularfireauth
    ///https://github.com/angular/angularfire/issues/3208

    let provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');

    // vengono ignorate perché l'app deve essere analizzata da facebook
    // https://developers.facebook.com/docs/permissions/reference
    // https://developers.facebook.com/docs/app-review
    provider.addScope('user_birthday');
    provider.addScope('user_gender');

    const credentials: firebase.auth.UserCredential = await firebase
      .auth()
      .signInWithPopup(provider);

    //console.log("cred", credentials);

    this.user = credentials.user!;

    if (credentials.additionalUserInfo?.isNewUser) {
      const profile: any = credentials.additionalUserInfo?.profile;

      let user: User = {
        name: profile.given_name ?? '',
        surname: profile.family_name ?? '',
        language: this.transloco.getActiveLang() as User.LanguageEnum,
        email: credentials.user!.email!,
      };

      //console.log("user", user);

      await this.postUserProfile(user, credentials);
    }
  }

  /*async getFacebookExtraData(profileId: string, accessToken: string): Promise<ExtraData> {
    let url = `https://graph.facebook.com/${userId}?fields=birthday,gender&access_token=${accessToken}`;
    let response = await lastValueFrom(this.httpClient.get<any>(url));

    let birthday: string | undefined = undefined;
    if (response?.birthdays != undefined) {
      let birthdays: Array<any> = response!.birthdays;
      birthdays.forEach(item => {
        let primary: boolean = item.metadata?.primary ?? false;
        // check if the date is complete (it could be that there is only day and month for instance)
        let date = item.date;
        if (date?.day != undefined && date?.month != undefined && date?.year != undefined) {
          if (primary || birthday == undefined) {
            birthday = new Date(date.year, date.month - 1, date.day, 0, 0, 0).toISOString();
          }
        }
      });
    }

    let gender: string | undefined = undefined;
    if (response?.genders != undefined) {
      let genders: Array<any> = response!.genders;
      genders.forEach(item => {
        let primary: boolean = item.metadata?.primary ?? false;
        let value = item.value;
        if (value != undefined && (primary || gender == undefined)) {
          gender = value;
        }
      });
    }

    return {
      birthday: birthday,
      gender: gender == "male" ? User.GenderEnum.Male :
        gender == "female" ? User.GenderEnum.Female :
        gender != undefined ? User.GenderEnum.Other :
        undefined
    };
  }*/

  async signIn(email: string, password: string) {
    const credentials = await this.firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );
    this.user = credentials.user!;
  }

  async signOut() {
    await this.firebaseAuth.signOut();
    this.user = undefined;
    this.router.navigate(['/authentication']);
  }

  private async postUserProfile(
    user: User,
    credentials: firebase.auth.UserCredential
  ) {
    user.id = credentials.user?.uid;

    await this.updateApiCredentials();
    const userDB: User = await lastValueFrom(
      this.userApi.postUserProfile(user).pipe(retry(3))
    );
  }

  public async sendResetPassword(email: string) {
    await this.firebaseAuth.sendPasswordResetEmail(email);
  }

  public async updatePassword(oldPassword: string, newPassword: string) {
    const user = firebase.auth().currentUser;

    //https://stackoverflow.com/a/37812541
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(
      auth.currentUser!.email!,
      oldPassword
    );
    const result = await reauthenticateWithCredential(
      auth.currentUser!,
      credential
    );

    await user?.updatePassword(newPassword);
  }
}
