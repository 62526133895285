import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface ProductCategoryItem {
  title: string;
  description: string;
  button: string;
  image: string;
  image_over?: string;
  route: string;
}

@Component({
  selector: 'app-product-categories-item',
  templateUrl: './product-categories-item.component.html',
  styleUrls: ['./product-categories-item.component.scss'],
})
export class ProductCategoriesItemComponent
  extends BaseComponent
  implements OnInit
{
  mouseOver: boolean = false;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router,
  ) {
    super(responsive, transloco);
  }

  @Input()
  item: ProductCategoryItem;

  ngOnInit(): void {}

  onClickItem() {
    this.router.navigate([this.item.route]);
  }
}
