<app-home-privacy-terms-header
  [header]="header"
></app-home-privacy-terms-header>

<div [ngClass]="!isMobile ? 'content' : 'content-mobile'">
  <img
    [ngClass]="{
      image: !isMobile,
      'image-mobile-small': isSmall,
      'image-mobile-xsmall': isXSmall
    }"
    src="assets/images/img_state_aid.jpg"
  />
</div>

<app-home-privacy-terms-body
  [contentTranslocoKey]="'home.state-aid.content'"
></app-home-privacy-terms-body>
