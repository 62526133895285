import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

interface LanguageItem {
  key: "it" | "en";
  value: string;
  selected: boolean;
}

@Component({
  selector: 'app-core-header-language-selector',
  templateUrl: './core-header-language-selector.component.html',
  styleUrls: ['./core-header-language-selector.component.scss'],
})
export class CoreHeaderLanguageSelectorComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private localStorageService: LocalStorageService,
    private authService: AuthenticationService,
    private userApi: UserService
  ) {
    super(responsive, transloco);
  }

  languages: LanguageItem[];

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('core.header.languages')
      .subscribe((result) => {
        this.languages = result;

        let currentLanguage = this.transloco.getActiveLang();

        this.languages.forEach((e) => {
          e.selected = e.key == currentLanguage;
        });
      });
  }

  languageSelected(item: LanguageItem) {
    this.languages.forEach((e) => {
      e.selected = e.key == item.key;
    });

    // set transloco
    this.transloco.setActiveLang(item.key);

    // set user preferences in local storage
    const userPreferences = this.localStorageService.userPreferences.get();
    userPreferences.language = item.key;
    this.localStorageService.userPreferences.set(userPreferences);

    // if authenticated, set language to backend
    if (this.authService.isAuthenticated()) {
      this.setBackendLanguage(item.key);
    }
  }

  async setBackendLanguage(lang: string) {
    try {

      await this.authService.updateApiCredentials();
      await lastValueFrom(
        this.userApi.putUserProfileLanguage({content: lang}).pipe(retry(3))
      );

    } catch(error) {
      console.log("Cannot send language to backend");
    }
  }

  isActiveLanguage(item: LanguageItem) {
    let currentLanguage = this.transloco.getActiveLang();
    return currentLanguage == item.key;
  }
}
