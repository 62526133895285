<ng-container *ngIf="!isMobile; else mobile">
  <div class="content flexible-row-centered">
    <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
    <div class="content-text">
      <p class="text-7 text-align-left title">{{ item.title }}</p>
      <p
        class="text-7 text-align-left description"
        innerHTML="{{ item.description }}"
      ></p>
    </div>
    <div *ngIf="hasSeparator" class="vertical-separator"></div>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="content flexible-column">
    <div class="flexible-row-centered">
      <mat-icon class="icon-mobile" svgIcon="{{ item.icon }}"></mat-icon>
      <div class="content-text">
        <p
          class="title text-align-left"
          [ngClass]="{ 'text-7-small': isSmall, 'text-7-xsmall': isXSmall }"
        >
          {{ item.title }}
        </p>
        <p
          class="description text-align-left"
          [ngClass]="{ 'text-7-small': isSmall, 'text-7-xsmall': isXSmall }"
          innerHTML="{{ item.description }}"
        ></p>
      </div>
    </div>
    <div *ngIf="hasSeparator" class="horizontal-separator"></div>
  </div>
</ng-template>
