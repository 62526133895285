import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-home-home-page',
  templateUrl: './home-home-page.component.html',
  styleUrls: ['./home-home-page.component.scss']
})
export class HomeHomePageComponent extends BaseComponent implements OnInit {

  class: string;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  ngOnInit() {
    this.fixAnimationStart();
  }

  ngAfterViewChecked(){
    this.computeAnimations();
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.computeAnimations();
  }
}
