import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowAlignedItemsComponent } from './row-aligned-items.component';
import { MatIconModule } from '@angular/material/icon';

const materialModules = [
  MatIconModule
]

@NgModule({
  declarations: [
    RowAlignedItemsComponent
  ],
  imports: [
    CommonModule,
    ...materialModules
  ],
  exports: [
    RowAlignedItemsComponent
  ],
})
export class RowAlignedItemsModule { }
