<div class="content">
  <p
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    class="text-align-left message"
    [innerHTML]="
      'products.products_search.list.message'
        | transloco : { query: query, quantity: products.length }
    "
  ></p>

  <app-products-list-item
    [ngClass]="{
      item: !isMobile,
      'item-small': isSmall,
      'item-xsmall': isXSmall
    }"
    *ngFor="let product of products"
    [product]="product"
    [favorites]="favorites"
  ></app-products-list-item>
</div>
