import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-home-innovation',
  templateUrl: './home-innovation.component.html',
  styleUrls: ['./home-innovation.component.scss']
})
export class HomeInnovationComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.fixAnimationStart();
  }

  ngAfterViewChecked(){
    this.computeAnimations();
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.computeAnimations();
  }
}
