import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HomePageHeader } from 'src/app/modules/home/shared/components/home-page-header/home-page-header.component';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-about-us-section-a',
  templateUrl: './home-about-us-section-a.component.html',
  styleUrls: ['./home-about-us-section-a.component.scss']
})
export class HomeAboutUsSectionAComponent extends BaseComponent implements OnInit {
  
  aboutUsHeader : HomePageHeader;
  
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.aboutUsHeader = {
      imgSrc: "assets/images/img_jewels_flowers.jpg",
      titleTranslocoKey: "home.about_us.section_a.title",
      descriptionTranslocoKey: "home.about_us.section_a.description"
    };
  }
}
