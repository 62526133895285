import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface FooterTopItem {
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-core-footer-top-item',
  templateUrl: './core-footer-top-item.component.html',
  styleUrls: ['./core-footer-top-item.component.scss']
})
export class CoreFooterTopItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  item: FooterTopItem;

  @Input()
  hasSeparator: boolean;

  ngOnInit(): void {
  }
}
