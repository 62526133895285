import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { ProductListFilterItem } from './components/products-list-filter-item/products-list-filter-item.component';

@Component({
  selector: 'app-products-list-filter',
  templateUrl: './products-list-filter.component.html',
  styleUrls: ['./products-list-filter.component.scss']
})
export class ProductsListFilterComponent extends BaseComponent implements OnInit {
  items: ProductListFilterItem[];

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'products.products_list.filters.filter.items'
    ).subscribe(result => {
      this.items = result;
    });
  }
}
