import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

/*
This service is responsible to load and sanitize svg icons that will be used by Material mat-icon, as described here:
https://material.angular.io/components/icon/overview#svg-icons
https://www.digitalocean.com/community/tutorials/angular-custom-svg-icons-angular-material
*/
@Injectable({
  providedIn: 'root'
})
export class SvgIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('icon_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_arrow.svg"));
    this.matIconRegistry.addSvgIcon('icon_cart', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_cart.svg"));
    this.matIconRegistry.addSvgIcon('icon_color_palette', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_color_palette.svg"));
    this.matIconRegistry.addSvgIcon('icon_contacts', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_contacts.svg"));
    this.matIconRegistry.addSvgIcon('icon_find', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_find.svg"));
    this.matIconRegistry.addSvgIcon('icon_heart', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_heart.svg"));
    this.matIconRegistry.addSvgIcon('icon_heart_filled', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_heart_filled.svg"));
    this.matIconRegistry.addSvgIcon('icon_location', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_location.svg"));
    this.matIconRegistry.addSvgIcon('icon_menu_burger', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_menu_burger.svg"));
    this.matIconRegistry.addSvgIcon('icon_secure_pay', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_secure_pay.svg"));
    this.matIconRegistry.addSvgIcon('icon_share', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_share.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_facebook', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_facebook.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_google', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_google.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_instagram', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_instagram.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_tiktok', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_tiktok.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_twitter', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_twitter.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_youtube', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_youtube.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_linkedin', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_linkedin.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_telegram', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_telegram.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_whatsapp.svg"));
    this.matIconRegistry.addSvgIcon('icon_social_phone', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_social_phone.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_1_number', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_1_number.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_1', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_1.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_2_number', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_2_number.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_2', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_2.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_3_number', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_3_number.svg"));
    this.matIconRegistry.addSvgIcon('icon_step_3', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_step_3.svg"));
    this.matIconRegistry.addSvgIcon('icon_store', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_store.svg"));
    this.matIconRegistry.addSvgIcon('icon_trash', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_trash.svg"));
    this.matIconRegistry.addSvgIcon('logo_rem', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/logo_REM.svg"));
    this.matIconRegistry.addSvgIcon('logo_rem_icon', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/logo_REM_icon.svg"));
    this.matIconRegistry.addSvgIcon('icon_language', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_language.svg"));
    this.matIconRegistry.addSvgIcon('icon_user', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_user.svg"));
    this.matIconRegistry.addSvgIcon('icon_login_facebook', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_login_facebook.svg"));
    this.matIconRegistry.addSvgIcon('icon_login_google', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_login_google.svg"));
    this.matIconRegistry.addSvgIcon('icon_edit_pencil', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_edit_pencil.svg"));
    this.matIconRegistry.addSvgIcon('icon_x', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_x.svg"));
    this.matIconRegistry.addSvgIcon('icon_alert', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_alert.svg"));
    this.matIconRegistry.addSvgIcon('icon_instructions_upload', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_instructions_upload.svg"));
    this.matIconRegistry.addSvgIcon('icon_instructions_approach', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_instructions_approach.svg"));
    this.matIconRegistry.addSvgIcon('icon_instructions_delivery', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_instructions_delivery.svg"));
    this.matIconRegistry.addSvgIcon('icon_instructions_envelope', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_instructions_envelope.svg"));
    this.matIconRegistry.addSvgIcon('icon_instructions_click_link', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_instructions_click_link.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_care', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_care.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_work', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_work.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_orders', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_orders.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_returns', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_returns.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_care_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_care_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_delivery', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_delivery.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_work_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_work_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_orders_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_orders_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_technology', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_technology.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_returns_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_returns_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_delivery_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_delivery_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_technology_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_technology_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_discount', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_discount.svg"));
    this.matIconRegistry.addSvgIcon('icon_option_private', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_option_private.svg"));
    this.matIconRegistry.addSvgIcon('icon_option_shared', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_option_shared.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_user', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_user.svg"));
    this.matIconRegistry.addSvgIcon('icon_faq_user_neg', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_faq_user_neg.svg"));
    this.matIconRegistry.addSvgIcon('icon_hourglass', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/icon_hourglass.svg"));

    //Avatars
    this.matIconRegistry.addSvgIcon('avatar_01', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_01.svg"));
    this.matIconRegistry.addSvgIcon('avatar_02', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_02.svg"));
    this.matIconRegistry.addSvgIcon('avatar_03', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_03.svg"));
    this.matIconRegistry.addSvgIcon('avatar_04', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_04.svg"));
    this.matIconRegistry.addSvgIcon('avatar_05', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_05.svg"));
    this.matIconRegistry.addSvgIcon('avatar_06', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_06.svg"));
    this.matIconRegistry.addSvgIcon('avatar_07', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_07.svg"));
    this.matIconRegistry.addSvgIcon('avatar_08', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_08.svg"));
    this.matIconRegistry.addSvgIcon('avatar_09', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_09.svg"));
    this.matIconRegistry.addSvgIcon('avatar_10', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_10.svg"));
    this.matIconRegistry.addSvgIcon('avatar_11', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_11.svg"));
    this.matIconRegistry.addSvgIcon('avatar_12', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/avatars/avatar_12.svg"));
  }
}
