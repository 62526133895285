import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-favorites',
  templateUrl: './products-favorites.component.html',
  styleUrls: ['./products-favorites.component.scss'],
})
export class ProductsFavoritesComponent
  extends BaseComponent
  implements OnInit
{
  favorites: Product[];

  loading: boolean;
  error: boolean;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private authService: AuthenticationService,
    private userApi: UserService,
    private favoritesService: FavoritesService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.getFavorites();
  }

  private async getFavorites(): Promise<void> {
    this.loading = true;

    try {
      this.favorites = await this.favoritesService.getFavorites();
    } catch (error) {
      error = true;
    }

    this.loading = false;
  }
}
