<div *transloco="let t">
  <!-- header -->
  <div class="header flexible-column-centered">
    <div class="header-content">
      <h1
        class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
        [ngClass]="{
          'text-2': !isMobile,
          'text-2-small': isSmall,
          'text-2-xsmall': isXSmall
        }"
      >
        {{ t("products.products_home.gift_idea.title") }}
      </h1>
      <p
        class="description animated-fade-in-from-bottom animation-delay-2 to-animate"
        [ngClass]="{
          'text-5': !isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        [innerHTML]="'products.products_home.gift_idea.description' | transloco"
      ></p>
      <h2
        class="subtitle animated-fade-in-from-bottom animation-delay-3 to-animate"
        [ngClass]="{
          'text-5': !isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
      >
        {{ t("products.products_home.gift_idea.scroll_label") }}
      </h2>
    </div>
  </div>

  <!-- categories -->
  <div class="margin content flexible-column-centered">
    <div class="cat-anchor-container flexible-wrap-center">
      <div
        class="cat-anchor"
        *ngFor="let cat of categories; let i = index"
        [attr.data-index]="i"
      >
        <!--div
          class="cursor-pointer flexible-column-centered"
          
          (mouseenter)="setHover(i)"
          (mouseleave)="resetHover(i)"
        -->
        <div
          class="cat-anchor-item position-relative cursor-pointer"
          (click)="resetHover(i); scrollToCategory(i)"
          (mouseenter)="setHover(i)"
          (mouseleave)="resetHover(i)"
        >
          <div class="cat-image-container">
            <img
              class="cat-image"
              [ngClass]="{ 'cat-image-hover': cat.hover }"
              src="{{ cat.image_title }}"
            />
          </div>
          <div class="overlay"></div>
          <span class="cat-title text-5">{{ cat.title }}</span>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{
        'cat-description': !isMobile,
        'cat-description-mobile': isSmall
      }"
    >
      <p
        [ngClass]="{
          'text-5': !isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        [innerHTML]="'products.products_home.gift_idea.description_category' | transloco"
      ></p>
    </div>

    <div class="element-container">
      <div
        *ngFor="let cat of categories; let even = even; let i = index"
        id="{{ 'cat-' + i }}"
      >
        <app-products-home-gifts-idea-section-cat-item
          [category]="cat"
          [imageFirst]="even"
        ></app-products-home-gifts-idea-section-cat-item>
      </div>
    </div>
  </div>
</div>
