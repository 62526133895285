<div *transloco="let t" class="flexible-row-centered">
    <input
        [formControl]="controller"
        maxLength="30"
        class="input text-align-left text-6"
        type="text"
        placeholder="{{t('core.header.search')}}"
        (keyup.enter)="search()"
      />

      <div class="icon-container" (click)="search()">
        <mat-icon class="icon" svgIcon="icon_find"></mat-icon>
      </div>
</div>