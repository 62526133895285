import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupCharmIncomingComponent } from 'src/app/shared/modules/popup-charm-incoming/components/popup-charm-incoming/popup-charm-incoming.component';

@Component({
  selector: 'app-popup-package-info',
  templateUrl: './popup-package-info.component.html',
  styleUrls: ['./popup-package-info.component.scss']
})
export class PopupPackageInfoComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<PopupCharmIncomingComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {}

  onClickCloseDialog() {
    this.dialogRef.close();
  }
}