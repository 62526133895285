<div
  class="position-relative"
  [ngClass]="{ content: !isMobile, 'content-mobile': isMobile }"
>
  <div
    class="flexible-row-centered"
    [ngClass]="{
      'menu-container': !isMobile,
      'menu-container-mobile': isMobile
    }"
  >
    <app-core-header-section-a
      (onOpenMobileMenu)="openMobileMenu()"
    ></app-core-header-section-a>
    <app-core-header-section-b></app-core-header-section-b>
  </div>
  <div class="stack-center logo-container">
    <app-core-header-logo></app-core-header-logo>
  </div>
</div>
