<ng-container *ngIf="!isMobile; else mobile">
    <ng-container *transloco="let t">
        <div class="content">
            <div class="flexible filter-container" *ngIf="items != undefined">
                <span class="filter-title">{{ t('products.products_list.filters.filter.title') }}</span>

                <app-products-list-filter-item *ngFor="let item of items" [item]="item"></app-products-list-filter-item>
            </div>

            <div class="separator"></div>
        </div>
    </ng-container>
</ng-container>

<ng-template #mobile>

</ng-template>