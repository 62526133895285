<div *transloco="let t" class="content flexible-column-centered">
  <span
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
    >{{ t("shared.partner_description.title") }}</span
  >
  <img
    class="image animated-zoom-in animation-delay-2 to-animate"
    src="assets/images/logo_stella.png"
  />
  <span
    class="description animated-fade-in-from-bottom animation-delay-2 to-animate"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    [innerHTML]="'shared.partner_description.description' | transloco"
  ></span>
</div>
