<ng-container *ngIf="!isMobile; else mobile">
  <div
    class="content"
    [ngClass]="{ 'content-extended': footerBottomItem.image != undefined }"
  >
    <p class="text-7 text-align-left title">{{ footerBottomItem.title }}</p>
    <img
      *ngIf="footerBottomItem.image != undefined"
      class="image"
      src="{{ footerBottomItem.image }}"
    />
    <span
      *ngFor="let item of footerBottomItem.items"
      class="text-7 text-align-left subtitle cursor-pointer"
      [routerLink]="item.route == '' ? null : item.route"
      [innerHTML]="item.title"
    ></span>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="content-extended">
    <ng-container *ngIf="footerBottomItem.image == undefined; else lastItem">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title class="material-remove-font-size">
            <p
              class="title text-align-left"
              [ngClass]="{ 'text-7-small': isSmall, 'text-7-xsmall': isXSmall }"
            >
              {{ footerBottomItem.title }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span
          *ngFor="let item of footerBottomItem.items"
          class="subtitle-mobile text-align-left"
          [ngClass]="{ 'text-7-small': isSmall, 'text-7-xsmall': isXSmall }"
          [routerLink]="item.route == '' ? null : item.route"
          [innerHTML]="item.title"
        ></span>
      </mat-expansion-panel>
    </ng-container>

    <ng-template #lastItem>
      <p
        class="title last-item adapt-material text-align-left"
        [ngClass]="{ 'text-7-small': isSmall, 'text-7-xsmall': isXSmall }"
      >
        {{ footerBottomItem.title }}
      </p>
      <div class="adapt-material">
        <img class="image" src="{{ footerBottomItem.image }}" />
      </div>
    </ng-template>
  </div>
</ng-template>
