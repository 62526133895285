<div *transloco="let t" class="position-relative flexible-column-centered">
  <div class="icon-close-container" (click)="onClickClose()">
    <mat-icon class="icon-close" svgIcon="icon_x"></mat-icon>
  </div>

  <h3
    [ngClass]="{
      'text-4': !isMobile,
      'text-4-small': isSmall,
      'text-4-xsmall': isXSmall
    }"
    class="fill text-align-left title"
  >
    {{ t("cookie.popup.title") }}
  </h3>

  <p
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
    class="fill text-align-left description"
  >
    {{ t("cookie.popup.description") }}
  </p>

  <div class="flexible-column-centered list">
    <app-cookie-popup-item
      *ngFor="let item of items"
      [item]="item"
      class="fill-width"
    ></app-cookie-popup-item>
  </div>

  <div
    class="fill-width buttons-container"
    [ngClass]="{
      'flexible-row-centered': !isMobile,
      'flexible-column-centered': isMobile
    }"
  >
    <button
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      mat-stroked-button
      (click)="savePreferences()"
    >
      {{ t("cookie.popup.button_1") }}
    </button>

    <button
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small button-mobile': isSmall,
        'button-3-xsmall button-mobile': isXSmall
      }"
      mat-stroked-button
      (click)="refuseAll()"
    >
      {{ t("cookie.popup.button_2") }}
    </button>

    <button
      class="button"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small button-mobile': isSmall,
        'button-2-xsmall button-mobile': isXSmall
      }"
      mat-stroked-button
      (click)="acceptAll()"
    >
      {{ t("cookie.popup.button_3") }}
    </button>
  </div>
</div>
