<ng-container *transloco="let t">
  <div class="flexible-column-centered">
    <p
      class="description"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
      [innerHTML]="'products.products_favorites.description' | transloco"
    ></p>

    <button
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      mat-stroked-button
      routerLink="/products"
    >
      {{ t("products.products_favorites.button") }}
    </button>
  </div>
</ng-container>
