<div class="flexible-row-centered" *transloco="let t">
    <a routerLink="/profile" class="contacts-link">
      <div class="flexible">
        <div class="icon-container" [ngClass]="{ 'icon-container-mobile': isMobile }">
          <mat-icon class="icon" svgIcon="icon_user" [ngClass]="{'color-white': isMobile}"></mat-icon>
        </div>
  
        <span
          class="contacts-text"
          [ngClass]="{
            'text-7': !isMobile,
            'color-white': isMobile,
            'text-5-small': isSmall,
            'text-5-xsmall': isXSmall
          }"
          >{{ isAuthenticated() ? t("core.header.profile") : t("core.header.sign_in") }}</span
        >
      </div>
    </a>
  </div>
  