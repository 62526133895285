<ng-container *ngIf="!isMobile; else mobile">
    <div *ngIf="items != undefined" class="flexible items-container">
        <div *ngFor="let item of items" class="item-flex-odd-space">
            <div class="flexible-column-centered">
                <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
                <h3 innerHTML="{{ item.title }}" class="item-title"
                    [ngClass]="{'text-4 item-flex-properties': !isMobile, 'text-4-small': isSmall, 'text-4-xsmall': isXSmall}">
                </h3>
                <p innerHTML="{{ item.description }}" class="item-description"
                    [ngClass]="{'text-5 item-flex-properties': !isMobile, 'text-5-small': isSmall, 'text-5-xsmall': isXSmall}">
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #mobile>
    <div *ngIf="items != undefined" class="items-container-mobile">
        <div *ngFor="let item of items; let first = first; let last = last;" class="item-mobile"
        [ngClass]="{'item-mobile-margin-top': !first, 'item-mobile-margin-bottom': !last}">
            <div class="flexible-column-centered">
                <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
                <h3 innerHTML="{{ item.title }}" class="item-title"
                    [ngClass]="{'text-4 item-flex-properties': !isMobile, 'text-4-small': isSmall, 'text-4-xsmall': isXSmall}">
                </h3>
                <p innerHTML="{{ item.description }}" class="item-description"
                    [ngClass]="{'text-5 item-flex-properties': !isMobile, 'text-5-small': isSmall, 'text-5-xsmall': isXSmall}">
                </p>
            </div>
        </div>
    </div>
</ng-template>