import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UserGuest } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { UserGuestService } from 'src/app/services/user-guest/user-guest.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-product-added-dialog',
  templateUrl: './popup-request-mail.component.html',
  styleUrls: ['./popup-request-mail.component.scss'],
})
export class PopupRequestMailComponent
  extends BaseComponent
  implements OnInit
{
  loading: boolean = false;
  error: string;
  form: FormGroup;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PopupRequestMailComponent>,
    private userGuestService: UserGuestService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super(responsive, transloco);

    this.form = formBuilder.group({
      email: [
        null,
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'submit',
        },
      ],
    });
  }

  ngOnInit() {}

  getFormController(controller: string): FormControl {
    return this.form.controls[controller] as FormControl;
  }

  onClickCloseDialog() {
    this.dialogRef.close();
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loading = true;

      const email = this.getFormController('email').value;

      const userGuest: UserGuest = {
        email: email,
        language: this.transloco.getActiveLang() as 'it' | 'en',
      };

      try {
        await this.userGuestService.postUserGuest(userGuest, 'NEWSLETTER');

        this.loading = false;
        this.dialogRef.close();
      } catch (error) {
        this.error;
      }

      this.loading = false;
    }
  }
}
