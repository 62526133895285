<div class="flexible" *transloco="let t">
  <div class="icon-container">
    <mat-icon class="icon" svgIcon="icon_find"></mat-icon>
  </div>

  <input
    [formControl]="controller"
    maxLength="30"
    class="input text-align-left text-6 fill-width"
    type="text"
    placeholder="{{ t('core.header.search') }}"
    (keyup.enter)="search()"
  />
</div>
