<div class="flexible-column-centered" *transloco="let t">
  <div class="icon-container">
    <mat-icon class="icon" svgIcon="icon_instructions_envelope"></mat-icon>
  </div>

  <h2
    mat-dialog-title
    class="title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("shared.popup_request_mail.title") }}
  </h2>

  <p
    class="description"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
  >
    {{ t("shared.popup_request_mail.description") }}
  </p>
</div>
