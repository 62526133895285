import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomePageHeader } from '../../../../shared/components/home-page-header/home-page-header.component';

@Component({
  selector: 'app-home-faq-section-a',
  templateUrl: './home-faq-section-a.component.html',
  styleUrls: ['./home-faq-section-a.component.scss']
})
export class HomeFaqSectionAComponent extends BaseComponent implements OnInit {

  faqHeader : HomePageHeader;
  
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.faqHeader = {
      imgSrc: "assets/images/img_faq_diamonds.jpg",
      titleTranslocoKey: "home.shared.faq.title",
      descriptionTranslocoKey: "home.shared.faq.description"
    };
  }

}
