import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircularProgressIndicatorComponent } from './circular-progress-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    CircularProgressIndicatorComponent
  ],
  exports: [
    CircularProgressIndicatorComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ]
})
export class CircularProgressIndicatorModule { }
