import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-about-us-section-e',
  templateUrl: './home-about-us-section-e.component.html',
  styleUrls: ['./home-about-us-section-e.component.scss']
})
export class HomeAboutUsSectionEComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  images: string[] = [
    'assets/images/logo-associazione-orafa-lombarda.jpg',
    'assets/images/logo-assolombarda.jpg',
    'assets/images/logo-confcommercio.jpg',
    'assets/images/logo-dih.jpg',
    'assets/images/logo-fortitudo-diamonds.jpg',
    'assets/images/logo-gia-alumni.jpg'
  ];

  descriptions: string[];

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'home.about_us.section_e.items'
    ).subscribe(result => {
      this.descriptions = result;
    });
  }
}
