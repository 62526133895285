<div class="margin content flexible-column-centered">
  <div class="cat-anchor-container flexible-wrap-center">
    <div
      class="cat-anchor"
      *ngFor="let cat of categories; let i = index"
      [attr.data-index]="i"
    >
      <div
        class="cursor-pointer flexible-column-centered"
        (click)="resetHover(i); openCategoryAndScroll(i)"
        (mouseenter)="setHover(i)"
        (mouseleave)="resetHover(i)"
      >
        <mat-icon
          class="icon"
          svgIcon="{{ cat.hover ? cat.icon + '_neg' : cat.icon }}"
        ></mat-icon>
        <span class="title text-5">{{ cat.title }}</span>
      </div>
    </div>
  </div>

  <div class="element-container">
    <div class="separator"></div>

    <ng-container *ngIf="categories != undefined">
      <div
        [ngClass]="
          !isMobile ? 'cat-items-container' : 'cat-items-container-mobile'
        "
      >
        <div
          *ngFor="let cat of categories; let i = index"
          id="{{ 'cat-' + i }}"
        >
          <mat-expansion-panel
            [(expanded)]="cat.expanded"
            class="cat-item-header-panel mat-elevation-z0 remove-mat-expansion-panel-padding item"
          >
            <mat-expansion-panel-header
              class="cat-item-header"
              collapsedHeight="*"
              expandedHeight="*"
            >
              <mat-panel-title class="material-remove-font-size">
                <p
                  class="item-title text-align-left"
                  [ngClass]="{
                    'text-3': !isMobile,
                    'text-3-small': isSmall,
                    'text-3-xsmall': isXSmall
                  }"
                >
                  {{ cat.title }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-home-mat-expansion-panel
              [items]="cat.items"
              [viewSeparatorAtLastRow]="false"
            ></app-home-mat-expansion-panel>
          </mat-expansion-panel>
          <div class="separator"></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
