<div *transloco="let t" class="flexible-column-centered">
  <h2
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("products.products_detail.section_c.title") }}
  </h2>

  <div *ngIf="loaded" class="content">
    <app-products-list-item
      [ngClass]="{
        item: !isMobile,
        'item-small': isSmall,
        'item-xsmall': isXSmall
      }"
      *ngFor="let product of products"
      [product]="product"
      [favorites]="favorites"
    ></app-products-list-item>
  </div>
</div>
