import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { ProductsNavigationPathItem } from '../../shared/components/products-navigation-path/products-navigation-path.component';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-products-home',
  templateUrl: './products-home.component.html',
  styleUrls: ['./products-home.component.scss']
})
export class ProductsHomeComponent extends BaseComponent implements OnInit {
  path: ProductsNavigationPathItem[]

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.fixAnimationStart();
    
    this.transloco.selectTranslateObject(
      'products.products_home.path'
    ).subscribe(result => {
      this.path = result;
    });
  }

  ngAfterViewChecked(){
    this.computeAnimations();
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.computeAnimations();
  }
}
