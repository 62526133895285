import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Multimedia, Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupRequestMailService } from 'src/app/shared/modules/popup-request-mail/services/popup-request-mail/popup-request-mail.service';

@Component({
  selector: 'app-products-list-item',
  templateUrl: './products-list-item.component.html',
  styleUrls: ['./products-list-item.component.scss'],
})
export class ProductsListItemComponent extends BaseComponent implements OnInit {
  @Input()
  favorites: Product[];

  @Input()
  product: Product;

  loading: boolean;
  imageUrl: string;

  metal: string;
  gemstone: string;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router,
    private authService: AuthenticationService,
    private userApi: UserService,
    private favoritesService: FavoritesService,
    private popupRequestMailService: PopupRequestMailService
  ) {
    super(responsive, transloco);
  }

  isFavorite() {
    return (
      this.favorites?.map((e) => e.id).indexOf(this.product.id) > -1 ?? false
    );
  }

  ngOnInit(): void {
    const multimedia: Multimedia[] = this.product.multimedia!;
    this.imageUrl = multimedia[0].url ?? '';

    this.transloco
      .selectTranslateObject('products.product_attributes')
      .subscribe((result) => {
        this.metal = result.metals.find(
          (e: any) => e.key == this.product.metal
        ).value;
        this.gemstone = result.gemstones.find(
          (e: any) => e.key == this.product.gemstone
        ).value;
      });
  }

  productSelected() {
    window.dataLayer.push({
      event: 'product_clicked',
      product_id: this.product.id
    });
    this.router.navigate(['/products/product-detail/', this.product.id]);
  }

  async onClickFavorite() {
    this.popupRequestMailService.resetDialogTimer();
    
    this.loading = true;

    try {
      if (this.isFavorite()) {
        await this.deleteFavorite();
      } else {
        await this.postFavorite();
      }
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }

  private async postFavorite(): Promise<void> {
    await this.favoritesService.postFavorite(this.product);
    this.favorites.push(this.product);
  }

  private async deleteFavorite(): Promise<void> {
    await this.favoritesService.deleteFavorite(this.product.id!);

    this.favorites.splice(
      this.favorites.map((e) => e.id).indexOf(this.product.id),
      1
    );
  }
}
