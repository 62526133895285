import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-contacts-section-c',
  templateUrl: './home-contacts-section-c.component.html',
  styleUrls: ['./home-contacts-section-c.component.scss']
})
export class HomeContactsSectionCComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {

  }
}
