import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogState, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

interface MenuItem {
  title: string;
  route: string;
  items: MenuItem[];
}

@Component({
  selector: 'app-popup-menu-home-info',
  templateUrl: './popup-menu-home-info.component.html',
  styleUrls: ['./popup-menu-home-info.component.scss']
})
export class PopupMenuHomeInfoComponent extends BaseComponent implements OnInit {
  @Output() isFocused = new EventEmitter<boolean>();
  item: MenuItem;

  private positionRelativeToElement: ElementRef

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<PopupMenuHomeInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data: { positionRelativeToElement: ElementRef },
    private router: Router
  ) {
    super(responsive, transloco);

    this.positionRelativeToElement = data.positionRelativeToElement
  }

  ngOnInit(): void {
    const matDialogConfig = new MatDialogConfig()
    const rect: DOMRect = this.positionRelativeToElement.nativeElement.getBoundingClientRect()

    const width = rect.width + 48;
    const left = rect.left - 24;
    const top = rect.bottom + 4;

    matDialogConfig.position = { left:`${left}px` , top: `${top}px` }
    this.dialogRef.updatePosition(matDialogConfig.position)
    this.dialogRef.updateSize(`${width}px`);
    
    this.transloco.selectTranslateObject(
      'core.header.menu'
    ).subscribe(result => {
      this.item = result[3];
    });
  }

  mouseEnter() {
    if (this.dialogRef.getState() === MatDialogState.OPEN) {
      this.isFocused.emit(true);
    }
  }

  mouseLeave() {
    this.isFocused.emit(false);
  }

  async onClickItem(item: MenuItem) {
    //this.router.navigate([item.route]);
    this.isFocused.emit(false);
    this.dialogRef.close(item.route);
  }
}
