<div class="content">
  <label
    class="flexible-column text-align-left"
    [ngClass]="{
      'text-7': !isMobile,
      'text-7-small': isSmall,
      'text-7-xsmall': isXSmall
    }"
  >
    {{ input.label }}

    <ng-container
      *ngIf="input.type == 'text' || input.type == 'password'; else textarea"
    >
      <input
        [formControl]="input.controller"
        maxLength="{{ input.maxLength }}"
        class="input text-align-left"
        [ngClass]="{
          'input-error': isInvalid(),
          'text-6': !isMobile,
          'text-6-small': isSmall,
          'text-6-xsmall': isXSmall
        }"
        type="{{ input.type }}"
      />
    </ng-container>

    <ng-template #textarea>
      <textarea
        [formControl]="input.controller"
        maxLength="{{ input.maxLength }}"
        class="input textarea text-align-left"
        [ngClass]="{
          'input-error': isInvalid(),
          'text-6': !isMobile,
          'text-6-small': isSmall,
          'text-6-xsmall': isXSmall
        }"
      ></textarea>
    </ng-template>
  </label>
  <span
    *ngIf="isInvalid()"
    class="error"
    [ngClass]="{
      'text-7': !isMobile,
      'text-7-small': isSmall,
      'text-7-xsmall': isXSmall
    }"
    >{{ input.errorMessage }}</span
  >
</div>
