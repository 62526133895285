import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { LocalizedString, Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html',
  styleUrls: ['./products-search.component.scss'],
})
export class ProductsSearchComponent extends BaseComponent implements OnInit {
  loading = false;
  error = false;
  products: Product[];
  favorites: Product[];

  query: string;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userApi: UserService,
    private favoritesService: FavoritesService
  ) {
    super(responsive, transloco);

    this.route.queryParams.subscribe((params) => {
      this.query = params['query'];
      this.getProducts();
    });
  }

  ngOnInit(): void {}

  private async getProducts() {
    this.loading = true;

    try {
      await this.authService.updateApiCredentials();
      this.products = await lastValueFrom(
        this.userApi.getUserProducts().pipe(retry(3))
      );

      this.products = this.products.filter((e) => {
        if (e.name != undefined && this.matchQuery(e.name!, this.query)) {
          return true;
        } else if (
          e.description != undefined &&
          this.matchQuery(e.description!, this.query)
        ) {
          return true;
        }

        return false;
      });

      this.products.forEach((p) => p.multimedia?.sort((a, b) => a.id! - b.id!));

      this.favorites = await this.favoritesService.getFavorites();
    } catch (error) {
      this.error = true;
    }

    this.loading = false;
  }

  private matchQuery(
    localizedStrings: Array<LocalizedString>,
    query: string
  ): boolean {
    return (
      localizedStrings.find((localizedString) =>
        localizedString.content?.toLowerCase().includes(query.toLowerCase())
      ) != undefined
    );
  }
}
