<div class="content flexible-column-centered">
  <div class="image-container">
    <img class="image-fill-cover" src="{{ item.image }}" />
  </div>
  <h2
    class="title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ item.title }}
  </h2>
  <p
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    innerHTML="{{ item.description }}"
  ></p>
</div>
