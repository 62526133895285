import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomePrivacyTermsHeader } from '../../shared/components/home-privacy-terms-header/home-privacy-terms-header.component';

@Component({
  selector: 'app-home-terms',
  templateUrl: './home-terms.component.html',
  styleUrls: ['./home-terms.component.scss']
})
export class HomeTermsComponent extends BaseComponent implements OnInit {
  
  header: HomePrivacyTermsHeader;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.header = {
      imgSrc: "assets/images/img_termini.jpg",
      titleTranslocoKey: "home.terms.title"
    }
  }

}
