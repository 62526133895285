import { Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomeInfoItem } from './components/home-info-item/home-info-item.component';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss'],
})
export class HomeInfoComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router
  ) {
    super(responsive, transloco);
  }
  
  items: HomeInfoItem[];

  ngOnInit(): void {
    this.fixAnimationStart();
    
    this.transloco
      .selectTranslateObject('home.info.items')
      .subscribe((result) => {
        this.items = result;
      });
  }

  ngAfterViewChecked(){
    this.computeAnimations();
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.computeAnimations();
  }
}
