<div [ngClass]="{ content: !isMobile }">
  <ng-container *ngIf="!isMobile; else mobile">
    <div
      class="flexible fill-height"
      [ngClass]="{ 'flex-direction-row-reverse': !imageFirst }"
    >
      <div class="half-width-container">
        <img class="image-fill-cover" src="{{ item.image }}" />
      </div>
      <div class="half-width-container">
        <div class="content-desktop flexible-column-centered">
          <h2
            class="text-3 animated-fade-in-from-bottom animation-delay-1 to-animate"
          >
            {{ item.title }}
          </h2>
          <p
            class="text-5 text-align-left description animated-fade-in-from-bottom animation-delay-2 to-animate"
            innerHTML="{{ item.description }}"
          ></p>
          <button
            class="button-3 animated-fade-in animation-delay-3 to-animate"
            routerLink="{{ item.route }}"
            mat-stroked-button
          >
            {{ item.button }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div class="flexible-column-centered">
      <div
        [ngClass]="{
          'image-container-small': isSmall,
          'image-container-xsmall': isXSmall
        }"
      >
        <img class="image-fill-cover" src="{{ item.image }}" />
      </div>

      <div class="content-mobile flexible-column-centered">
        <h2
          class="animated-fade-in-from-bottom animation-delay-1 to-animate"
          [ngClass]="{ 'text-3-small': isSmall, 'text-3-xsmall': isXSmall }"
        >
          {{ item.title }}
        </h2>
        <p
          class="text-align-left description animated-fade-in-from-bottom animation-delay-2 to-animate"
          [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
          innerHTML="{{ item.description }}"
        ></p>
        <button
          class="animated-fade-in animation-delay-2 to-animate"
          [ngClass]="{ 'button-3-small': isSmall, 'button-3-xsmall': isXSmall }"
          routerLink="{{ item.route }}"
          mat-stroked-button
        >
          {{ item.button }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
