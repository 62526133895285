import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeHomePageComponent } from './components/home-home-page/home-home-page.component';
import { HomeInfoComponent } from './components/home-info/home-info.component';
import { HomeAboutUsComponent } from './components/home-about-us/home-about-us.component';
import { HomeInnovationComponent } from './components/home-innovation/home-innovation.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeContactsComponent } from './components/home-contacts/home-contacts.component';
import { HomePageNotFoundComponent } from './components/home-page-not-found/home-page-not-found.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ProductsModule } from '../products/products.module';
import { MatIconModule } from '@angular/material/icon';
import { HomeHomePageSectionAComponent } from './components/home-home-page/components/home-home-page-section-a/home-home-page-section-a.component';
import { HomeHomePageSectionBComponent } from './components/home-home-page/components/home-home-page-section-b/home-home-page-section-b.component';
import { HomeHomePageSectionCComponent } from './components/home-home-page/components/home-home-page-section-c/home-home-page-section-c.component';
import { HomeHomePageSectionDComponent } from './components/home-home-page/components/home-home-page-section-d/home-home-page-section-d.component';
import { HomeHomePageSectionEComponent } from './components/home-home-page/components/home-home-page-section-e/home-home-page-section-e.component';
import { HomeHomePageSectionEItemComponent } from './components/home-home-page/components/home-home-page-section-e/components/home-home-page-section-e-item/home-home-page-section-e-item.component';
import { HomeAboutUsSectionAComponent } from './components/home-about-us/components/home-about-us-section-a/home-about-us-section-a.component';
import { HomeAboutUsSectionBComponent } from './components/home-about-us/components/home-about-us-section-b/home-about-us-section-b.component';
import { HomeAboutUsSectionCComponent } from './components/home-about-us/components/home-about-us-section-c/home-about-us-section-c.component';
import { HomeAboutUsSectionDComponent } from './components/home-about-us/components/home-about-us-section-d/home-about-us-section-d.component';
import { HomeAboutUsSectionEComponent } from './components/home-about-us/components/home-about-us-section-e/home-about-us-section-e.component';
import { HomeAboutUsSectionFComponent } from './components/home-about-us/components/home-about-us-section-f/home-about-us-section-f.component';
import { HomeAboutUsSectionBItemComponent } from './components/home-about-us/components/home-about-us-section-b/components/home-about-us-section-b-item/home-about-us-section-b-item.component';
import { HomeAboutUsSectionCItemComponent } from './components/home-about-us/components/home-about-us-section-c/components/home-about-us-section-c-item/home-about-us-section-c-item.component';
import { HomeInfoHeadComponent } from './components/home-info/components/home-info-head/home-info-head.component';
import { HomeInfoItemComponent } from './components/home-info/components/home-info-item/home-info-item.component';
import { HomeContactsSectionAComponent } from './components/home-contacts/components/home-contacts-section-a/home-contacts-section-a.component';
import { HomeContactsSectionBComponent } from './components/home-contacts/components/home-contacts-section-b/home-contacts-section-b.component';
import { HomeContactsSectionCComponent } from './components/home-contacts/components/home-contacts-section-c/home-contacts-section-c.component';
import { HomeContactsSectionDComponent } from './components/home-contacts/components/home-contacts-section-d/home-contacts-section-d.component';
import { HomeContactsSectionEComponent } from './components/home-contacts/components/home-contacts-section-e/home-contacts-section-e.component';
import { HomeContactsSectionAItemComponent } from './components/home-contacts/components/home-contacts-section-a/components/home-contacts-section-a-item/home-contacts-section-a-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { HomeInnovationSectionAComponent } from './components/home-innovation/components/home-innovation-section-a/home-innovation-section-a.component';
import { HomeInnovationSectionBComponent } from './components/home-innovation/components/home-innovation-section-b/home-innovation-section-b.component';
import { HomeInnovationSectionCComponent } from './components/home-innovation/components/home-innovation-section-c/home-innovation-section-c.component';
import { HomeInnovationSectionDComponent } from './components/home-innovation/components/home-innovation-section-d/home-innovation-section-d.component';
import { HomeInnovationSectionEComponent } from './components/home-innovation/components/home-innovation-section-e/home-innovation-section-e.component';
import { HomeFaqReducedComponent } from './shared/components/home-faq-reduced/home-faq-reduced.component';
import { HomeJewelsComponent } from './shared/components/home-jewels/home-jewels.component';
import { FormInputFieldModule } from 'src/app/shared/modules/form-input-field/form-input-field.module';
import { PartnerDescriptionModule } from 'src/app/shared/modules/partner-description/partner-description.module';
import { ProductCategoriesModule } from 'src/app/shared/modules/product-categories/product-categories.module';
import { HomePrivacyComponent } from './components/home-privacy/home-privacy.component';
import { HomeTermsComponent } from './components/home-terms/home-terms.component';
import { HomeFaqComponent } from './components/home-faq/home-faq.component';
import { HomePageHeaderComponent } from './shared/components/home-page-header/home-page-header.component';
import { HomeFaqSectionAComponent } from './components/home-faq/components/home-faq-section-a/home-faq-section-a.component';
import { HomeFaqSectionBComponent } from './components/home-faq/components/home-faq-section-b/home-faq-section-b.component';
import { HomeMatExpansionPanelComponent } from './shared/components/home-mat-expansion-panel/home-mat-expansion-panel.component';
import { HomeTitleListButtonComponent } from './shared/components/home-title-list-button/home-title-list-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HomePrivacyTermsHeaderComponent } from './shared/components/home-privacy-terms-header/home-privacy-terms-header.component';
import { HomePrivacyTermsBodyComponent } from './shared/components/home-privacy-terms-body/home-privacy-terms-body.component';
import { RowAlignedItemsModule } from 'src/app/shared/modules/row-aligned-items/row-aligned-items.module';
import { CircularProgressIndicatorModule } from 'src/app/shared/modules/circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from 'src/app/shared/modules/error-message/error-message.module';
import { HomeStateAidComponent } from './components/home-state-aid/home-state-aid.component';
import { HomeCookiePolicyComponent } from './components/home-cookie-policy/home-cookie-policy.component';
import { HomeAboutUsPartnersComponent } from './components/home-about-us/components/home-about-us-partners/home-about-us-partners.component';
import { HomeAboutUsPartnersItemComponent } from './components/home-about-us/components/home-about-us-partners/components/home-about-us-partners-item/home-about-us-partners-item.component';

const sharedModules = [
  FormInputFieldModule,
  PartnerDescriptionModule,
  ProductCategoriesModule,
  RowAlignedItemsModule,
  CircularProgressIndicatorModule,
  ErrorMessageModule
];

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatExpansionModule,
  MatDialogModule,
];

@NgModule({
  declarations: [
    HomeHomePageComponent,
    HomeInfoComponent,
    HomeAboutUsComponent,
    HomeInnovationComponent,
    HomeContactsComponent,
    HomePageNotFoundComponent,
    HomeHomePageSectionAComponent,
    HomeHomePageSectionBComponent,
    HomeHomePageSectionCComponent,
    HomeHomePageSectionDComponent,
    HomeHomePageSectionEComponent,
    HomeHomePageSectionEItemComponent,
    HomeAboutUsSectionAComponent,
    HomeAboutUsSectionBComponent,
    HomeAboutUsSectionCComponent,
    HomeAboutUsSectionDComponent,
    HomeAboutUsSectionEComponent,
    HomeAboutUsSectionFComponent,
    HomeAboutUsSectionBItemComponent,
    HomeAboutUsSectionCItemComponent,
    HomeInfoHeadComponent,
    HomeInfoItemComponent,
    HomeContactsSectionAComponent,
    HomeContactsSectionBComponent,
    HomeContactsSectionCComponent,
    HomeContactsSectionDComponent,
    HomeContactsSectionEComponent,
    HomeContactsSectionAItemComponent,
    HomeInnovationSectionAComponent,
    HomeInnovationSectionBComponent,
    HomeInnovationSectionCComponent,
    HomeInnovationSectionDComponent,
    HomeInnovationSectionEComponent,
    HomeFaqComponent,
    HomeFaqReducedComponent,
    HomeFaqSectionAComponent,
    HomeFaqSectionBComponent,
    HomeJewelsComponent,
    HomePrivacyComponent,
    HomeTermsComponent,
    HomePageHeaderComponent,
    HomeFaqSectionAComponent,
    HomeFaqSectionBComponent,
    HomeMatExpansionPanelComponent,
    HomeTitleListButtonComponent,
    HomePrivacyTermsHeaderComponent,
    HomePrivacyTermsBodyComponent,
    HomeStateAidComponent,
    HomeCookiePolicyComponent,
    HomeAboutUsPartnersComponent,
    HomeAboutUsPartnersItemComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    HomeRoutingModule,
    ProductsModule,
    ReactiveFormsModule,
    ...materialModules,
    ...sharedModules,
  ],
})
export class HomeModule {}
