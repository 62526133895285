<div
  *transloco="let t"
  [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'"
>
  <ng-container *ngIf="loading; else loaded">
    <app-circular-progress-indicator></app-circular-progress-indicator>
  </ng-container>

  <ng-template #loaded>
    <ng-container *ngIf="error; else content">
      <app-error-message></app-error-message>
    </ng-container>

    <ng-template #content>
      <app-products-search-empty *ngIf="products.length == 0" [query]="query">
      </app-products-search-empty>
      <app-products-search-list
        *ngIf="products.length > 0"
        [query]="query"
        [products]="products"
        [favorites]="favorites"
      >
      </app-products-search-list>
    </ng-template>
  </ng-template>
</div>
