import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomeAboutUsSectionCItem {
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-home-about-us-section-c-item',
  templateUrl: './home-about-us-section-c-item.component.html',
  styleUrls: ['./home-about-us-section-c-item.component.scss']
})
export class HomeAboutUsSectionCItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  item: HomeAboutUsSectionCItem;

  ngOnInit(): void {
  }

}
