<div
  *transloco="let t"
  class="margin flexible-column-centered"
  [ngClass]="!isMobile ? 'content' : 'content-mobile'"
>
  <h1
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t(titleTranslocoKey) }}
  </h1>

  <app-home-mat-expansion-panel
    class="animated-fade-in-from-bottom animation-delay-2 to-animate"
    [items]="items"
    [viewSeparatorAtLastRow]="true"
  ></app-home-mat-expansion-panel>

  <div
    class="flexible-column-centered animated-fade-in animation-delay-3 to-animate"
  >
    <button
      *ngIf="!isBtnFlat"
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      (click)="onBtnClick()"
      [innerHTML]="btnLabelTranslocoKey | transloco"
      mat-stroked-button
    ></button>

    <button
      *ngIf="isBtnFlat"
      class="button"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small': isSmall,
        'button-2-xsmall': isXSmall
      }"
      (click)="onBtnClick()"
      [innerHTML]="btnLabelTranslocoKey | transloco"
      mat-flat-button
    ></button>
  </div>
</div>
