<div *transloco="let t" class="position-relative content flexible-column">
  <div class="icon-close-container" (click)="onClickCloseDialog()">
    <mat-icon class="icon-close" svgIcon="icon_x"></mat-icon>
  </div>

  <div class="icon-container">
    <mat-icon class="icon" svgIcon="icon_discount"></mat-icon>
  </div>

  <h2
    mat-dialog-title
    class="title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("products.product_added_dialog.title") }}
  </h2>

  <mat-dialog-content>
    <p
      class="description"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ t("products.product_added_dialog.description") }}
    </p>

    <form
      [formGroup]="form"
      class="fill-width"
      (ngSubmit)="submit()"
      id="dialog-form"
    >
      <app-form-input-field
        class="input"
        [input]="{
          label: t('products.product_added_dialog.email'),
          errorMessage: t('products.product_added_dialog.invalid_email'),
          controller: getFormController('email'),
          maxLength: '100',
          type: 'text'
        }"
      >
      </app-form-input-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-circular-progress-indicator
      *ngIf="loading"
      class="loading"
    ></app-circular-progress-indicator>

    <button
      *ngIf="!loading"
      form="dialog-form"
      type="submit"
      class="button"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small': isSmall,
        'button-2-xsmall': isXSmall
      }"
      mat-stroked-button
    >
      {{ t("products.product_added_dialog.button") }}
    </button>
  </mat-dialog-actions>
</div>
