<div class="flexible-column-centered" *transloco="let t">
  <h1
    [ngClass]="{
      'text-2': !isMobile,
      'text-2-small': isSmall,
      'text-2-xsmall': isXSmall
    }"
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
  >
    {{ t("home.rem_innovation.section_c.title") }}
  </h1>
  <h2
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    class="subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
  >
    {{ t("home.rem_innovation.section_c.subtitle") }}
  </h2>
  <p
    class="animated-fade-in-from-bottom animation-delay-3 to-animate"
    [ngClass]="{
      'text-5 description': !isMobile,
      'description-mobile': isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    [innerHTML]="'home.rem_innovation.section_c.description' | transloco"
  ></p>
  <br />

  <div class="items-container position-relative">
    <ng-container *ngIf="!isMobile; else mobile">
      <div class="image-container">
        <img class="image-fill-cover" src="assets/images/img_night_sky.jpg" />
      </div>
      <div *ngIf="items != undefined" class="stack-center flexible">
        <div
          *ngFor="let item of items; let i = index"
          class="position-relative item-flex-odd-space animated-fade-in-from-bottom to-animate"
          [ngClass]="'item-animation-delay-' + (i + 1)"
        >
          <div class="fill-width icon-background-container">
            <mat-icon
              class="icon-background"
              svgIcon="{{ item.background }}"
            ></mat-icon>
          </div>

          <div class="stack-center flexible-column-centered">
            <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
            <h3
              innerHTML="{{ item.title }}"
              class="item-title"
              [ngClass]="{
                'text-5 item-flex-properties': !isMobile,
                'text-5-small': isSmall,
                'text-5-xsmall': isXSmall
              }"
            ></h3>
            <p
              innerHTML="{{ item.description }}"
              class="item-description"
              [ngClass]="{
                'text-5 item-flex-properties': !isMobile,
                'text-5-small': isSmall,
                'text-5-xsmall': isXSmall
              }"
            ></p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #mobile>
      <div class="position-relative">
        <div class="image-container-mobile">
          <img class="image-fill-cover" src="assets/images/img_night_sky.jpg" />
        </div>

        <div *ngIf="items != undefined" class="stack-center">
          <div
            *ngFor="let item of items; let even = even"
            class="position-relative item-mobile animation-delay-1 to-animate"
            [ngClass]="{
              'animated-fade-in-from-left': even,
              'animated-fade-in-from-right': !even
            }"
          >
            <div class="fill-width icon-background-container">
              <mat-icon
                class="icon-background"
                svgIcon="{{ item.background }}"
              ></mat-icon>
            </div>

            <div class="stack-center flexible-column-centered">
              <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
              <h3
                innerHTML="{{ item.title }}"
                class="item-title"
                [ngClass]="{
                  'text-5 item-flex-properties': !isMobile,
                  'text-5-small': isSmall,
                  'text-5-xsmall': isXSmall
                }"
              ></h3>
              <p
                innerHTML="{{ item.description }}"
                class="item-description"
                [ngClass]="{
                  'text-5 item-flex-properties': !isMobile,
                  'text-5-small': isSmall,
                  'text-5-xsmall': isXSmall
                }"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
