import { Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalizedString } from 'src/app/openapi/api/model/localizedString';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';

@Injectable()
export abstract class BaseComponent {
  isMobile: boolean = false;
  isXSmall: boolean = false;
  isSmall: boolean = false;

  constructor(
    public responsive: ResponsiveService,
    public transloco: TranslocoService
  ) {
    this.responsive.observeResponsive().subscribe((result) => {
      this.isMobile = result.matches;

      if (result.breakpoints[Breakpoints.Small]) {
        this.isXSmall = false;
        this.isSmall = true;
      } else if (result.breakpoints[Breakpoints.XSmall]) {
        this.isXSmall = true;
        this.isSmall = false;
      } else {
        this.isXSmall = false;
        this.isSmall = false;
      }
    });
  }

  computeAnimations() {
    let elements = document.getElementsByClassName('to-animate');
    for (let i = 0; i < elements.length; i++) {
      let elem = elements[i];
      let rect = elem.getBoundingClientRect();
      if (rect.y + Math.min(rect.height, elem.tagName == "IMG" ? 150 : 50) <= window.innerHeight) {
        // the element is visible
        elem.classList.remove('to-animate');
      }
    }
  }

  fixAnimationStart() {
    window.scrollTo({ top: 1 });
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 250);
  }

  getLocalizedString(strings?: Array<LocalizedString>) {
    let activeLang = this.transloco.getActiveLang();
    return strings?.find(localizedName =>
        localizedName.language?.toLowerCase() == activeLang.toLowerCase()
      )?.content ?? "";
  }
}
