import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-product-detail-section-b-info',
  templateUrl: './product-detail-section-b-info.component.html',
  styleUrls: ['./product-detail-section-b-info.component.scss']
})
export class ProductDetailSectionBInfoComponent extends BaseComponent implements OnInit { 
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }
}
