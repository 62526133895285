import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomePageSectionEItem {
  image: string;
  title: string;
  description: string;
  button: string;
  route: string;
}

@Component({
  selector: 'app-home-home-page-section-e-item',
  templateUrl: './home-home-page-section-e-item.component.html',
  styleUrls: ['./home-home-page-section-e-item.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),

      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
})
export class HomeHomePageSectionEItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  item: HomePageSectionEItem;

  showContent: boolean;

  ngOnInit(): void {

  }
}
