<div *transloco="let t" class="position-relative flexible-column">
  <div
    class="position-relative top-content"
    [ngClass]="{
      'top-content': !isMobile,
      'top-content-small': isSmall,
      'top-content-xsmall': isXSmall
    }"
  >
    <!-- Image background-->
    <img
      class="image-fill-cover image-background"
      src="assets/images/img_night_sky.jpg"
    />

    <!-- Description stack -->
    <app-popup-request-mail-description class="stack-center description-content">
    </app-popup-request-mail-description>

    <!-- Icon close -->
    <app-popup-request-mail-icon-close
      (click)="onClickCloseDialog()"
      class="icon-close"
    >
    </app-popup-request-mail-icon-close>
  </div>

  <mat-dialog-content>
    <form
      *ngIf="!loading"
      [formGroup]="form"
      class="fill-width form"
      (ngSubmit)="submit()"
    >
      <app-form-input-field
        class="input"
        [input]="{
          label: t('shared.popup_request_mail.email'),
          errorMessage: t('shared.popup_request_mail.invalid_email'),
          controller: getFormController('email'),
          maxLength: '100',
          type: 'text'
        }"
      >
      </app-form-input-field>

      <button
        type="submit"
        class="button"
        [ngClass]="{
          'button-2': !isMobile,
          'button-2-small': isSmall,
          'button-2-xsmall': isXSmall
        }"
        mat-stroked-button
      >
        {{ t("shared.popup_request_mail.button") }}
      </button>
    </form>

    <app-circular-progress-indicator
      *ngIf="loading"
      class="loading"
    ></app-circular-progress-indicator>
  </mat-dialog-content>
</div>
