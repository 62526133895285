import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerDescriptionComponent } from './partner-description.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    PartnerDescriptionComponent
  ],
  exports: [
    PartnerDescriptionComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class PartnerDescriptionModule { }
