/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface ContactUs { 
    /**
     * 
     */
    id?: string;
    /**
     * 
     */
    creation_date?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    surname?: string;
    /**
     * 
     */
    phone?: string;
    /**
     * 
     */
    message?: string;
}

