<div class="content position-relative">
  <div class="flexible-column-centered" (click)="productSelected()">
    <div class="image-container fill">
      <img class="image" src="{{ imageUrl }}" />
    </div>
    <span
      class="title"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
      >{{ getLocalizedString(product.name) }}</span
    >
    <span
      class="description"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
      >{{ metal }} - {{ gemstone }}</span
    >
    <span
      class="price"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ product.price }} €</span
    >
  </div>
  <div class="icon-container" (click)="onClickFavorite()">
    <mat-icon
      *ngIf="!isFavorite()"
      class="icon"
      svgIcon="icon_heart"
    ></mat-icon>
    <mat-icon
      *ngIf="isFavorite()"
      class="icon"
      svgIcon="icon_heart_filled"
    ></mat-icon>
  </div>
</div>
