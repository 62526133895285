import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface SocialItem {
  icon: string;
  link: string;
}

@Component({
  selector: 'app-core-footer-socials',
  templateUrl: './core-footer-socials.component.html',
  styleUrls: ['./core-footer-socials.component.scss']
})
export class CoreFooterSocialsComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  socialItems: SocialItem[];

  ngOnInit(): void {
    this.socialItems = [
      {
        icon: "icon_social_instagram",
        link: "https://www.instagram.com/rem_montenapoleone"
      },
      {
        icon: "icon_social_whatsapp",
        link: "https://wa.me/390294758011"
      },
      {
        icon: "icon_social_telegram",
        link: "https://t.me/REM_Montenapoleone"
      },
      {
        icon: "icon_social_phone",
        link: "tel:800 960 831"
      },
    ];
  }
}
