import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from '../../components/base-component';

export interface RowAlignedItem {
  icon: string
  title: string
  description: string
}

@Component({
  selector: 'app-row-aligned-items',
  templateUrl: './row-aligned-items.component.html',
  styleUrls: ['./row-aligned-items.component.scss']
})
export class RowAlignedItemsComponent extends BaseComponent implements OnInit {

  @Input()
  items: RowAlignedItem[];

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

}
