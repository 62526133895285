<div
  [ngClass]="{
    'content header-margin': !isMobile,
    'header-margin-mobile': isMobile,
    'content-small': isSmall,
    'content-xsmall': isXSmall
  }"
  *transloco="let t"
>
  <img class="image-fill-cover" src="assets/images/img_night_sky.jpg" />

  <div
    class="stack-center flexible-column-centered"
    [ngClass]="{
      'container-reduced': !isMobile,
      'container-reduced-mobile': isMobile
    }"
  >
    <h1
      class="title animated-fade-in-from-bottom animation-delay-1"
      [ngClass]="{
        'text-2': !isMobile,
        'text-2-small': isSmall,
        'text-2-xsmall': isXSmall
      }"
    >
      {{ t("products.products_home.title") }}
    </h1>
    <h2
      class="subtitle animated-fade-in-from-bottom animation-delay-2"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ t("products.products_home.subtitle") }}
    </h2>
    <p
      class="description animated-fade-in-from-bottom animation-delay-3"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
      [innerHTML]="'products.products_home.description' | transloco"
    ></p>
  </div>
</div>

<div class="container-path">
  <app-products-navigation-path
    *ngIf="path != undefined"
    [path]="path"
  ></app-products-navigation-path>
</div>

<app-product-categories></app-product-categories>

<app-products-home-gift-idea-section></app-products-home-gift-idea-section>

<!-- best sellers -->
<app-products-detail-section-c></app-products-detail-section-c>

<div class="separator"></div>

<app-partner-description></app-partner-description>
