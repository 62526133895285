import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Product } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-detail-section-a',
  templateUrl: './products-detail-section-a.component.html',
  styleUrls: ['./products-detail-section-a.component.scss']
})
export class ProductsDetailSectionAComponent extends BaseComponent implements OnInit {
  @Input()
  product: Product;

  @Input()
  products: Array<Product>;

  @Input()
  filters: any;
  
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }
}
