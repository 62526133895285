<ng-container *ngIf="!isMobile; else mobile">
  <ng-container *transloco="let t">
    <div class="flexible">
      <!-- Languages -->
      <app-core-header-language-selector></app-core-header-language-selector>

      <!-- Contact us -->
      <app-core-header-contact-us></app-core-header-contact-us>
    </div>
  </ng-container>
</ng-container>

<ng-template #mobile>
  <div class="icon-container" (click)="openMobileMenu()">
    <mat-icon
      class="icon-menu icon-mobile"
      svgIcon="icon_menu_burger"
    ></mat-icon>
  </div>
</ng-template>
