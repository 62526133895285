import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MatExpansionPanelItem } from 'src/app/modules/home/shared/components/home-mat-expansion-panel/home-mat-expansion-panel.component';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-contacts-section-e',
  templateUrl: './home-contacts-section-e.component.html',
  styleUrls: ['./home-contacts-section-e.component.scss']
})
export class HomeContactsSectionEComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  items: MatExpansionPanelItem[];

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'home.contacts.section_e.items'
    ).subscribe(result => {
      this.items = result;
    });
  }
}
