import { Injectable } from '@angular/core';
import { getBrowserLang } from '@ngneat/transloco';
import { CartItem, Product } from 'src/app/openapi/api';

export interface CookieItem {
  key: string;
  value: boolean;
  mandatory: boolean;
}

export interface UserPreferences {
  email_requested_cart: boolean;
  email_requested: boolean;
  email_sent: boolean;
  language: string;
  cookie: {
    accepted: boolean;
    items: Array<CookieItem>;
  };
}

type LocalStorageType = CartItem | Product | UserPreferences;

interface ILocalStorageArray {
  getAll(): Array<LocalStorageType>;
  add(item: LocalStorageType): void;
  edit(item: LocalStorageType): void;
  delete(itemId: any): void;
  clear(): void;
}

interface ILocalStorageObject {
  isSet(): boolean;
  get(): LocalStorageType;
  set(item: LocalStorageType): void;
  clear(): void;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  readonly cart: LocalStorageCart = new LocalStorageCart();
  readonly favorites: LocalStorageFavorites = new LocalStorageFavorites();
  readonly userPreferences: LocalStorageUserPreferences =
    new LocalStorageUserPreferences();

  constructor() {}
}

class LocalStorageCart implements ILocalStorageArray {
  readonly key: string = 'cart';

  getAll(): CartItem[] {
    const data = localStorage.getItem(this.key);

    if (data == undefined) {
      return [];
    } else {
      const cart: CartItem[] = JSON.parse(data);
      return cart;
    }
  }

  add(item: CartItem): void {
    const cart: CartItem[] = this.getAll();
    item.id = cart.length;
    cart.push(item);
    localStorage.setItem(this.key, JSON.stringify(cart));
  }

  edit(item: CartItem): void {
    const cart: CartItem[] = this.getAll().filter((e) => e.id != item.id);
    cart.push(item);
    localStorage.setItem(this.key, JSON.stringify(cart));
  }

  delete(itemId: any): void {
    const cart: CartItem[] = this.getAll().filter((e) => e.id != itemId);
    localStorage.setItem(this.key, JSON.stringify(cart));
  }

  clear(): void {
    localStorage.removeItem(this.key);
  }
}

class LocalStorageFavorites implements ILocalStorageArray {
  readonly key: string = 'favorites';

  getAll(): Product[] {
    const items = localStorage.getItem(this.key);

    if (items == undefined) {
      return [];
    } else {
      const result: Product[] = JSON.parse(items);
      return result;
    }
  }

  add(item: Product): void {
    const items: Product[] = this.getAll();
    items.push(item);
    localStorage.setItem(this.key, JSON.stringify(items));
  }

  edit(item: Product): void {}

  delete(itemId: any): void {
    const items: Product[] = this.getAll().filter((e) => e.id != itemId);
    localStorage.setItem(this.key, JSON.stringify(items));
  }

  clear(): void {
    localStorage.removeItem(this.key);
  }
}

class LocalStorageUserPreferences implements ILocalStorageObject {
  readonly key: string = 'user_preferences';

  isSet(): boolean {
    return localStorage.getItem(this.key) != undefined;
  }

  get(): UserPreferences {
    const item = localStorage.getItem(this.key);

    if (item == undefined) {
      const data: UserPreferences = {
        email_requested_cart: false,
        email_requested: false,
        email_sent: false,
        language: getBrowserLang() ?? 'en',
        cookie: {
          accepted: false,
          items: [
            {
              key: '0',
              value: true,
              mandatory: true,
            },
            {
              key: '1',
              value: true,
              mandatory: false,
            },
            {
              key: '2',
              value: true,
              mandatory: false,
            },
            {
              key: '3',
              value: true,
              mandatory: false,
            },
          ],
        },
      };

      this.set(data);

      return data;
    } else {
      const result: UserPreferences = JSON.parse(item);

      this.checkIntegrity(result);

      return result;
    }
  }

  set(item: UserPreferences): void {
    localStorage.setItem(this.key, JSON.stringify(item));
  }

  clear(): void {
    localStorage.removeItem(this.key);
  }

  private checkIntegrity(preferences: UserPreferences) {
    if (preferences.email_requested_cart == undefined) {
      preferences.email_requested_cart = false;
    }

    if (preferences.email_requested == undefined) {
      preferences.email_requested = false;
    }

    if (preferences.email_sent == undefined) {
      preferences.email_sent = false;
    }

    if (preferences.language == undefined) {
      preferences.language = getBrowserLang() ?? 'en';
    }

    if (
      preferences.cookie == undefined ||
      preferences.cookie?.accepted == undefined ||
      preferences.cookie?.items == undefined ||
      preferences.cookie?.items?.length == 0
    ) {
      preferences.cookie = {
        accepted: false,
        items: [
          {
            key: '0',
            value: true,
            mandatory: true,
          },
          {
            key: '1',
            value: true,
            mandatory: false,
          },
          {
            key: '2',
            value: true,
            mandatory: false,
          },
          {
            key: '3',
            value: true,
            mandatory: false,
          },
        ],
      };
    }

    this.set(preferences);
  }
}
