<div *transloco="let t" [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'">
    <ng-container *ngIf="!isMobile; else mobile">
        <div class="content-desktop flexible">
            <div class="half-width-container">
                <app-products-detail-section-a-images [multimedia]="product.multimedia!"></app-products-detail-section-a-images>
            </div>
            <div class="half-width-container">
                <app-products-detail-section-a-content [product]="product" [products]="products" [filters]="filters"></app-products-detail-section-a-content>
            </div>
        </div>
    </ng-container>

    <ng-template #mobile>
        <div [ngClass]="{'content-images-small': isSmall, 'content-images-xsmall': isXSmall}">
            <app-products-detail-section-a-images [multimedia]="product.multimedia!"></app-products-detail-section-a-images>
        </div>
        <app-products-detail-section-a-content [product]="product" [products]="products" [filters]="filters"></app-products-detail-section-a-content>
    </ng-template>
</div>