import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsHomeComponent } from './components/products-home/products-home.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProductsDetailComponent } from './components/products-detail/products-detail.component';
import { ProductsFavoritesComponent } from './components/products-favorites/products-favorites.component';
import { PartnerDescriptionModule } from 'src/app/shared/modules/partner-description/partner-description.module';
import { ProductCategoriesModule } from 'src/app/shared/modules/product-categories/product-categories.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ProductsNavigationPathComponent } from './shared/components/products-navigation-path/products-navigation-path.component';
import { ProductsListSectionAComponent } from './components/products-list/components/products-list-section-a/products-list-section-a.component';
import { ProductsListItemComponent } from './components/products-list/components/products-list-item/products-list-item.component';
import { ProductsListFilterComponent } from './components/products-list/components/products-list-filter/products-list-filter.component';
import { ProductsListFilterItemComponent } from './components/products-list/components/products-list-filter/components/products-list-filter-item/products-list-filter-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { CircularProgressIndicatorModule } from 'src/app/shared/modules/circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from 'src/app/shared/modules/error-message/error-message.module';
import { ProductsDetailSectionAComponent } from './components/products-detail/components/products-detail-section-a/products-detail-section-a.component';
import { ProductsDetailSectionAImagesComponent } from './components/products-detail/components/products-detail-section-a/components/products-detail-section-a-images/products-detail-section-a-images.component';
import { ProductsDetailSectionAContentComponent } from './components/products-detail/components/products-detail-section-a/components/products-detail-section-a-content/products-detail-section-a-content.component';
import { SwiperModule } from 'swiper/angular';
import { ProductsDetailSectionBComponent } from './components/products-detail/components/products-detail-section-b/products-detail-section-b.component';
import { ProductDetailSectionBDescriptionComponent } from './components/products-detail/components/products-detail-section-b/components/product-detail-section-b-description/product-detail-section-b-description.component';
import { ProductDetailSectionBInfoComponent } from './components/products-detail/components/products-detail-section-b/components/product-detail-section-b-info/product-detail-section-b-info.component';
import { ProductsDetailSectionCComponent } from './components/products-detail/components/products-detail-section-c/products-detail-section-c.component';
import { ProductsFavoritesEmptyComponent } from './components/products-favorites/components/products-favorites-empty/products-favorites-empty.component';
import { ProductsFavoritesListComponent } from './components/products-favorites/components/products-favorites-list/products-favorites-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductAddedDialogComponent } from './components/product-added-dialog/product-added-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormInputFieldModule } from 'src/app/shared/modules/form-input-field/form-input-field.module';
import { PopupMenuModule } from 'src/app/shared/modules/popup-menu/popup-menu.module';
import { ProductsSearchComponent } from './components/products-search/products-search.component';
import { ProductsSearchEmptyComponent } from './components/products-search/components/products-search-empty/products-search-empty.component';
import { ProductsSearchListComponent } from './components/products-search/components/products-search-list/products-search-list.component';
import { ProductsHomeGiftIdeaSectionComponent } from './components/products-home/components/products-home-gift-idea-section/products-home-gift-idea-section.component';
import { ProductsHomeGiftsIdeaSectionCatItemComponent } from './components/products-home/components/products-home-gift-idea-section/components/products-home-gifts-idea-section-cat-item/products-home-gifts-idea-section-cat-item.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PopupCharmIncomingModule } from 'src/app/shared/modules/popup-charm-incoming/popup-charm-incoming.module';
import {MatChipsModule} from '@angular/material/chips';
import { PopupPackageInfoComponent } from './components/products-detail/components/products-detail-section-a/components/products-detail-section-a-content/components/popup-package-info/popup-package-info.component';

const sharedModules = [
  PartnerDescriptionModule,
  ProductCategoriesModule,
  FormInputFieldModule,
  CircularProgressIndicatorModule,
  ErrorMessageModule,
  PopupMenuModule,
  PopupCharmIncomingModule,
];

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatExpansionModule,
  MatDialogModule,
  MatChipsModule
];

@NgModule({
  declarations: [
    ProductsHomeComponent,
    ProductsListComponent,
    ProductsDetailComponent,
    ProductsFavoritesComponent,
    ProductsNavigationPathComponent,
    ProductsListSectionAComponent,
    ProductsListItemComponent,
    ProductsListFilterComponent,
    ProductsListFilterItemComponent,
    ProductsDetailSectionAComponent,
    ProductsDetailSectionAImagesComponent,
    ProductsDetailSectionAContentComponent,
    ProductsDetailSectionBComponent,
    ProductDetailSectionBDescriptionComponent,
    ProductDetailSectionBInfoComponent,
    ProductsDetailSectionCComponent,
    ProductsFavoritesEmptyComponent,
    ProductsFavoritesListComponent,
    ProductAddedDialogComponent,
    ProductsSearchComponent,
    ProductsSearchEmptyComponent,
    ProductsSearchListComponent,
    ProductsHomeGiftIdeaSectionComponent,
    ProductsHomeGiftsIdeaSectionCatItemComponent,
    PopupPackageInfoComponent,
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    TranslocoModule,
    SwiperModule,
    ReactiveFormsModule,
    NgxImageZoomModule,
    ...sharedModules,
    ...materialModules,
  ],
  entryComponents: [ProductAddedDialogComponent]
})
export class ProductsModule { }
