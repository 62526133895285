<div class="content flexible-column-centered" *transloco="let t">
  <div class="separator"></div>
  <div class="element-container flexible-wrap-center">
    <img
      class="image animated-fade-in animation-delay-1 to-animate"
      *ngFor="let item of images"
      src="{{ item }}"
    />
  </div>
  <div class="separator"></div>

  <div
    *ngIf="descriptions != undefined"
    class="element-container flexible-wrap-center"
  >
    <!--p
      *ngFor="let item of descriptions; let i = index; let first = first"
      innerHTML="{{ item }}"
      class="description animated-fade-in-from-bottom to-animate"
      [ngClass]="{
        'text-5 item-flex-properties': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall,
        'animation-delay-2': isMobile && first,
        'animation-delay-1': isMobile && !first
      }"
    ></p-->

    <p
      *ngFor="let item of descriptions; let i = index; let first = first"
      innerHTML="{{ item }}"
      class="description animated-fade-in-from-bottom to-animate"
      [ngClass]="
        !isMobile
          ? 'text-5 item-flex-properties item-animation-delay-' + (i + 2)
          : isSmall
          ? 'text-5-small animation-delay-' + (first ? 2 : 1)
          : isXSmall
          ? 'text-5-xsmall animation-delay-' + (first ? 2 : 1)
          : ''
      "
    ></p>
  </div>
</div>
