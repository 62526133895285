<div
  [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'"
  class="position-relative"
  *transloco="let t"
>
  <ng-container *ngIf="!isMobile; else mobile">
    <div class="content">
      <!-- Background images -->
      <div class="flexible fill-height">
        <div class="half-width-container">
          <img class="image" src="assets/images/img_model_02.jpg" />
        </div>
        <div class="half-width-container">
          <img class="image" src="assets/images/img_night_sky.jpg" />
        </div>
      </div>

      <!-- Content -->
      <div class="stack-center flexible-column-centered">
        <h1
          class="text-1 title animated-fade-in-from-bottom animation-delay-1 to-animate"
        >
          {{ t("home.home_page.section_a.title") }}
        </h1>
        <h2
          class="text-4 subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
        >
          {{ t("home.home_page.section_a.subtitle") }}
        </h2>
        <button
          class="button-1 button animated-fade-in animation-delay-3 to-animate"
          (click)="trackButtonClick()"
          mat-flat-button
        >
          {{ t("home.home_page.section_a.button") }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div class="flexible-column-centered">
      <div
        class="position-relative"
        [ngClass]="{
          'top-content-small': isSmall,
          'top-content-xsmall': isXSmall
        }"
      >
        <img class="image" src="assets/images/img_night_sky.jpg" />
        <div class="stack-center flexible-column-centered">
          <h1
            class="title-mobile animated-fade-in-from-bottom animation-delay-1 to-animate"
            [ngClass]="{ 'text-1-small': isSmall, 'text-1-xsmall': isXSmall }"
          >
            {{ t("home.home_page.section_a.title") }}
          </h1>
          <h2
            class="subtitle-mobile animated-fade-in-from-bottom animation-delay-2 to-animate"
            [ngClass]="{ 'text-4-small': isSmall, 'text-4-xsmall': isXSmall }"
          >
            {{ t("home.home_page.section_a.subtitle") }}
          </h2>
          <button
            class="button-mobile animated-fade-in animation-delay-3 to-animate"
            [ngClass]="{
              'button-1-small': isSmall,
              'button-1-xsmall': isXSmall
            }"
            (click)="trackButtonClick()"
            mat-flat-button
          >
            {{ t("home.home_page.section_a.button") }}
          </button>
        </div>
      </div>
      <img
        class="image"
        src="assets/images/img_model_02.jpg"
        [ngClass]="{
          'bottom-content-small': isSmall,
          'bottom-content-xsmall': isXSmall
        }"
      />
    </div>
  </ng-template>
</div>
