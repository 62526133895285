import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupRequestMailComponent } from '../../../popup-request-mail/components/popup-request-mail/popup-request-mail.component';
import { CookieService } from '../../services/cookie/cookie.service';

export interface CookieOptionItem {
  key: string,
  title: string,
  description: string
}

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss'],
})
export class CookiePopupComponent extends BaseComponent implements OnInit {
  items: CookieOptionItem[];

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<PopupRequestMailComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cookieService: CookieService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'cookie.popup.items'
    ).subscribe(result => {
      this.items = result;
    });
  }

  savePreferences() {
    this.cookieService.acceptConsent();
  }

  acceptAll() {
    this.cookieService.acceptAll();
  }

  refuseAll() {
    this.cookieService.refuseAll();
  }

  onClickClose() {
    this.dialogRef.close();
  }
}
