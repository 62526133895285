<ng-container *transloco="let t">
  <div class="flexible">
    <!-- Favorites -->
    <a
      routerLink="/products/products-favorites"
      (mouseenter)="showFavoritesPopup()"
      (mouseleave)="startTimeout()"
    >
      <div class="icon-container">
        <mat-icon
          [ngClass]="{ 'icon': !isMobile, 'icon-mobile': isMobile, 'icon-focused': iconFocused[0] }"
          svgIcon="icon_heart"
        ></mat-icon>
      </div>
    </a>

    <!-- Profile -->
    <a
      routerLink="/profile"
      *ngIf="!isMobile"
      (mouseenter)="showProfilePopup()"
      (mouseleave)="startTimeout()"
    >
      <div class="icon-container">
        <mat-icon
          [ngClass]="{ 'icon': !isMobile, 'icon-mobile': isMobile, 'icon-focused': iconFocused[1] }"
          svgIcon="icon_user"
        ></mat-icon>
      </div>
    </a>

    <!-- Cart -->
    <a
      routerLink="/cart"
      (mouseenter)="showCartPopup()"
      (mouseleave)="startTimeout()"
    >
      <div class="icon-container">
        <mat-icon
          [ngClass]="{ 'icon': !isMobile, 'icon-mobile': isMobile, 'icon-focused': iconFocused[2] }"
          svgIcon="icon_cart"
        ></mat-icon>
      </div>
    </a>
  </div>
</ng-container>
