<div
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  class="content flexible-column-centered"
  *transloco="let t"
>
  <ng-container *ngIf="loading; else loaded">
    <app-circular-progress-indicator
      class="flexible center-flexible"
    ></app-circular-progress-indicator>
  </ng-container>

  <ng-template #loaded>
    <ng-container *ngIf="error; else content">
      <app-error-message class="flexible center-flexible"></app-error-message>
    </ng-container>

    <ng-template #content>
      <ng-container *ngIf="cart.length == 0">
        <div class="description-container flexible-column-centered">
          <p
            class="description"
            [ngClass]="{
              'text-7': !isMobile,
              'text-7-small': isSmall,
              'text-7-xsmall': isXSmall
            }"
          >
            {{ t("cart.empty.description") }}
          </p>
        </div>

        <button
          class="button fill-width"
          [ngClass]="{
            'button-3': !isMobile,
            'button-3-small': isSmall,
            'button-3-xsmall': isXSmall
          }"
          mat-stroked-button
          (click)="onClickButtonEmpty()"
        >
          {{ t("cart.empty.button") }}
        </button>
      </ng-container>

      <ng-container *ngIf="cart.length > 0">
        <p
          [innerHTML]="
            (cart.length == 1 ? 'shared.popup_menu.cart.quantity_1' : 'shared.popup_menu.cart.quantity') | transloco : { quantity: cart.length }
          "
          class="title"
          [ngClass]="{
            'text-7': !isMobile,
            'text-7-small': isSmall,
            'text-7-xsmall': isXSmall
          }"
        ></p>

        <div class="list fill-width">
          <app-popup-menu-product-item
            *ngFor="let item of cart"
            [item]="item.product!"
            (onProductSelected)="productSelected($event)"
          ></app-popup-menu-product-item>
        </div>

        <div class="separator"></div>

        <div class="fill-width flexible-row-centered total-content">
          <p
            class="total-title text-align-left"
            [ngClass]="{
              'text-5': !isMobile,
              'text-5-small': isSmall,
              'text-5-xsmall': isXSmall
            }"
          >
            {{ t("shared.popup_menu.cart.total") }}
          </p>
          <p
            [ngClass]="{
              'text-5': !isMobile,
              'text-5-small': isSmall,
              'text-5-xsmall': isXSmall
            }"
            class="total-value"
          >
            {{ getTotalPrice() }}
          </p>
        </div>

        <button
          class="fill-width"
          [ngClass]="{
            'button-2': !isMobile,
            'button-2-small': isSmall,
            'button-2-xsmall': isXSmall
          }"
          (click)="onClickButton()"
          mat-stroked-button
        >
          {{ t("shared.popup_menu.cart.button") }}
        </button>
      </ng-container>
    </ng-template>
  </ng-template>
</div>
