<div class="content">
  <app-products-list-item
    [ngClass]="{
      item: !isMobile,
      'item-small': isSmall,
      'item-xsmall': isXSmall
    }"
    *ngFor="let product of favorites"
    [product]="product"
    [favorites]="favorites"
  ></app-products-list-item>
</div>
