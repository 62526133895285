<div class="flexible-row-centered content">
  <div class="flexible-row-centered fill-width">
    <div class="content-description fill-width">
      <h4
        [ngClass]="{
          'text-5': !isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        class="text-align-left title"
      >
        {{ item.title }}
      </h4>

      <p
        [ngClass]="{
          'text-6': !isMobile,
          'text-6-small': isSmall,
          'text-6-xsmall': isXSmall
        }"
        class="text-align-left description"
      >
        {{ item.description }}
      </p>
    </div>

    <mat-slide-toggle *ngIf="cookieItem != undefined" [disabled]="cookieItem.mandatory" [checked]="cookieItem.value" (change)="toggleChanges($event)"></mat-slide-toggle>
  </div>
</div>

<div class="separator"></div>
