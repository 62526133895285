<div *transloco="let t" class="content flexible-column-centered">
  <ng-container *ngIf="!completed; else sent">
    <h1
      class="title"
      [ngClass]="{
        'text-4': !isMobile,
        'text-4-small': isSmall,
        'text-4-xsmall': isXSmall
      }"
    >
      {{ t("home.contacts.section_b.form.title") }}
    </h1>
    <form
      [formGroup]="form"
      (ngSubmit)="submit()"
      [ngClass]="!isMobile ? 'form' : 'form-mobile'"
    >
      <div class="flexible">
        <app-form-input-field
          class="input"
          [input]="{
            label: t('home.contacts.section_b.form.label_name'),
            errorMessage: t('home.contacts.section_b.form.invalid_value'),
            controller: getFormController('name'),
            maxLength: '50',
            type: 'text'
          }"
        >
        </app-form-input-field>
        <app-form-input-field
          class="input"
          [input]="{
            label: t('home.contacts.section_b.form.label_surname'),
            errorMessage: t('home.contacts.section_b.form.invalid_value'),
            controller: getFormController('surname'),
            maxLength: '50',
            type: 'text'
          }"
        >
        </app-form-input-field>
      </div>

      <div class="flexible">
        <app-form-input-field
          class="input"
          [input]="{
            label: t('home.contacts.section_b.form.label_email'),
            errorMessage: t('home.contacts.section_b.form.invalid_value'),
            controller: getFormController('email'),
            maxLength: '100',
            type: 'text'
          }"
        >
        </app-form-input-field>
        <app-form-input-field
          class="input"
          [input]="{
            label: t('home.contacts.section_b.form.label_phone'),
            errorMessage: t('home.contacts.section_b.form.invalid_value'),
            controller: getFormController('phone'),
            maxLength: '30',
            type: 'text'
          }"
        >
        </app-form-input-field>
      </div>

      <div class="flexible">
        <app-form-input-field
          class="input"
          [input]="{
            label: t('home.contacts.section_b.form.label_message'),
            errorMessage: t('home.contacts.section_b.form.invalid_value'),
            controller: getFormController('message'),
            maxLength: '1000',
            type: 'text-area'
          }"
        >
        </app-form-input-field>
      </div>

      <div class="flexible-column-centered">
        <button
          *ngIf="!loading"
          class="button"
          [ngClass]="{
            'button-2': !isMobile,
            'button-2-small': isSmall,
            'button-2-xsmall': isXSmall
          }"
          mat-stroked-button
        >
          {{ t("home.contacts.section_b.form.button") }}
        </button>

        <app-circular-progress-indicator
          *ngIf="loading"
        ></app-circular-progress-indicator>

        <app-error-message *ngIf="error"></app-error-message>
      </div>
    </form>
  </ng-container>

  <ng-template #sent>
    <h1
      class="title"
      [ngClass]="{
        'text-4': !isMobile,
        'text-4-small': isSmall,
        'text-4-xsmall': isXSmall
      }"
    >
      {{ t("home.contacts.section_b.completed.title") }}
    </h1>
    <p
      class="description"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ t("home.contacts.section_b.completed.description") }}
    </p>
  </ng-template>
</div>
