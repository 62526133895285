<div
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  class="content flexible-column-centered"
  *transloco="let t"
>
  <div
    *ngFor="let element of item.items"
    class="fill-width item-container"
    (click)="onClickItem(element)"
  >
    <p
      class="item text-align-left"
      [ngClass]="{
        'text-6': !isMobile,
        'text-6-small': isSmall,
        'text-6-xsmall': isXSmall
      }"
    >
      {{ element.title }}
    </p>
  </div>
</div>