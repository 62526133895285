import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { FooterBottomItem } from './components/core-footer-bottom-item/core-footer-bottom-item.component';

@Component({
  selector: 'app-core-footer-bottom',
  templateUrl: './core-footer-bottom.component.html',
  styleUrls: ['./core-footer-bottom.component.scss']
})
export class CoreFooterBottomComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  items: FooterBottomItem[];

  ngOnInit(): void {  
    this.transloco.selectTranslateObject(
      'core.footer.bottom_items'
    ).subscribe(result => {
      this.items = result;
    });
  }
}
