/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Multimedia } from './multimedia';
import { LocalizedString } from './localizedString';


/**
 * 
 */
export interface Product { 
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    name?: Array<LocalizedString>;
    /**
     * 
     */
    description?: Array<LocalizedString>;
    /**
     * 
     */
    price?: string;
    category?: Product.CategoryEnum;
    metal?: Product.MetalEnum;
    gemstone?: Product.GemstoneEnum;
    packaging?: Product.PackagingEnum;
    measure?: Product.MeasureEnum;
    /**
     * 
     */
    multimedia?: Array<Multimedia>;
}
export namespace Product {
    export type CategoryEnum = 'CHARM' | 'BRACELET' | 'NECKLACE';
    export const CategoryEnum = {
        Charm: 'CHARM' as CategoryEnum,
        Bracelet: 'BRACELET' as CategoryEnum,
        Necklace: 'NECKLACE' as CategoryEnum
    };
    export type MetalEnum = 'GOLD' | 'WHITE_GOLD' | 'SILVER';
    export const MetalEnum = {
        Gold: 'GOLD' as MetalEnum,
        WhiteGold: 'WHITE_GOLD' as MetalEnum,
        Silver: 'SILVER' as MetalEnum
    };
    export type GemstoneEnum = 'PEARL' | 'BLACK_QUARTZ';
    export const GemstoneEnum = {
        Pearl: 'PEARL' as GemstoneEnum,
        BlackQuartz: 'BLACK_QUARTZ' as GemstoneEnum
    };
    export type PackagingEnum = 'STANDARD' | 'PREMIUM';
    export const PackagingEnum = {
        Standard: 'STANDARD' as PackagingEnum,
        Premium: 'PREMIUM' as PackagingEnum
    };
    export type MeasureEnum = 'STANDARD' | 'REDUCED';
    export const MeasureEnum = {
        Standard: 'STANDARD' as MeasureEnum,
        Reduced: 'REDUCED' as MeasureEnum
    };
}


