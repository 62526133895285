<ng-container *ngIf="!isMobile; else mobile">
  <div
    class="flexible content-height"
    [ngClass]="{ 'flex-direction-row-reverse': !imageFirst }"
  >
    <div class="half-width-container">
      <img class="image-fill-cover" src="{{ category.image }}" />
    </div>
    <div class="half-width-container background-color">
      <div class="content-text flexible-column-centered">
        <h2
          class="text-3 text-align-left title animation-delay-1 to-animate"
          [ngClass]="
            imageFirst
              ? 'animated-fade-in-from-right'
              : 'animated-fade-in-from-left'
          "
        >
          {{ category.title }}
        </h2>
        <p
          class="text-5 text-align-left animation-delay-2 to-animate"
          [ngClass]="
            imageFirst
              ? 'animated-fade-in-from-right'
              : 'animated-fade-in-from-left'
          "
          innerHTML="{{ category.description }}"
        ></p>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="flexible-column-centered">
    <div
      class="image-container-mobile"
      [ngClass]="{
        'image-container-mobile-small': isSmall,
        'image-container-mobile-xsmall': isXSmall
      }"
    >
      <img class="image-fill-cover" src="{{ category.image }}" />
    </div>
    <div class="background-color">
      <div class="content-mobile flexible-column-centered">
        <h2
          class="text-align-left title"
          [ngClass]="{ 'text-3-small': isSmall, 'text-3-xsmall': isXSmall }"
        >
          {{ category.title }}
        </h2>
        <p
          class="text-align-left"
          [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
          innerHTML="{{ category.description }}"
        ></p>
      </div>
    </div>
  </div>
</ng-template>
