<div class="flexible-row-centered content" (click)="productSelected()">
  <div class="image-container fill">
    <img class="image" src="{{ imageUrl }}" />
  </div>

  <div class="flexible-column-centered info-container">
    <span
      class="fill-width title text-align-left"
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
      >{{ getLocalizedString(item.name) }}</span
    >
    <span
      class="fill-width text-align-left"
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
      >{{ metal }} - {{ gemstone }}</span
    >
    <span
      class="fill-width text-align-left"
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
    >
      {{ item.price }} €</span
    >
  </div>
</div>
