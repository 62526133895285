<div [ngClass]="{ content: !isMobile, 'content-mobile': isMobile }">
  <div
    [ngClass]="{
      'flexible-row-centered reduced-content': !isMobile,
      'flexible-column-centered reduced-content-mobile': isMobile
    }"
  >
    <ng-container *ngIf="items != undefined">
      <app-core-footer-top-item
        *ngFor="let item of items; let last = last"
        [item]="item"
        [hasSeparator]="!last"
        [ngClass]="{ item: !isMobile, 'item-mobile': isMobile }"
      >
      </app-core-footer-top-item>
    </ng-container>
  </div>
</div>
