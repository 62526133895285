<ng-container *ngIf="title != undefined">
  <app-products-list-section-a
    [title]="title"
    [description]="description"
    [image]="image"
  >
  </app-products-list-section-a>

  <div class="container-path">
    <app-products-navigation-path [path]="path"></app-products-navigation-path>
  </div>

  <!-- TODO implements products filter
    <app-products-list-filter></app-products-list-filter>
    -->

  <app-error-message *ngIf="error"></app-error-message>

  <app-circular-progress-indicator
    *ngIf="!loaded && !error"
  ></app-circular-progress-indicator>

  <div *ngIf="loaded" class="content">
    <app-products-list-item
      [ngClass]="{
        'item': !isMobile,
        'item-small': isSmall,
        'item-xsmall': isXSmall
      }"
      *ngFor="let product of products"
      [product]="product"
      [favorites]="favorites"
    ></app-products-list-item>
  </div>
</ng-container>
