<div
  class="content"
  [ngClass]="{
    'content-height': !isMobile,
    'content-height-small': isSmall,
    'content-height-xsmall': isXSmall
  }"
>
  <div class="reduced-content flexible-column-centered">
    <h2
      class="title"
      [ngClass]="{
        'text-4': !isMobile,
        'text-4-small': isSmall,
        'text-4-xsmall': isXSmall
      }"
    >
      {{ item.title }}
    </h2>
    <p
      class="description"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
      innerHTML="{{ item.description }}"
    ></p>

    <button
      *ngIf="item.button != ''"
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      [routerLink]="item.route == '' ? null : item.route"
      mat-stroked-button
      innerHTML="{{ item.button }}"
    ></button>

    <div class="content-icons" *ngIf="item.socials != undefined">
      <a
        *ngFor="let item of item.socials"
        href="{{ item.href }}"
        target="_blank"
      >
        <mat-icon class="icon" svgIcon="{{ item.icon }}"></mat-icon>
      </a>
    </div>
  </div>
</div>
