import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { MobileMenuItem } from './components/core-header-mobile-menu-item/core-header-mobile-menu-item.component';

@Component({
  selector: 'app-core-header-mobile-menu',
  templateUrl: './core-header-mobile-menu.component.html',
  styleUrls: ['./core-header-mobile-menu.component.scss']
})
export class CoreHeaderMobileMenuComponent extends BaseComponent implements OnInit {
  @Output() onCloseMenu = new EventEmitter<void>();

  @Input()
  showMobileMenu: boolean;

  menu: MobileMenuItem[];

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'core.header.menu'
    ).subscribe(result => {
      this.menu = result;
    });
  }

  onClickCloseMenu() {
    this.onCloseMenu.emit();
  }
}
