import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface ProductListFilterItem {
  title: string,
  items: [
    {
      title: string,
      checked: boolean,
    }
  ]
}

@Component({
  selector: 'app-products-list-filter-item',
  templateUrl: './products-list-filter-item.component.html',
  styleUrls: ['./products-list-filter-item.component.scss']
})
export class ProductsListFilterItemComponent extends BaseComponent implements OnInit {
  @Input()
  item: ProductListFilterItem;

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    
  }
}
