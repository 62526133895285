import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupMenuProfileComponent } from './components/popup-menu-profile/popup-menu-profile.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CircularProgressIndicatorModule } from '../circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { PopupMenuFavoritesComponent } from './components/popup-menu-favorites/popup-menu-favorites.component';
import { PopupMenuCartComponent } from './components/popup-menu-cart/popup-menu-cart.component';
import { TranslocoModule } from '@ngneat/transloco';
import { PopupMenuProductItemComponent } from './components/popup-menu-product-item/popup-menu-product-item.component';
import { RouterModule } from '@angular/router';
import { PopupMenuHomeInfoComponent } from './components/popup-menu-home-info/popup-menu-home-info.component';

const sharedModules = [
  CircularProgressIndicatorModule,
  ErrorMessageModule,
];

const materialModules = [
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [
    PopupMenuProfileComponent,
    PopupMenuFavoritesComponent,
    PopupMenuCartComponent,
    PopupMenuProductItemComponent,
    PopupMenuHomeInfoComponent,
  ],
  exports: [
    PopupMenuProfileComponent,
    PopupMenuFavoritesComponent,
    PopupMenuCartComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    ...sharedModules,
    ...materialModules
  ]
})
export class PopupMenuModule { }
