import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomePrivacyTermsHeader {
  imgSrc: string
  titleTranslocoKey: string
}

@Component({
  selector: 'app-home-privacy-terms-header',
  templateUrl: './home-privacy-terms-header.component.html',
  styleUrls: ['./home-privacy-terms-header.component.scss']
})
export class HomePrivacyTermsHeaderComponent  extends BaseComponent implements OnInit {

  @Input()
  header: HomePrivacyTermsHeader;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }
  
  ngOnInit(): void {
  }

}
