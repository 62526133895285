<div *transloco="let t">
  <h2
    class="text-align-left title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("products.products_detail.section_b.title_1") }}
  </h2>
  <p
    class="text-align-left description"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    [innerHTML]="getLocalizedString(product.description)"
  ></p>
</div>
