<div class="position-relative fill-height" [ngClass]="{'content-desktop': !isMobile, 'content-mobile': isMobile}" *transloco="let t">
    <!-- Background images -->
    <div class="fill">
        <img class="image-fill-cover" src="assets/images/img_night_sky.jpg">
    </div>

    <!-- Content -->
    <div class="stack-center flexible-column-centered">
        <h2 class="text-3 title text-align-left">{{ t('products.products_detail.section_b.title_2') }}</h2>
        <p class="text-5 description text-align-left" [innerHTML]="'products.products_detail.section_b.description' | transloco"></p>
    </div>
</div>