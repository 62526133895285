import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { HomeModule } from './modules/home/home.module';
import { ProductsModule } from './modules/products/products.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule, Configuration, ConfigurationParameters } from './openapi/api';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { PopupRequestMailModule } from './shared/modules/popup-request-mail/popup-request-mail.module';
import { CookieModule } from './shared/modules/cookie/cookie.module';
import { PopupCharmIncomingModule } from './shared/modules/popup-charm-incoming/popup-charm-incoming.module';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
  }
  return new Configuration(params);
}

const firebaseImports = [
  AngularFireModule.initializeApp(environment.firebase),
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HomeModule,
    ProductsModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    ApiModule.forRoot(apiConfigFactory),
    ...firebaseImports,
    PopupRequestMailModule,
    PopupCharmIncomingModule,
    CookieModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
