<div *transloco="let t" class="content">
  <p
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    class="text-align-left message"
    [innerHTML]="
      'products.products_search.empty.message' | transloco : { query: query }
    "
  ></p>

  <h2
    class="subtitle"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
    class="text-align-left title"
  >
    {{ t("products.products_search.empty.title") }}
  </h2>

  <p
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    class="text-align-left description"
  >
    {{ t("products.products_search.empty.description") }}
  </p>
</div>
