<div *transloco="let t" class="margin-top">
  <ng-container *ngIf="!isMobile; else mobile">
    <div class="flexible content-height">
      <div class="half-width-container">
        <img class="image-fill-cover" src="assets/images/img_technology.jpg" />
      </div>
      <div class="half-width-container background-color">
        <div class="content-text flexible-column-centered">
          <h2
            class="text-3 text-align-left title animated-fade-in-from-bottom animation-delay-1 to-animate"
          >
            {{ t("home.rem_innovation.section_b.title") }}
          </h2>
          <p
            class="text-5 text-align-left animated-fade-in-from-bottom animation-delay-2 to-animate"
            [innerHTML]="
              'home.rem_innovation.section_b.description' | transloco
            "
          ></p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div class="flexible-column-centered">
      <div
        class="image-container-mobile"
        [ngClass]="{
          'image-container-mobile-small': isSmall,
          'image-container-mobile-xsmall': isXSmall
        }"
      >
        <img class="image-fill-cover" src="assets/images/img_technology.jpg" />
      </div>
      <div class="background-color">
        <div class="content-mobile flexible-column-centered">
          <h2
            class="text-align-left title animated-fade-in-from-bottom animation-delay-1 to-animate"
            [ngClass]="{ 'text-3-small': isSmall, 'text-3-xsmall': isXSmall }"
          >
            {{ t("home.rem_innovation.section_b.title") }}
          </h2>
          <p
            class="text-align-left animated-fade-in-from-bottom animation-delay-2 to-animate"
            [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
            [innerHTML]="
              'home.rem_innovation.section_b.description' | transloco
            "
          ></p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
