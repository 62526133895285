<div class="content" *transloco="let t">
  <img class="image-fill-cover image" src="assets/images/img_night_sky.jpg" />

  <div class="menu-content" *ngIf="showMobileMenu">
    <div class="flexible-column-centered">
      <mat-icon class="logo" svgIcon="logo_rem"></mat-icon>
    </div>

    <div class="icon-close-container" (click)="onClickCloseMenu()">
      <mat-icon class="icon-close" svgIcon="icon_x"></mat-icon>
    </div>

    <app-core-header-mobile-menu-item
      *ngFor="let item of menu"
      [item]="item"
      (onCloseMenu)="onClickCloseMenu()"
    ></app-core-header-mobile-menu-item>

    <div class="search-margin">
      <app-core-header-mobile-menu-search
        (onCloseMenu)="onClickCloseMenu()"
      ></app-core-header-mobile-menu-search>
    </div>
    <app-core-header-language-selector></app-core-header-language-selector>
    <app-core-header-contact-us
      (click)="onClickCloseMenu()"
    ></app-core-header-contact-us>
    <app-core-header-sign-in
      (click)="onClickCloseMenu()"
      class="last-item"
    ></app-core-header-sign-in>
  </div>
</div>
