import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-home-page-section-b',
  templateUrl: './home-home-page-section-b.component.html',
  styleUrls: ['./home-home-page-section-b.component.scss'],
})
export class HomeHomePageSectionBComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {}

  trackButtonClick() {
    window.dataLayer.push({
      event: 'home_how_it_works_click',
    });
    this.router.navigate(['/home/innovation']);
  }
}
