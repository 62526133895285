import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  CookieItem,
  LocalStorageService,
  UserPreferences,
} from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { PopupRequestMailService } from '../../../popup-request-mail/services/popup-request-mail/popup-request-mail.service';
import { CookieBannerComponent } from '../../components/cookie-banner/cookie-banner.component';
import { CookiePopupComponent } from '../../components/cookie-popup/cookie-popup.component';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  isMobile: boolean;

  private bannerDialogRef: any;
  private popupDialogRef: any;

  constructor(
    private responsive: ResponsiveService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router,
    private popupRequestMailService: PopupRequestMailService
  ) {
    this.responsive.observeResponsive().subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.launchBanner();
      }
    });
  }

  launchBanner() {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();

    if (userPreferences.cookie.accepted != true) {
      this.popupRequestMailService.cancelDialogTimer();

      this.bannerDialogRef = this.dialog.open(CookieBannerComponent, {
        //https://stackoverflow.com/a/45933595
        panelClass: 'squared-dialog-container',
        disableClose: true,
        autoFocus: false,
        position: {
          bottom: '0',
        },
        width: '100%',
        minWidth: '100%',
      });

      this.bannerDialogRef.afterClosed().subscribe(() => {
        this.popupRequestMailService.launchDialog();
      });
    }
  }

  launchPopup() {
    this.popupDialogRef = this.dialog.open(CookiePopupComponent, {
      //https://stackoverflow.com/a/45933595
      panelClass: 'dialog-container-border',
      disableClose: true,
      autoFocus: false,
      width: !this.isMobile ? '35em' : '100%',
      minWidth: !this.isMobile ? '45em' : '100%',
    });
  }

  acceptConsent() {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    userPreferences.cookie.accepted = true;
    this.localStorageService.userPreferences.set(userPreferences);
    this.bannerDialogRef?.close();
    this.popupDialogRef?.close();
  }

  acceptAll() {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    userPreferences.cookie.items.forEach(e => e.value = true);
    this.localStorageService.userPreferences.set(userPreferences);

    this.acceptConsent();
  }

  refuseAll() {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    userPreferences.cookie.items.forEach(e => e.value = e.mandatory ? true : false);
    this.localStorageService.userPreferences.set(userPreferences);

    this.acceptConsent();
  }

  setItem(cookieItem: CookieItem) {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    userPreferences.cookie.items.filter(e => e.key == cookieItem.key)[0].value = cookieItem.value;
    this.localStorageService.userPreferences.set(userPreferences);
  }

  getItem(key: string): CookieItem {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    const result = userPreferences.cookie.items.filter(e => e.key == key)[0];
    return result;
  }
}
