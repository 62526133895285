<ng-container *ngIf="!isMobile; else mobile">
  <div
    class="position-relative flexible content"
    (mouseenter)="showContent = true"
    (mouseleave)="showContent = false"
  >
    <img class="image" src="{{ item.image }}" />

    <div
      class="fill flexible-column-centered section-content"
      *ngIf="showContent"
      [@enterAnimation]
    >
      <mat-icon class="icon" svgIcon="logo_rem_icon"></mat-icon>
      <h2 class="text-3 title">{{ item.title }}</h2>
      <p class="text-5 description" innerHTML="{{ item.description }}"></p>
      <button
        class="button-3 button"
        routerLink="{{ item.route }}"
        mat-stroked-button
      >
        {{ item.button }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="content-mobile flexible-column-centered">
    <img
      class="image animated-fade-in animation-delay-1 to-animate"
      src="{{ item.image }}"
    />
    <mat-icon
      class="icon animated-fade-in-from-bottom animation-delay-1 to-animate"
      svgIcon="logo_rem_icon"
    ></mat-icon>
    <h1
      [ngClass]="{ 'text-3-small': isSmall, 'text-3-xsmall': isXSmall }"
      class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    >
      {{ item.title }}
    </h1>
    <p
      [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
      class="description-mobile animated-fade-in-from-bottom animation-delay-2 to-animate"
      innerHTML="{{ item.description }}"
    ></p>
    <button
      class="button-3 button-mobile animated-fade-in animation-delay-2 to-animate"
      routerLink="{{ item.route }}"
      mat-stroked-button
    >
      {{ item.button }}
    </button>
  </div>
</ng-template>
