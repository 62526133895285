import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomeContactsSectionAItem {
  title: string;
  description: string;
  button: string;
  socials: Array<{ icon: string; href: string; }>
  route: string;
}

@Component({
  selector: 'app-home-contacts-section-a-item',
  templateUrl: './home-contacts-section-a-item.component.html',
  styleUrls: ['./home-contacts-section-a-item.component.scss']
})
export class HomeContactsSectionAItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  item: HomeContactsSectionAItem;

  ngOnInit(): void {
  }
}
