<ng-container *ngIf="!isMobile; else mobile">
  <div *transloco="let t" class="content">
    <img class="image-background" src="assets/images/img_night_sky.jpg" />

    <div class="stack-center content-size flexible-column-centered">
      <div class="animated-fade-in-from-bottom animation-delay-1 to-animate">
        <h1 class="text-1 title-1">
          {{ t("home.home_page.section_e.title_1") }}
        </h1>
        <h1 class="text-2 title-2">
          {{ t("home.home_page.section_e.title_2") }}
        </h1>
      </div>
      <p
        class="text-5 subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
      >
        {{ t("home.home_page.section_e.subtitle") }}
      </p>

      <div *ngIf="items != undefined" class="items-container">
        <app-home-home-page-section-e-item
          *ngFor="let item of items"
          [item]="item"
          class="item"
        >
        </app-home-home-page-section-e-item>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <div *transloco="let t" class="content-mobile">
    <img
      [ngClass]="{
        'image-background-mobile-small': isSmall,
        'image-background-mobile-xsmall': isXSmall
      }"
      src="assets/images/img_night_sky.jpg"
    />

    <div class="position-contents-mobile flexible-column-centered">
      <div class="animated-fade-in-from-bottom animation-delay-1 to-animate">
        <h1
          class="title-1"
          [ngClass]="{ 'text-1-small': isSmall, 'text-1-xsmall': isXSmall }"
        >
          {{ t("home.home_page.section_e.title_1") }}
        </h1>
        <h2
          class="title-2"
          [ngClass]="{ 'text-2-small': isSmall, 'text-2-xsmall': isXSmall }"
        >
          {{ t("home.home_page.section_e.title_2") }}
        </h2>
      </div>
      <p
        class="text-5 subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
        [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
      >
        {{ t("home.home_page.section_e.subtitle") }}
      </p>

      <div *ngIf="items != undefined" class="items-container-mobile">
        <app-home-home-page-section-e-item
          *ngFor="let item of items"
          [item]="item"
        >
        </app-home-home-page-section-e-item>
      </div>
    </div>
  </div>
</ng-template>
