<div class="content flexible-column-centered" *transloco="let t">
  <div
    [ngClass]="{
      'image-container': !isMobile,
      'image-container-small': isSmall,
      'image-container-xsmall': isXSmall
    }"
  >
    <img
      class="image-fill-cover"
      src="assets/images/img_valori_madeinitaly.jpg"
    />
  </div>
  <h2
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("home.about_us.section_d.title") }}
  </h2>
  <p
    class="animated-fade-in-from-bottom animation-delay-2 to-animate"
    [ngClass]="{
      'text-5 description': !isMobile,
      'description-mobile': isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    [innerHTML]="'home.about_us.section_d.description' | transloco"
  ></p>
</div>
