/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductOrdination } from './productOrdination';
import { User } from './user';
import { AddressOrdination } from './addressOrdination';


/**
 * 
 */
export interface Ordination { 
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    price?: string;
    /**
     * 
     */
    product_ordinations?: Array<ProductOrdination>;
    user?: User;
    /**
     * 
     */
    creation_date?: string;
    /**
     * 
     */
    payment_type?: Ordination.PaymentTypeEnum;
    /**
     * 
     */
    status?: Ordination.StatusEnum;
    address_ordination?: AddressOrdination;
    /**
     * 
     */
    paypal_order_id?: string;
}
export namespace Ordination {
    export type PaymentTypeEnum = 'CARD' | 'PAYPAL' | 'BANK';
    export const PaymentTypeEnum = {
        Card: 'CARD' as PaymentTypeEnum,
        Paypal: 'PAYPAL' as PaymentTypeEnum,
        Bank: 'BANK' as PaymentTypeEnum
    };
    export type StatusEnum = 'CREATED' | 'CONFIRMED';
    export const StatusEnum = {
        Created: 'CREATED' as StatusEnum,
        Confirmed: 'CONFIRMED' as StatusEnum
    };
}


