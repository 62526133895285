import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupMenuHomeInfoComponent } from 'src/app/shared/modules/popup-menu/components/popup-menu-home-info/popup-menu-home-info.component';
import { CoreHeaderSearchPopupComponent } from '../core-header-search-popup/core-header-search-popup.component';

interface MenuItem {
  title: string;
  route: string;
}

@Component({
  selector: 'app-core-header-bottom',
  templateUrl: './core-header-bottom.component.html',
  styleUrls: ['./core-header-bottom.component.scss'],
})
export class CoreHeaderBottomComponent extends BaseComponent implements OnInit {
  menu: MenuItem[];

  @ViewChild('anchor_home_info', { static: false })
  public anchorHomeInfoRef: ElementRef;

  focused: boolean;
  private dialogHomeInfoRef: any;
  private dialogHomeInfoSubscription: any;
  private closeHomeInfoPopupTimer: NodeJS.Timeout;

  private dialogSearch: any;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('core.header.menu')
      .subscribe((result) => {
        this.menu = result;
      });
  }

  startTimeout() {
    this.closeHomeInfoPopupTimer = setTimeout(() => {
      this.clearDialogData();
    }, 250);
  }

  private clearDialogData() {
    this.focused = false;
    clearTimeout(this.closeHomeInfoPopupTimer);
    this.dialogHomeInfoSubscription?.unsubscribe();
    this.dialogHomeInfoRef?.close();
  }

  showHomeInfoPopup() {
    if (!this.isMobile) {
      this.clearDialogData();
      this.focused = true;
      this.dialogHomeInfoRef = this.dialog.open(PopupMenuHomeInfoComponent, {
        disableClose: true,
        autoFocus: false,
        hasBackdrop: false,
        data: { positionRelativeToElement: this.anchorHomeInfoRef },
      });
      this.dialogHomeInfoSubscription =
        this.dialogHomeInfoRef.componentInstance.isFocused.subscribe(
          (isFocused: boolean) => {
            if (isFocused) {
              clearTimeout(this.closeHomeInfoPopupTimer);
            } else {
              this.startTimeout();
            }
          }
        );

      this.dialogHomeInfoRef.afterClosed().subscribe((data?: string) => {
        if(data != undefined) {
          this.router.navigate([data]);
        }
      });
    }
  }

  onClickItem(route: string) {
    this.clearDialogData();

    setTimeout(() => {
      this.router.navigate([route]);
    }, 250);
  }

  onClickSearch() {
    this.dialogSearch = this.dialog.open(CoreHeaderSearchPopupComponent, {
      panelClass: 'squared-dialog-container',
      disableClose: false,
      autoFocus: true,
      width: '35em',
      position: {
        top: '11em'
      }
    });
  }
}
