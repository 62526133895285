<div
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  class="content flexible-column-centered"
  *transloco="let t"
>
  <ng-container *ngIf="!isAuthenticated(); else content">
    <p
      class="title"
      [ngClass]="{
        'text-6': !isMobile,
        'text-6-small': isSmall,
        'text-6-xsmall': isXSmall
      }"
    >
      {{ t("shared.popup_menu.profile.not_authenticated.title") }}
    </p>

    <div class="description-container flexible-column-centered">
      <p
        class="description"
        [ngClass]="{
          'text-7': !isMobile,
          'text-7-small': isSmall,
          'text-7-xsmall': isXSmall
        }"
        [innerHTML]="
          'shared.popup_menu.profile.not_authenticated.description' | transloco
        "
      ></p>
    </div>

    <button
      class="fill-width"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small': isSmall,
        'button-2-xsmall': isXSmall
      }"
      mat-stroked-button
      (click)="onClickSignIn()"
    >
      {{ t("shared.popup_menu.profile.not_authenticated.button_1") }}
    </button>

    <button
      class="button-signup fill-width"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      mat-stroked-button
      (click)="onClickSignUp()"
    >
      {{ t("shared.popup_menu.profile.not_authenticated.button_2") }}
    </button>
  </ng-container>

  <ng-template #content>
    <div
      *ngFor="let item of items; let last = last"
      class="fill-width item-container"
      (click)="onClickItem(item)"
    >
      <div *ngIf="last" class="separator"></div>

      <p
        class="item text-align-left"
        [ngClass]="{
          'text-6': !isMobile,
          'text-6-small': isSmall,
          'text-6-xsmall': isXSmall
        }"
      >
        {{ item.title }}
      </p>
    </div>
  </ng-template>
</div>
