import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export class MatExpansionPanelItem {
  title: string;
  description: string;
}

@Component({
  selector: 'app-home-mat-expansion-panel',
  templateUrl: './home-mat-expansion-panel.component.html',
  styleUrls: ['./home-mat-expansion-panel.component.scss']
})
export class HomeMatExpansionPanelComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  @Input()
  viewSeparatorAtLastRow: boolean;

  @Input()
  items: MatExpansionPanelItem[];

  ngOnInit(): void {
  }

}
