<div class="content" *transloco="let t">
  <ng-container *ngIf="!isMobile; else mobile">
    <div class="flexible-column-centered">
      <!--Content -->
      <div class="flexible content-height">
        <div class="half-width-container background-color">
          <div class="flexible-column-centered translated-box">
            <h1
              routerLink="/products"
              class="text-2 title animated-fade-in-from-bottom animation-delay-1 to-animate"
            >
              {{ t("home.home_page.section_b.title") }}
            </h1>
            <h2
              class="text-5 subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
            >
              {{ t("home.home_page.section_b.subtitle") }}
            </h2>
            <p
              class="text-5 description animated-fade-in-from-bottom animation-delay-3 to-animate"
              [innerHTML]="'home.home_page.section_b.description' | transloco"
            ></p>

            <img
              class="image-phones animated-zoom-in animation-delay-1 to-animate"
              src="assets/images/img_mockup_phones_rem_montenapoleone_webapp.gif"
            />
          </div>
        </div>
        <div class="half-width-container">
          <img
            class="image"
            src="assets/images/img_rem_gioielli_tecnologici.png"
          />
        </div>
      </div>

      <!-- Button -->
      <button
        class="button-2 button animated-fade-in animation-delay-2 to-animate"
        (click)="trackButtonClick()"
        mat-flat-button
      >
        {{ t("home.home_page.section_b.button") }}
      </button>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div class="flexible-column-centered">
      <img
        class="image"
        [ngClass]="{
          'top-content-small': isSmall,
          'top-content-xsmall': isXSmall
        }"
        src="assets/images/img_rem_gioielli_tecnologici.png"
      />
      <div
        class="background-color"
        [ngClass]="{
          'bottom-content-small': isSmall,
          'bottom-content-xsmall': isXSmall
        }"
      >
        <div class="flexible-column-centered translated-box-mobile">
          <h1
            routerLink="/products"
            class="title-mobile animated-fade-in-from-bottom animation-delay-1 to-animate"
            [ngClass]="{ 'text-2-small': isSmall, 'text-2-xsmall': isXSmall }"
          >
            {{ t("home.home_page.section_b.title") }}
          </h1>
          <h2
            class="subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
            [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
          >
            {{ t("home.home_page.section_b.subtitle") }}
          </h2>
          <p
            class="description-mobile animated-fade-in-from-bottom animation-delay-3 to-animate"
            [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
            [innerHTML]="'home.home_page.section_b.description' | transloco"
          ></p>

          <img
            class="image-phones-mobile animated-zoom-in animation-delay-1 to-animate"
            src="assets/images/img_mockup_phones_rem_montenapoleone_webapp.gif"
          />
        </div>
      </div>
      <!-- Button -->
      <button
        class="button animated-fade-in animation-delay-2 to-animate"
        [ngClass]="{ 'button-2-small': isSmall, 'button-2-xsmall': isXSmall }"
        (click)="trackButtonClick()"
        mat-flat-button
      >
        {{ t("home.home_page.section_b.button") }}
      </button>
    </div>
  </ng-template>
</div>
