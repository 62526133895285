import { Injectable } from '@angular/core';
import { lastValueFrom, retry } from 'rxjs';
import { UserService, CartItem, Product } from 'src/app/openapi/api';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

const cartKey = 'cart';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private authService: AuthenticationService,
    private userApi: UserService,
    private localStorageService: LocalStorageService
  ) {}

  async getCart(): Promise<CartItem[]> {
    if (this.authService.isAuthenticated()) {
      const cart = await this.getOnlineCart();
      const merged = await this.mergeCart(cart);
      return merged;
    } else {
      return this.localStorageService.cart.getAll();
    }
  }

  async postCartItem(cartItem: CartItem): Promise<void> {
    if (this.authService.isAuthenticated()) {
      await this.authService.updateApiCredentials();
      await lastValueFrom(
        this.userApi.postUserCartItem(cartItem).pipe(retry(3))
      );
    } else {
      this.localStorageService.cart.add(cartItem);
    }
  }

  async putCartItem(cartItem: CartItem): Promise<void> {
    if (this.authService.isAuthenticated()) {
      await this.authService.updateApiCredentials();
      await lastValueFrom(
        this.userApi.putUserCartItem(cartItem.id!, cartItem).pipe(retry(3))
      );
    } else {
      this.localStorageService.cart.edit(cartItem);
    }
  }

  async deleteCartItem(cart_item_id: number): Promise<void> {
    if (this.authService.isAuthenticated()) {
      await this.authService.updateApiCredentials();
      await lastValueFrom(
        this.userApi.deleteUserCartItem(cart_item_id).pipe(retry(3))
      );
    } else {
      this.localStorageService.cart.delete(cart_item_id);
    }
  }

  private async getOnlineCart(): Promise<CartItem[]> {
    await this.authService.updateApiCredentials();
    const cart = await lastValueFrom(this.userApi.getUserCart().pipe(retry(3)));
    return cart;
  }

  // Merge online & offline cart
  // https://stackoverflow.com/a/54134237
  private async mergeCart(onlineCart: CartItem[]): Promise<CartItem[]> {
    let offline = this.localStorageService.cart.getAll();

    await this.authService.updateApiCredentials();
    for (let item of offline) {
      const cartItem = await lastValueFrom(
        this.userApi.postUserCartItem(item).pipe(retry(3))
      );

      item.id = cartItem.id;
      onlineCart.push(item);
    }

    this.localStorageService.cart.clear();

    return onlineCart;
  }
}
