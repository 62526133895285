import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ProductsRoutingModule } from '../products/products-routing.module';
import { HomeAboutUsComponent } from './components/home-about-us/home-about-us.component';
import { HomeContactsComponent } from './components/home-contacts/home-contacts.component';
import { HomeFaqComponent } from './components/home-faq/home-faq.component';
import { HomeHomePageComponent } from './components/home-home-page/home-home-page.component';
import { HomeInfoComponent as HomeInfoComponent } from './components/home-info/home-info.component';
import { HomeInnovationComponent } from './components/home-innovation/home-innovation.component';
import { HomePageNotFoundComponent } from './components/home-page-not-found/home-page-not-found.component';
import { HomePrivacyComponent } from './components/home-privacy/home-privacy.component';
import { HomeTermsComponent } from './components/home-terms/home-terms.component';
import { HomeStateAidComponent } from './components/home-state-aid/home-state-aid.component';
import { HomeCookiePolicyComponent } from './components/home-cookie-policy/home-cookie-policy.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeHomePageComponent,
      },
      {
        path: 'info',
        component: HomeInfoComponent,
      },
      {
        path: 'about-us',
        component: HomeAboutUsComponent,
      },
      {
        path: 'innovation',
        component: HomeInnovationComponent,
      },
      {
        path: 'contacts',
        component: HomeContactsComponent,
      },
      {
        path: 'faq',
        component: HomeFaqComponent,
      },
      {
        path: 'privacy',
        component: HomePrivacyComponent,
      },
      {
        path: 'terms',
        component: HomeTermsComponent,
      },
      {
        path: 'cookie-policy',
        component: HomeCookiePolicyComponent,
      },
      {
        path: 'state-aid',
        component: HomeStateAidComponent,
      },
      {
        path: 'page-not-found',
        component: HomePageNotFoundComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
