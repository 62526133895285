<div
  class="content flexible-column-centered"
  [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'"
  *transloco="let t"
>
  <h1
    class="title"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
  >
    {{ t("home.contacts.section_a.title") }}
  </h1>
  <div *ngIf="items != undefined" class="items-container" [ngClass]="{ flexible: !isMobile }">
    <app-home-contacts-section-a-item
      class="item-flex-properties"
      *ngFor="let item of items"
      [item]="item"
    >
    </app-home-contacts-section-a-item>
  </div>
</div>
