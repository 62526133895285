<div class="flexible">
    <div *ngFor="let item of path; let first = first; let last = last">
        <span *ngIf="!first" class="text"
            [ngClass]="{'text-7': !isMobile, 'text-7-small': isSmall, 'text-7-xsmall': isXSmall}">&nbsp;</span>

        <a routerLink="{{ item.route }}"
            class="text"
            [ngClass]="{'text-last': last, 'text-7': !isMobile, 'text-7-small': isSmall,
            'text-7-xsmall': isXSmall}">{{
            item.title }}
        </a>
        <span *ngIf="!last" class="text"
            [ngClass]="{'text-7': !isMobile, 'text-7-small': isSmall, 'text-7-xsmall': isXSmall}">&nbsp;/</span>
    </div>
</div>