import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupMenuCartComponent } from 'src/app/shared/modules/popup-menu/components/popup-menu-cart/popup-menu-cart.component';
import { PopupMenuFavoritesComponent } from 'src/app/shared/modules/popup-menu/components/popup-menu-favorites/popup-menu-favorites.component';
import { PopupMenuProfileComponent } from 'src/app/shared/modules/popup-menu/components/popup-menu-profile/popup-menu-profile.component';

@Component({
  selector: 'app-core-header-section-b',
  templateUrl: './core-header-section-b.component.html',
  styleUrls: ['./core-header-section-b.component.scss'],
})
export class CoreHeaderSectionBComponent
  extends BaseComponent
  implements OnInit
{
  private dialogRef: any;
  private dialogSubscription: any;
  private closePopupTimer: NodeJS.Timeout;

  iconFocused: boolean[] = [false, false, false];

  private readonly dialogProperties = {
    disableClose: true,
    autoFocus: false,
    hasBackdrop: false,
    position: {
      top: '5em',
      right: '4em',
    },
    data: {},
  };

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialog: MatDialog
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {}

  private clearDialogData() {
    this.iconFocused = [false, false, false];
    clearTimeout(this.closePopupTimer);
    this.dialogSubscription?.unsubscribe();
    this.dialog.closeAll();
  }

  showFavoritesPopup() {
    if (!this.isMobile) {
      this.clearDialogData();
      this.iconFocused[0] = true;
      this.dialogRef = this.dialog.open(
        PopupMenuFavoritesComponent,
        this.dialogProperties
      );
      this.dialogSubscription =
        this.dialogRef.componentInstance.isFocused.subscribe(
          (isFocused: boolean) => {
            if (isFocused) {
              clearTimeout(this.closePopupTimer);
            } else {
              this.startTimeout();
            }
          }
        );
    }
  }

  showProfilePopup() {
    if (!this.isMobile) {
      this.clearDialogData();
      this.iconFocused[1] = true;
      this.dialogRef = this.dialog.open(
        PopupMenuProfileComponent,
        this.dialogProperties
      );
      this.dialogSubscription =
        this.dialogRef.componentInstance.isFocused.subscribe(
          (isFocused: boolean) => {
            if (isFocused) {
              clearTimeout(this.closePopupTimer);
            } else {
              this.startTimeout();
            }
          }
        );
    }
  }

  showCartPopup() {
    if (!this.isMobile) {
      this.clearDialogData();
      this.iconFocused[2] = true;
      this.dialogRef = this.dialogRef = this.dialog.open(
        PopupMenuCartComponent,
        this.dialogProperties
      );
      this.dialogSubscription =
        this.dialogRef.componentInstance.isFocused.subscribe(
          (isFocused: boolean) => {
            if (isFocused) {
              clearTimeout(this.closePopupTimer);
            } else {
              this.startTimeout();
            }
          }
        );
    }
  }

  startTimeout() {
    this.closePopupTimer = setTimeout(() => {
      this.clearDialogData();
    }, 250);
  }
}
