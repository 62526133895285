import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { HomeAboutUsSectionBItem } from './components/home-about-us-section-b-item/home-about-us-section-b-item.component';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-about-us-section-b',
  templateUrl: './home-about-us-section-b.component.html',
  styleUrls: ['./home-about-us-section-b.component.scss']
})
export class HomeAboutUsSectionBComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  items: HomeAboutUsSectionBItem[];

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'home.about_us.section_b.items'
    ).subscribe(result => {
      this.items = result;
    });
  }
}
