<div
  *transloco="let t"
  [ngClass]="{
    'flexible-row-centered': !isMobile,
    'flexible-column-centered': isMobile
  }"
>
  <div
    [ngClass]="{
      'content-description': !isMobile,
      'content-description-mobile': isMobile
    }"
  >
    <h3
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
      class="text-align-left title"
    >
      {{ t("cookie.banner.title") }}
    </h3>

    <p
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
      class="text-align-left description"
    >
      {{ t("cookie.banner.description") }}
    </p>
  </div>

  <div class="fill-width flexible-column-centered">
    <button
      class="button"
      [ngClass]="{
        'button-2': !isMobile,
        'button-2-small': isSmall,
        'button-2-xsmall': isXSmall
      }"
      mat-stroked-button
      (click)="acceptCookie()"
    >
      {{ t("cookie.banner.button_accept") }}
    </button>

    <button
      class="button"
      [ngClass]="{
        'button-3': !isMobile,
        'button-3-small': isSmall,
        'button-3-xsmall': isXSmall
      }"
      mat-stroked-button
      (click)="launchPopup()"
    >
      {{ t("cookie.banner.button_options") }}
    </button>
  </div>
</div>
