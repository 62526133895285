import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { GiftIdeaCategory } from '../../products-home-gift-idea-section.component';

@Component({
  selector: 'app-products-home-gifts-idea-section-cat-item',
  templateUrl: './products-home-gifts-idea-section-cat-item.component.html',
  styleUrls: ['./products-home-gifts-idea-section-cat-item.component.scss']
})
export class ProductsHomeGiftsIdeaSectionCatItemComponent extends BaseComponent implements OnInit {
  
  @Input()
  imageFirst: boolean;
  @Input()
  category: GiftIdeaCategory;

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

}
