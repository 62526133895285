<ng-container *ngIf="!isMobile; else mobile">
    <div class="content flexible">
        <ng-container *ngIf="items != undefined">
            <app-core-footer-bottom-item *ngFor="let item of items; let isLast = last;"
                [footerBottomItem]="item" class="item"
                [ngClass]="{'item-last': isLast}">
            </app-core-footer-bottom-item>
        </ng-container>
    </div>
</ng-container>

<ng-template #mobile>
    <div class="content flexible-column-centered">
        <ng-container *ngIf="items != undefined">
            <app-core-footer-bottom-item *ngFor="let item of items" [footerBottomItem]="item"
                class="item-mobile">
            </app-core-footer-bottom-item>
        </ng-container>
    </div>
</ng-template>

