import { Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { PopupCharmIncomingComponent } from 'src/app/shared/modules/popup-charm-incoming/components/popup-charm-incoming/popup-charm-incoming.component';

@Injectable({
  providedIn: 'root',
})
export class ProductsGuard implements CanActivate {
  isMobile: boolean = false;
  isXSmall: boolean = false;
  isSmall: boolean = false;

  constructor(public responsive: ResponsiveService, private dialog: MatDialog) {
    this.responsive.observeResponsive().subscribe((result) => {
      this.isMobile = result.matches;

      if (result.breakpoints[Breakpoints.Small]) {
        this.isXSmall = false;
        this.isSmall = true;
      } else if (result.breakpoints[Breakpoints.XSmall]) {
        this.isXSmall = true;
        this.isSmall = false;
      } else {
        this.isXSmall = false;
        this.isSmall = false;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let allow = route.url[route.url.length - 1].path != '1';

    if (!allow) {
      this.dialog.open(PopupCharmIncomingComponent, {
        autoFocus: false,
        hasBackdrop: true,
        width: !this.isMobile ? '35em' : '80%',
        minWidth: !this.isMobile ? '45em' : '80%',
      });
    }

    return allow;
  }
}
