import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { CookiePopupComponent } from './components/cookie-popup/cookie-popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { CookiePopupItemComponent } from './components/cookie-popup/components/cookie-popup-item/cookie-popup-item.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [CookieBannerComponent, CookiePopupComponent, CookiePopupItemComponent],
  exports: [CookieBannerComponent, CookiePopupComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
})
export class CookieModule {}
