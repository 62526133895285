<div *transloco="let t" [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'">
    <ng-container *ngIf="!isMobile; else mobile">
        <div class="content-desktop flexible flexible-center-content">
            <div class="half-width-container">
                <div class="text-desktop flexible-column-centered">
                    <h2 class="text-3">{{ title }}</h2>
                    <p class="text-5 text-align-left description" innerHTML="{{ description }}"></p>
                </div>
            </div>
            <div class="half-width-container">
                <img class="image-fill-cover image" src="{{ image }}">
            </div>
        </div>
    </ng-container>

    <ng-template #mobile>
        <div class="flexible-column-centered">
            <div [ngClass]="{'image-container-mobile-small': isSmall, 'image-container-mobile-xsmall': isXSmall}">
                <img class="image-fill-cover" src="{{ image }}">
            </div>

            <div class="content-mobile flexible-column-centered">
                <h2 class="text-align-left" [ngClass]="{'text-3-small': isSmall, 'text-3-xsmall': isXSmall}">{{ title }}</h2>
                <p class="description text-align-left"
                    [ngClass]="{'text-5-small': isSmall, 'text-5-xsmall': isXSmall}"
                    innerHTML="{{ description }}"></p>
            </div>
        </div>
    </ng-template>
</div>
