<div class="content flexible-column-centered" [ngClass]="{'header-margin': !isMobile, 'header-margin-mobile': isMobile}" *transloco="let t">
    <h1 class="title" [ngClass]="{'text-3': !isMobile, 'text-3-small': isSmall, 'text-3-xsmall': isXSmall}">{{
        t('products.products_favorites.title') }}</h1>

    <div class="content-reduced">
        <ng-container *ngIf="loading; else content">
            <app-circular-progress-indicator></app-circular-progress-indicator>
        </ng-container>

        <ng-template #content>
            <app-error-message *ngIf="error"></app-error-message>
            <app-products-favorites-empty *ngIf="!error && this.favorites.length == 0"></app-products-favorites-empty>
            <app-products-favorites-list *ngIf="!error && this.favorites.length > 0" [favorites]="favorites"></app-products-favorites-list>
        </ng-template>
    </div>
</div>