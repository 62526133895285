import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService {
  private historyUrl: string[] = [];

  private previousUrl?: string = undefined;
  private currentUrl?: string = undefined;

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        this.historyUrl.push(this.currentUrl);
      };
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  } 
  
  public getCurrentUrl(){
    return this.currentUrl;
  }

  public getHistoryUrl(): string[] {
    return this.historyUrl;
  }

  public getFirstExceptUrl(exception: string, defaultUrl: string): string {
    for (let index = this.historyUrl.length - 1; index >= 0; index--) {
      const element = this.historyUrl[index];
      
      if(!element.includes(exception)) return element;
    }

    return defaultUrl;
  }
}
