import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface FooterBottomItem {
  title: string;
  image: string;
  items: {
    title: string;
    route: string;
  }[];
}

@Component({
  selector: 'app-core-footer-bottom-item',
  templateUrl: './core-footer-bottom-item.component.html',
  styleUrls: ['./core-footer-bottom-item.component.scss'],
})
export class CoreFooterBottomItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  footerBottomItem: FooterBottomItem;

  ngOnInit(): void {
    
  }
}
