import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import {
  LocalStorageService,
  UserPreferences,
} from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { PopupRequestMailComponent } from '../../components/popup-request-mail/popup-request-mail.component';

@Injectable({
  providedIn: 'root',
})
export class PopupRequestMailService {
  private readonly delayMs: number = 60000;

  isMobile: boolean;

  private dialogRef: any;
  private openPopupTimer: NodeJS.Timeout;

  constructor(
    private authService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private responsive: ResponsiveService,
    private router: Router
  ) {
    this.responsive.observeResponsive().subscribe((result) => {
      this.isMobile = result.matches;
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.resetDialogTimer();
      }
    });
  }

  launchDialog() {
    this.openPopupTimer = setTimeout(() => {
      if (!this.authService.isAuthenticated()) {
        const userPreferences: UserPreferences =
          this.localStorageService.userPreferences.get();

        if (userPreferences.email_requested == false) {
          userPreferences.email_requested = true;
          this.localStorageService.userPreferences.set(userPreferences);

          this.dialogRef = this.dialog.open(PopupRequestMailComponent, {
            //https://stackoverflow.com/a/45933595
            panelClass: 'custom-dialog-container',
            disableClose: true,
            autoFocus: true,
            width: !this.isMobile ? '25em' : '80%',
          });
        }
      }
    }, this.delayMs);
  }

  resetDialogTimer() {
    clearTimeout(this.openPopupTimer);
    this.launchDialog();
  }

  cancelDialogTimer() {
    clearTimeout(this.openPopupTimer);
  }
}
