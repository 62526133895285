import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { ProductsNavigationPathItem } from '../../shared/components/products-navigation-path/products-navigation-path.component';
import { Product, UserService } from 'src/app/openapi/api';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, lastValueFrom, retry, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent extends BaseComponent implements OnInit {
  title: string;
  description: string;
  image: string;
  path: ProductsNavigationPathItem[];

  loaded = false;
  error = false;
  products: Product[];
  favorites: Product[];

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private userApi: UserService,
    private favoritesService: FavoritesService
  ) {
    super(responsive, transloco);

    //https://stackoverflow.com/a/41678403
    route.params.subscribe(async (params) => {
      try {
        let id = this.getId(params);
        this.loadTranslations(id);

        this.products = await this.getProducts(id);
        this.favorites = await this.favoritesService.getFavorites();

        this.loaded = true;
      } catch (error) {
        if (error instanceof Error && error.message == 'invalid-id') {
          this.navigatePageNotFound();
        }
      }
    });
  }

  ngOnInit(): void {}

  private getId(params: any): number {
    if (params.id != undefined) {
      let id = +params.id! - 1;

      if (id < 3) {
        return id;
      }
    }

    throw new Error('invalid-id');
  }

  private loadTranslations(id: number) {
    this.transloco
      .selectTranslateObject('products.products_list.items')
      .subscribe((result) => {
        this.title = result[id].title;
        this.description = result[id].description;
        this.image = result[id].image;
        this.path = result[id].path;
      });
  }

  navigatePageNotFound() {
    this.router.navigate(['/home/page-not-found']);
  }

  private async getProducts(id: number): Promise<Product[]> {
    await this.authService.updateApiCredentials();
    let products = await lastValueFrom(
      this.userApi.getUserProducts().pipe(retry(3))
    );

    let category = [
      Product.CategoryEnum.Charm,
      Product.CategoryEnum.Bracelet,
      Product.CategoryEnum.Necklace,
    ][id];

    products = products.filter((e) => e.category == category);
    products.forEach((p) => p.multimedia?.sort((a, b) => a.id! - b.id!));


    const metalOrder = {
      [Product.MetalEnum.Gold]: 0,
      [Product.MetalEnum.WhiteGold]: 1,
      [Product.MetalEnum.Silver]: 2
    };

    products.sort((a, b) => {
      const metalA = a.metal!;
      const metalB = b.metal!;
    
      return metalOrder[metalA] - metalOrder[metalB];
    });

    products = products.reduce((accumulator: Product[], product) => {
      const existingProduct = accumulator.find(
        (p) =>
          p.metal === product.metal &&
          p.gemstone === product.gemstone &&
          p.category === product.category
      );
    
      if (!existingProduct) {
        accumulator.push(product);
      } else {
        // Compare packaging and measure
        const isExistingProductStandardPackaging =
          existingProduct.packaging === Product.PackagingEnum.Standard;
        const isExistingProductStandardMeasure =
          existingProduct.measure === Product.MeasureEnum.Standard;
        const isProductStandardPackaging =
          product.packaging === Product.PackagingEnum.Standard;
        const isProductStandardMeasure =
          product.measure === Product.MeasureEnum.Standard;
    
        if (
          (isProductStandardPackaging && isProductStandardMeasure) ||
          (!isExistingProductStandardPackaging && isExistingProductStandardMeasure)
        ) {
          // Replace existing product with the new product
          const existingProductIndex = accumulator.indexOf(existingProduct);
          accumulator.splice(existingProductIndex, 1, product);
        }
      }
    
      return accumulator;
    }, []);

    return products;
  }
}
