<div *transloco="let t" class="content flexible-column-centered">
  <ng-container *ngIf="items != undefined">
    <div [ngClass]="!isMobile ? 'items-container' : 'items-container-mobile'">
      <div *ngFor="let item of items; let last = last">
        <mat-expansion-panel
          class="mat-elevation-z0 remove-mat-expansion-panel-padding item"
        >
          <mat-expansion-panel-header
            collapsedHeight="*"
            expandedHeight="*"
          >
            <mat-panel-title class="material-remove-font-size">
              <p
                class="item-title text-align-left"
                [ngClass]="{
                  'text-5': !isMobile,
                  'text-5-small': isSmall,
                  'text-5-xsmall': isXSmall
                }"
              >
                {{ item.title }}
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="text-align-left"
            [innerHTML]="item.description"
            [ngClass]="{
              'text-5': !isMobile,
              'text-5-small': isSmall,
              'text-5-xsmall': isXSmall
            }"
          >
          </p>
        </mat-expansion-panel>
        <div *ngIf="!last || viewSeparatorAtLastRow" class="separator"></div>
      </div>
    </div>
  </ng-container>
</div>
