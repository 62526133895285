import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupCharmIncomingComponent } from './components/popup-charm-incoming/popup-charm-incoming.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { CircularProgressIndicatorModule } from '../circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { FormInputFieldModule } from '../form-input-field/form-input-field.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    PopupCharmIncomingComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormInputFieldModule,
    CircularProgressIndicatorModule,
    ErrorMessageModule,
  ],
  exports: [
    PopupCharmIncomingComponent,
  ],
})
export class PopupCharmIncomingModule { }
