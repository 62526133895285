import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { FooterTopItem } from './components/core-footer-top-item/core-footer-top-item.component';

@Component({
  selector: 'app-core-footer-top',
  templateUrl: './core-footer-top.component.html',
  styleUrls: ['./core-footer-top.component.scss']
})
export class CoreFooterTopComponent extends BaseComponent implements OnInit {
  items: FooterTopItem[];
  
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'core.footer.top_items'
    ).subscribe(result => {
      this.items = result;
    });
  }

}
