<div class="content">
  <!-- Main content -->
  <div class="flexible-row-centered main-content" (click)="onClickMainItem()">
    <span
      class="title text-align-left"
      [ngClass]="{ 'text-4-small': isSmall, 'text-4-xsmall': isXSmall }"
      >{{ item.title }}</span
    >
    <div class="icon-container">
      <mat-icon class="icon" svgIcon="icon_arrow"></mat-icon>
    </div>
  </div>

  <!-- Children -->
  <ng-container *ngIf="item.items != undefined">
    <div
      *ngFor="let child of item.items; let last = last"
      [ngClass]="{ 'margin-child': last }"
      (click)="onClickChildItem(child)"
      class="flexible-row-centered child-content"
    >
      <span
        class="title text-align-left"
        [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
        >{{ child.title }}</span
      >
      <div class="icon-container">
        <mat-icon class="icon" svgIcon="icon_arrow"></mat-icon>
      </div>
    </div>
  </ng-container>
</div>

<div class="separator"></div>
