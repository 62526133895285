<div class="flexible-column-centered content" *transloco="let t">
    <div class="icon-close-container" (click)="onClickCloseDialog()">
      <mat-icon class="icon-close" svgIcon="icon_x"></mat-icon>
    </div>
      <h2
        mat-dialog-title
        class="title"
        [ngClass]="{
          'text-3': !isMobile,
          'text-3-small': isSmall,
          'text-3-xsmall': isXSmall
        }"
      >
        {{ t("products.products_detail.section_a.packaging.popup.title") }}
      </h2>
    
      <p
        class="description"
        [ngClass]="{
          'text-5': !isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
      >
      {{ t("products.products_detail.section_a.packaging.popup.description") }}
      </p>
    </div>
    