import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatDialogRef,
  MatDialogState,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ProductAddedDialogComponent } from 'src/app/modules/products/components/product-added-dialog/product-added-dialog.component';
import { ProfileMenuItem } from 'src/app/modules/profile/shared/components/profile-menu/components/profile-menu-item/profile-menu-item.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-popup-menu-profile',
  templateUrl: './popup-menu-profile.component.html',
  styleUrls: ['./popup-menu-profile.component.scss'],
})
export class PopupMenuProfileComponent extends BaseComponent implements OnInit {
  @Output() isFocused = new EventEmitter<boolean>();
  items: ProfileMenuItem[];

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<ProductAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private authService: AuthenticationService,
    private router: Router
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('profile.menu.items')
      .subscribe((result) => {
        this.items = result;
      });
  }

  mouseEnter() {
    if (this.dialogRef.getState() === MatDialogState.OPEN) {
      this.isFocused.emit(true);
    }
  }

  mouseLeave() {
    this.isFocused.emit(false);
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  onClickSignIn() {
    this.dialogRef.close();
    this.isFocused.emit(false);
    this.router.navigate(['/authentication/sign-in']);
  }

  onClickSignUp() {
    this.dialogRef.close();
    this.isFocused.emit(false);
    this.router.navigate(['/authentication/sign-up']);
  }

  async onClickItem(item: ProfileMenuItem) {
    this.dialogRef.close();
    this.isFocused.emit(false);

    if (item.route != undefined) {
      this.router.navigate([item.route]);
    } else {
      //shoud be logout button
      await this.authService.signOut();
    }
  }
}
