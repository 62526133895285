import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

interface HomeInnovationSectionCItem {
  background: string;
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-home-innovation-section-c',
  templateUrl: './home-innovation-section-c.component.html',
  styleUrls: ['./home-innovation-section-c.component.scss']
})
export class HomeInnovationSectionCComponent extends BaseComponent implements OnInit {
  items: HomeInnovationSectionCItem[];

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'home.rem_innovation.section_c.items'
    ).subscribe(result => {
      this.items = result;
    });
  }

}
