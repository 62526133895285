import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface GiftIdeaCategory {
  title: string,
  description: string,
  image: string,
  image_title: string,
  hover: boolean
}

@Component({
  selector: 'app-products-home-gift-idea-section',
  templateUrl: './products-home-gift-idea-section.component.html',
  styleUrls: ['./products-home-gift-idea-section.component.scss']
})
export class ProductsHomeGiftIdeaSectionComponent extends BaseComponent implements OnInit {
  
  categories: GiftIdeaCategory[];

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'products.products_home.gift_idea.categories'
    ).subscribe(result => {
      this.categories = result;
    });
  }

  setHover(index: number) {
    this.categories[index].hover = true;
  }

  resetHover(index: number) {
    this.categories[index].hover = false;
  }

  scrollToCategory(index: number): void {
    let catTop = document.getElementById("cat-" + index)?.getBoundingClientRect().top;
    let headerHeight = document.getElementsByTagName("header")[0]!.getBoundingClientRect().height;
    if (catTop)
      window.scrollBy({top: catTop - headerHeight, behavior: "smooth"});
  }

}
