import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomeFaqCategory } from '../../../components/home-faq/components/home-faq-section-b/home-faq-section-b.component';
import { MatExpansionPanelItem } from '../home-mat-expansion-panel/home-mat-expansion-panel.component';

export class HomeFaqItem extends MatExpansionPanelItem {
  inPage: string[];
}

@Component({
  selector: 'app-home-faq-reduced',
  templateUrl: './home-faq-reduced.component.html',
  styleUrls: ['./home-faq-reduced.component.scss'],
})
export class HomeFaqReducedComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  @Input()
  page: string;

  items: HomeFaqItem[];

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('home.shared.faq.categories')
      .subscribe((categories: HomeFaqCategory[]) => {
        this.items = [];
        for (let cat of categories) {
          for (let faq of cat.items) {
            if (faq.inPage.includes(this.page)) {
              this.items.push(faq);
            }
          }
        }
      });
  }
}
