import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Product } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-search-list',
  templateUrl: './products-search-list.component.html',
  styleUrls: ['./products-search-list.component.scss']
})
export class ProductsSearchListComponent extends BaseComponent implements OnInit {
  @Input()
  query: string;

  @Input()
  products: Product[]

  @Input()
  favorites: Product[]

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

}
