import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Multimedia, Product } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-popup-menu-product-item',
  templateUrl: './popup-menu-product-item.component.html',
  styleUrls: ['./popup-menu-product-item.component.scss']
})
export class PopupMenuProductItemComponent extends BaseComponent implements OnInit {
  @Output() onProductSelected = new EventEmitter<Product>();
  
  @Input()
  item: Product;

  imageUrl: string;
  metal: string;
  gemstone: string;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    const multimedia: Multimedia[] = this.item.multimedia!;
    this.imageUrl = multimedia[0].url ?? '';

    this.transloco
      .selectTranslateObject('products.product_attributes')
      .subscribe((result) => {
        this.metal = result.metals.find(
          (e: any) => e.key == this.item.metal
        ).value;
        this.gemstone = result.gemstones.find(
          (e: any) => e.key == this.item.gemstone
        ).value;
      });
  }

  productSelected() {
    this.onProductSelected.emit(this.item);
  }
}
