import { Component, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-popup-request-mail-icon-close',
  templateUrl: './popup-request-mail-icon-close.component.html',
  styleUrls: ['./popup-request-mail-icon-close.component.scss'],
})
export class PopupRequestMailIconCloseComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {}
}
