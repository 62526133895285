<div
  [ngClass]="{
    'content header-margin': !isMobile,
    'header-margin-mobile': isMobile,
    'content-small': isSmall,
    'content-xsmall': isXSmall
  }"
  *transloco="let t"
>
  <img class="image-fill-cover" src="assets/images/img_night_sky.jpg" />

  <div
    class="stack-center flexible-column-centered"
    [ngClass]="{
      'container-reduced': !isMobile,
      'container-reduced-mobile': isMobile
    }"
  >
    <!--h1
      class="title animated-fade-in-from-bottom animation-delay-1"
      [ngClass]="{
        'text-2': !isMobile,
        'text-2-small': isSmall,
        'text-2-xsmall': isXSmall
      }"
    >
      {{ t("home.info.title") }}
    </h1-->
    <div class="animated-fade-in-from-bottom animation-delay-1 to-animate">
      <h1
        class="title-1"
        [ngClass]="{
          'text-1': !isMobile,
          'text-1-small': isSmall,
          'text-1-xsmall': isXSmall
        }"
      >
        {{ t("home.home_page.section_e.title_1") }}
      </h1>
      <h2
        class="title-2"
        [ngClass]="{
          'text-2': !isMobile,
          'text-2-small': isSmall,
          'text-2-xsmall': isXSmall
        }"
      >
        {{ t("home.home_page.section_e.title_2") }}
      </h2>
    </div>
    <h2
      class="subtitle animated-fade-in-from-bottom animation-delay-2"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ t("home.info.subtitle") }}
    </h2>
    <p
      class="description animated-fade-in-from-bottom animation-delay-3"
      [ngClass]="{
        'text-5': !isMobile,
        'text-5-small': isSmall,
        'text-5-xsmall': isXSmall
      }"
    >
      {{ t("home.info.description") }}
    </p>
  </div>
</div>
