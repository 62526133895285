import { Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslocoService } from '@ngneat/transloco';
import {
  CookieItem,
  UserPreferences,
} from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { CookieService } from '../../../../services/cookie/cookie.service';
import { CookieOptionItem } from '../../cookie-popup.component';

@Component({
  selector: 'app-cookie-popup-item',
  templateUrl: './cookie-popup-item.component.html',
  styleUrls: ['./cookie-popup-item.component.scss'],
})
export class CookiePopupItemComponent extends BaseComponent implements OnInit {
  @Input()
  item: CookieOptionItem;

  cookieItem: CookieItem;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private cookieService: CookieService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.cookieItem = this.cookieService.getItem(this.item.key);
  }

  toggleChanges($event: MatSlideToggleChange) {
    this.cookieItem.value = $event.checked;
    this.cookieService.setItem(this.cookieItem);
  }
}
