import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ProductAddedDialogComponent } from 'src/app/modules/products/components/product-added-dialog/product-added-dialog.component';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-core-header-search-popup',
  templateUrl: './core-header-search-popup.component.html',
  styleUrls: ['./core-header-search-popup.component.scss']
})
export class CoreHeaderSearchPopupComponent extends BaseComponent implements OnInit {
  controller: FormControl = new FormControl();
  
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<ProductAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private router: Router
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    
  }

  search() {
    const query = this.controller.value;
    this.router.navigate(['/products/products-search'], { queryParams: { query: query } });
    this.dialogRef.close();
  }
}
