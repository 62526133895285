<div class="content">
    <mat-expansion-panel class="mat-elevation-z0 remove-mat-expansion-panel-padding">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="material-remove-font-size">
                <p class="item-title"
                    [ngClass]="{'text-5': !isMobile, 'text-5-small': isSmall, 'text-5-xsmall': isXSmall}">
                    {{ item.title }}
                </p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="text-align-left panel-content"
            [ngClass]="{'text-5': !isMobile, 'text-5-small': isSmall, 'text-5-xsmall': isXSmall}">
            text</p>
    </mat-expansion-panel>
</div>