/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface User { 
    /**
     * 
     */
    id?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    surname?: string;
    /**
     * 
     */
    birthday?: string;
    /**
     * 
     */
    gender?: User.GenderEnum;
    /**
     * 
     */
    language?: User.LanguageEnum;
}
export namespace User {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type LanguageEnum = 'en' | 'it';
    export const LanguageEnum = {
        En: 'en' as LanguageEnum,
        It: 'it' as LanguageEnum
    };
}


