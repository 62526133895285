<div class="content flexible-column-centered">
  <!-- <span
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-3': !isMobile,
      'text-3-small': isSmall,
      'text-3-xsmall': isXSmall
    }"
    >{{ item.title }}</span
  > -->
  <img
    class="image animated-zoom-in animation-delay-2 to-animate"
    src="{{ item.image }}"
  />
  <span
    class="description animated-fade-in-from-bottom animation-delay-2 to-animate"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    innerHTML="{{ item.description }}"
  ></span>
</div>
