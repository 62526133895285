/**
 * REM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UserGuest { 
    /**
     * 
     */
    id?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    language?: UserGuest.LanguageEnum;
}
export namespace UserGuest {
    export type LanguageEnum = 'en' | 'it';
    export const LanguageEnum = {
        En: 'en' as LanguageEnum,
        It: 'it' as LanguageEnum
    };
}


