import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomeAboutUsPartnerItem } from './components/home-about-us-partners-item/home-about-us-partners-item.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-about-us-partners',
  templateUrl: './home-about-us-partners.component.html',
  styleUrls: ['./home-about-us-partners.component.scss'],
})
export class HomeAboutUsPartnersComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router,
  ) {
    super(responsive, transloco);
  }

  items: HomeAboutUsPartnerItem[];

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('home.about_us.partners.items')
      .subscribe((result) => {
        this.items = result;
      });
  }

  onClickItem(item: HomeAboutUsPartnerItem) {
    window.open(item.link, "_blank");
  }
}
