import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreHeaderComponent } from './components/core-header/core-header.component';
import { CoreFooterComponent } from './components/core-footer/core-footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CoreFooterSocialsComponent } from './components/core-footer/components/core-footer-socials/core-footer-socials.component';
import { CoreFooterCopyrightComponent } from './components/core-footer/components/core-footer-copyright/core-footer-copyright.component';
import { CoreHeaderLanguageSelectorComponent } from './components/core-header/components/core-header-language-selector/core-header-language-selector.component';
import { CoreHeaderLogoComponent } from './components/core-header/components/core-header-top/components/core-header-logo/core-header-logo.component';
import { CoreHeaderTopComponent } from './components/core-header/components/core-header-top/core-header-top.component';
import { CoreHeaderSectionAComponent } from './components/core-header/components/core-header-top/components/core-header-section-a/core-header-section-a.component';
import { CoreHeaderSectionBComponent } from './components/core-header/components/core-header-top/components/core-header-section-b/core-header-section-b.component';
import { CoreHeaderBottomComponent } from './components/core-header/components/core-header-bottom/core-header-bottom.component';
import { CoreFooterTopComponent } from './components/core-footer/components/core-footer-top/core-footer-top.component';
import { CoreFooterBottomComponent } from './components/core-footer/components/core-footer-bottom/core-footer-bottom.component';
import { CoreFooterTopItemComponent } from './components/core-footer/components/core-footer-top/components/core-footer-top-item/core-footer-top-item.component';
import { CoreFooterBottomItemComponent } from './components/core-footer/components/core-footer-bottom/components/core-footer-bottom-item/core-footer-bottom-item.component';
import { CoreHeaderMobileMenuComponent } from './components/core-header/components/core-header-mobile-menu/core-header-mobile-menu.component';
import { CoreHeaderMobileMenuItemComponent } from './components/core-header/components/core-header-mobile-menu/components/core-header-mobile-menu-item/core-header-mobile-menu-item.component';
import { CoreHeaderContactUsComponent } from './components/core-header/components/core-header-contact-us/core-header-contact-us.component';
import { CoreHeaderSignInComponent } from './components/core-header/components/core-header-sign-in/core-header-sign-in.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CircularProgressIndicatorModule } from 'src/app/shared/modules/circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from 'src/app/shared/modules/error-message/error-message.module';
import { PopupMenuModule } from 'src/app/shared/modules/popup-menu/popup-menu.module';
import { CoreHeaderSearchPopupComponent } from './components/core-header/components/core-header-search-popup/core-header-search-popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreHeaderMobileMenuSearchComponent } from './components/core-header/components/core-header-mobile-menu/components/core-header-mobile-menu-search/core-header-mobile-menu-search.component';

const sharedModules = [CircularProgressIndicatorModule, ErrorMessageModule, PopupMenuModule];

const materialModules = [MatButtonModule, MatIconModule, MatExpansionModule];

@NgModule({
  declarations: [
    CoreHeaderComponent,
    CoreFooterComponent,
    CoreFooterTopItemComponent,
    CoreFooterBottomItemComponent,
    CoreFooterSocialsComponent,
    CoreFooterCopyrightComponent,
    CoreHeaderSectionAComponent,
    CoreHeaderLanguageSelectorComponent,
    CoreHeaderSectionBComponent,
    CoreHeaderLogoComponent,
    CoreHeaderTopComponent,
    CoreHeaderBottomComponent,
    CoreFooterTopComponent,
    CoreFooterBottomComponent,
    CoreHeaderMobileMenuComponent,
    CoreHeaderMobileMenuItemComponent,
    CoreHeaderContactUsComponent,
    CoreHeaderSignInComponent,
    CoreHeaderSearchPopupComponent,
    CoreHeaderMobileMenuSearchComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatDialogModule,
    ReactiveFormsModule,
    ...sharedModules,
    ...materialModules,
  ],
  exports: [CoreHeaderComponent, CoreFooterComponent],
})
export class CoreModule {}
