import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface MenuItem {
  name: string;
  route: string;
}

@Component({
  selector: 'app-core-header',
  templateUrl: './core-header.component.html',
  styleUrls: ['./core-header.component.scss'],
})
export class CoreHeaderComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  showMobileMenu: boolean = false;

  ngOnInit(): void {

  }

  closeMobileMenu() {
    this.showMobileMenu = false;
  }

  openMobileMenu() {
    this.showMobileMenu = true;
  }
}
