import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomePageSectionEItem } from './components/home-home-page-section-e-item/home-home-page-section-e-item.component';

@Component({
  selector: 'app-home-home-page-section-e',
  templateUrl: './home-home-page-section-e.component.html',
  styleUrls: ['./home-home-page-section-e.component.scss']
})
export class HomeHomePageSectionEComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  items: HomePageSectionEItem[];

  ngOnInit(): void {
    this.transloco.selectTranslateObject(
      'home.home_page.section_e.items'
    ).subscribe(result => {
      this.items = result;
    });
  }
}
