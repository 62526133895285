import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Multimedia } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

import SwiperCore, { Pagination } from "swiper";

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-products-detail-section-a-images',
  templateUrl: './products-detail-section-a-images.component.html',
  styleUrls: ['./products-detail-section-a-images.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsDetailSectionAImagesComponent extends BaseComponent implements OnInit {
  @Input()
  multimedia: Multimedia[];

  selectedIndex: number = 0;

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

  selectItem(item: Multimedia) {
    this.selectedIndex = this.multimedia.indexOf(item);
  }
}
