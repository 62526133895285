import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { retry } from 'rxjs';
import { Product, UserService } from 'src/app/openapi/api';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-detail-section-c',
  templateUrl: './products-detail-section-c.component.html',
  styleUrls: ['./products-detail-section-c.component.scss'],
})
export class ProductsDetailSectionCComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  product: Product | undefined;

  loaded: boolean = false;
  products: Product[];
  favorites: Product[];

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private userApi: UserService,
    private favoritesService: FavoritesService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.retrieveProducts();
  }

  private async retrieveProducts() {
    this.userApi
      .getUserProducts()
      .pipe(retry(3))
      .subscribe({
        next: async (products) => {
          if (this.product == undefined) {
            if (products.length > 0) {
              this.product = products[0];
            }
          }

          if (this.product) {
            //same category, same gemstone
            let first = products.filter(
              (e) =>
                e.category == this.product!.category &&
                e.gemstone == this.product!.gemstone
            );

            //if current product has metal gold, retrieve white gold
            if (this.product.metal == Product.MetalEnum.Gold) {
              first = first.filter(
                (e) => e.metal == Product.MetalEnum.WhiteGold
              );
            } else {
              first = first.filter((e) => e.metal == Product.MetalEnum.Gold);
            }

            //same category, gold, different gemstone
            let second = products.filter(
              (e) =>
                e.category == this.product!.category &&
                e.metal == Product.MetalEnum.Gold &&
                e.gemstone != this.product?.gemstone
            );

            //different category, gold, same gemstone
            let third = products.filter(
              (e) =>
                e.category != this.product!.category &&
                e.category != Product.CategoryEnum.Charm &&
                e.gemstone == this.product!.gemstone &&
                e.metal == Product.MetalEnum.Gold
            );

            //different category, gold, different gemstone
            let fourth = products.filter(
              (e) =>
                e.category != this.product!.category &&
                e.category != Product.CategoryEnum.Charm &&
                e.metal == Product.MetalEnum.Gold &&
                e.gemstone != this.product?.gemstone
            );

            // Add the check for equal products based on measure or packaging
            const isEqual = (a: Product, b: Product) => {
              return a.measure == b.measure || a.packaging == b.packaging;
            };

            // Filter out products that differ only by measure or packaging
            const filterEqualProducts = (arr: Product[]) => {
              const result: Product[] = [];
              for (let i = 0; i < arr.length; i++) {
                let isDuplicate = false;
                for (let j = 0; j < result.length; j++) {
                  if (isEqual(arr[i], result[j])) {
                    isDuplicate = true;
                    break;
                  }
                }
                if (!isDuplicate) {
                  result.push(arr[i]);
                }
              }
              return result;
            };

            first = filterEqualProducts(first);
            second = filterEqualProducts(second);
            third = filterEqualProducts(third);
            fourth = filterEqualProducts(fourth);

            this.products = [...first, ...second, ...third, ...fourth];
          }

          this.products = this.products.slice(0, 4);
          this.products.forEach((p) =>
            p.multimedia?.sort((a, b) => a.id! - b.id!)
          );
          await this.getFavorites();
        },
        error: (e) => {},
        complete: () => {
          this.loaded = true;
        },
      });
  }

  private async getFavorites(): Promise<void> {
    try {
      this.favorites = await this.favoritesService.getFavorites();
    } catch (error) {}
  }
}
