import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CircularProgressIndicatorModule } from '../circular-progress-indicator/circular-progress-indicator.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { FormInputFieldModule } from '../form-input-field/form-input-field.module';
import { PopupRequestMailComponent } from './components/popup-request-mail/popup-request-mail.component';
import { PopupRequestMailDescriptionComponent } from './components/popup-request-mail/components/popup-request-mail-description/popup-request-mail-description.component';
import { PopupRequestMailIconCloseComponent } from './components/popup-request-mail/components/popup-request-mail-icon-close/popup-request-mail-icon-close.component';

@NgModule({
  declarations: [
    PopupRequestMailComponent,
    PopupRequestMailDescriptionComponent,
    PopupRequestMailIconCloseComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormInputFieldModule,
    CircularProgressIndicatorModule,
    ErrorMessageModule,
  ],
  exports: [PopupRequestMailComponent],
})
export class PopupRequestMailModule {}
