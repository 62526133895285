import { Component, OnInit } from '@angular/core';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from './openapi/api';
import { AuthenticationService } from './services/authentication/authentication.service';
import {
  CookieItem,
  LocalStorageService,
  UserPreferences,
} from './services/local-storage/local-storage.service';
import { ResponsiveService } from './services/responsive/responsive.service';
import { RouterHistoryService } from './services/router-history/router-history.service';
import { SvgIconsService } from './services/svg-icons/svg-icons.service';
import { BaseComponent } from './shared/components/base-component';
import { CookieService } from './shared/modules/cookie/services/cookie/cookie.service';
import { PopupRequestMailService } from './shared/modules/popup-request-mail/services/popup-request-mail/popup-request-mail.service';
import { filter, lastValueFrom, retry } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private svgIconsService: SvgIconsService, //needed to initialize icons
    private routerHistoryService: RouterHistoryService, //nedded to initialize history
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private popupRequestMailService: PopupRequestMailService,
    private firebaseAuth: AngularFireAuth,
    private authService: AuthenticationService,
    private userApi: UserService,
    private router: Router
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.firebaseAuth.authState.subscribe(async (user) => {
      if (user != undefined) {
        // user is authenticated, get language from backend
        await this.initLanguageFromBackend();
      } else {
        // user is not autheticated, get language from local storage
        this.initLanguageFromLocalStorage();
      }

      this.popupRequestMailService.launchDialog();
    });
    this.setUpAnalytics();
  }

  async initLanguageFromBackend() {
    try {
      await this.authService.updateApiCredentials();
      let language = await lastValueFrom(
        this.userApi.getUserProfileLanguage().pipe(retry(3))
      );
      if (!this.transloco.isLang(language)) {
        throw new Error();
      }
      this.setActiveLanguageOnTraslocoAndLocalStorage(language);
    } catch (error) {
      this.initLanguageFromLocalStorage();
    }
  }

  initLanguageFromLocalStorage() {
    let language = 'it';

    if (!this.localStorageService.userPreferences.isSet()) {
      // preferences in local storage are not set
      language = getBrowserLang() ?? 'it';
      if (!this.transloco.isLang(language)) {
        language = 'it';
      }
    } else {
      // preferences are present in local storage
      const userPreferences = this.localStorageService.userPreferences.get();
      language = userPreferences.language;
    }

    this.setActiveLanguageOnTraslocoAndLocalStorage('it');
  }

  setActiveLanguageOnTraslocoAndLocalStorage(language: string) {
    this.transloco.setActiveLang(language);
    const userPreferences = this.localStorageService.userPreferences.get();
    userPreferences.language = language;
    this.localStorageService.userPreferences.set(userPreferences);
  }

  setUpAnalytics() {
    gtag('config', environment.analyticsId, { send_page_view: false });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (this.canRunAnalytics()) {          
          gtag('event', 'page_view', {
            page_path: (event as NavigationEnd).urlAfterRedirects,
          });
        }
      });
  }

  canRunAnalytics(): boolean {
    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();

    if (!userPreferences.cookie.accepted) return false;

    const cookieItem: CookieItem | undefined =
      userPreferences.cookie.items.find((e) => e.key == '2');

    if (cookieItem == undefined) return false;

    return cookieItem.value;
  }
}
