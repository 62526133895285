import { Injectable } from '@angular/core';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  constructor(private responsive: BreakpointObserver) { }

  observeResponsive(): Observable<BreakpointState> {
    return this.responsive.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }
}
