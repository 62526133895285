<div *transloco="let t" class="content">
    <div class="separator"></div>
    <ng-container *ngIf="!isMobile; else mobile">
        <div class="content-desktop flexible">
            <div class="half-width-container">
                <app-product-detail-section-b-description [product]="product"></app-product-detail-section-b-description>
            </div>
            <div class="half-width-container">
                <app-product-detail-section-b-info></app-product-detail-section-b-info>
            </div>
        </div>
    </ng-container>

    <ng-template #mobile>
        <app-product-detail-section-b-description [product]="product"></app-product-detail-section-b-description>
        <app-product-detail-section-b-info></app-product-detail-section-b-info>
    </ng-template>
    <div class="separator"></div>
</div>