<footer class="footer">
    <!-- Top -->
    <app-core-footer-top></app-core-footer-top>
    
    <!-- Bottom -->
    <app-core-footer-bottom></app-core-footer-bottom>

    <!-- Socials -->
    <app-core-footer-socials></app-core-footer-socials>

    <!-- Copyright -->
    <app-core-footer-copyright></app-core-footer-copyright>
</footer>