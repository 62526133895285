import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { HomeFaqItem } from '../../../../shared/components/home-faq-reduced/home-faq-reduced.component';

export interface HomeFaqCategory {
  title: string;
  icon: string;
  expanded: boolean;
  hover: boolean;
  items: HomeFaqItem[];
}

@Component({
  selector: 'app-home-faq-section-b',
  templateUrl: './home-faq-section-b.component.html',
  styleUrls: ['./home-faq-section-b.component.scss'],
})
export class HomeFaqSectionBComponent extends BaseComponent implements OnInit {
  categories: HomeFaqCategory[];

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.transloco
      .selectTranslateObject('home.shared.faq.categories')
      .subscribe((result) => {
        this.categories = result;
        for (let cat of this.categories) cat.expanded = false;
      });
  }

  setHover(index: number) {
    this.categories[index].hover = true;
  }

  resetHover(index: number) {
    this.categories[index].hover = false;
  }

  openCategoryAndScroll(index: number) {
    this.categories[index].expanded = true;
    let catTop = document
      .getElementById('cat-' + index)
      ?.getBoundingClientRect().top;
    let headerHeight = document
      .getElementsByTagName('header')[0]!
      .getBoundingClientRect().height;
    if (catTop) {
      window.scrollBy({ top: catTop - headerHeight, behavior: 'smooth' });
    }

    setTimeout(() => {
      this.resetHover(index);
    }, 150);
  }
}
