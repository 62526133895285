import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ProductAddedDialogComponent } from 'src/app/modules/products/components/product-added-dialog/product-added-dialog.component';
import { CartItem, Product } from 'src/app/openapi/api';
import { CartService } from 'src/app/services/cart/cart.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-popup-menu-cart',
  templateUrl: './popup-menu-cart.component.html',
  styleUrls: ['./popup-menu-cart.component.scss']
})
export class PopupMenuCartComponent extends BaseComponent implements OnInit {
  @Output() isFocused = new EventEmitter<boolean>();

  cart: CartItem[];
  loading: boolean;
  error: boolean;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private dialogRef: MatDialogRef<ProductAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private cartService: CartService,
    private router: Router,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.getCart();
  }

  mouseEnter() {
    if (this.dialogRef.getState() === MatDialogState.OPEN) {
      this.isFocused.emit(true);
    }
  }

  mouseLeave() {
    this.isFocused.emit(false);
  }

  private async getCart(): Promise<void> {
    this.loading = true;

    try {
      this.cart = await this.cartService.getCart();

      this.cart.forEach(c => c.product?.multimedia?.sort((a, b) => a.id! - b.id!));
    } catch (error) {
      error = true;
    }

    this.loading = false;
  }

  onClickButton() {
    this.router.navigate(['/cart']);
    this.dialogRef.close();
    this.isFocused.emit(false);
  }

  getTotalPrice(): string {
    let total = 0;
    this.cart.forEach(e => {total += +e.product!.price!});

    return '€ ' + total;
  }

  productSelected(product: Product) {
    this.router.navigate(['/products/product-detail/', product.id]);
    this.dialogRef.close();
    this.isFocused.emit(false);
  }

  onClickButtonEmpty() {
    this.router.navigate(['/products']);
    this.dialogRef.close();
    this.isFocused.emit(false);
  }
}
