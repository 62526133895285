<div class="flexible-row-centered" *transloco="let t">
  <a routerLink="/home/contacts" class="contacts-link">
    <div class="flexible">
      <div class="icon-container" [ngClass]="{ 'icon-container-mobile': isMobile }">
        <mat-icon class="icon" svgIcon="icon_contacts" [ngClass]="{'color-white': isMobile}"></mat-icon>
      </div>

      <span
        class="contacts-text"
        [ngClass]="{
          'text-7': !isMobile,
          'color-white': isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        >{{ t("core.header.contacts") }}</span
      >
    </div>
  </a>
</div>
