<div *transloco="let t" class="content">
  <ng-container *ngIf="!isMobile; else mobile">
    <div class="flexible flexible-center-content">
      <div class="half-width-container">
        <div class="content-desktop flexible-column-centered">
          <h2 class="text-2">{{ t("home.contacts.section_d.title") }}</h2>
          <h3 class="text-5 subtitle">
            {{ t("home.contacts.section_d.subtitle") }}
          </h3>
          <p
            class="text-5 description"
            [innerHTML]="'home.contacts.section_d.description' | transloco"
          ></p>

          <button
            class="button"
            [ngClass]="{
              'button-2': !isMobile,
              'button-2-small': isSmall,
              'button-2-xsmall': isXSmall
            }"
            mat-stroked-button
            [innerHTML]="'home.contacts.section_d.button' | transloco"
          ></button>
        </div>
      </div>
      <div class="half-width-container image-container">
        <img
          class="image-fill-cover image"
          src="assets/images/img_lavoraconnoi.jpg"
        />
      </div>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div class="flexible-column-centered">
      <div
        [ngClass]="{
          'image-container-mobile-small': isSmall,
          'image-container-mobile-xsmall': isXSmall
        }"
      >
        <img
          class="image-fill-cover"
          src="assets/images/img_lavoraconnoi.jpg"
        />
      </div>

      <div class="content-mobile flexible-column-centered">
        <h2 [ngClass]="{ 'text-2-small': isSmall, 'text-2-xsmall': isXSmall }">
          {{ t("home.contacts.section_d.title") }}
        </h2>
        <h3
          class="subtitle"
          [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
        >
          {{ t("home.contacts.section_d.subtitle") }}
        </h3>
        <p
          class="description"
          [ngClass]="{ 'text-5-small': isSmall, 'text-5-xsmall': isXSmall }"
          [innerHTML]="'home.contacts.section_d.description' | transloco"
        ></p>

        <button
            class="button"
            [ngClass]="{
              'button-2': !isMobile,
              'button-2-small': isSmall,
              'button-2-xsmall': isXSmall
            }"
            mat-stroked-button
            [innerHTML]="'home.contacts.section_d.button' | transloco"
          ></button>
      </div>
    </div>
  </ng-template>
</div>
