<ul class="content mat-list-horizontal flexible-row-centered">
  <li *ngFor="let item of menu; let last = last">
    <a *ngIf="!last" routerLink="{{ item.route }}" class="text-6 menu-item">{{
      item.title
    }}</a>

    <a
      (mouseenter)="showHomeInfoPopup()"
      (mouseleave)="startTimeout()"
      (click)="onClickItem(item.route)"
      *ngIf="last"
      class="text-6 menu-item"
      [ngClass]="{ 'menu-item-focused': focused }"
      #anchor_home_info
    >
      {{ item.title }}
    </a>
  </li>

  <li class="search-icon icon-container" (click)="onClickSearch()">
    <mat-icon class="icon" svgIcon="icon_find"></mat-icon>
  </li>
</ul>
