// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://dev.api.rem-montenapoleone.com",
  //apiUrl: 'http://localhost:4201',
  webapp_url: "https://dev.rem-montenapoleone.com/webapp?id=",
  apiKey:
    'Z4QZtezQJw9hstHtVJEyRdSjylhUfGdPrXh4WDAK1KdzZ/v/OoPERprcc8S3YK4H6Uz0N4eG2JohHC4r1MD/Jg==',
  firebase: {
    apiKey: 'AIzaSyAukNwN3MyKlHxtjep1OY1ThUBgDXVQVPM',
    authDomain: 'rem-montenapoleone-development.firebaseapp.com',
    projectId: 'rem-montenapoleone-development',
    storageBucket: 'rem-montenapoleone-development.appspot.com',
    messagingSenderId: '374094773796',
    appId: '1:374094773796:web:58ffd8cde080daa4d662b1',
    measurementId: 'G-2FGRVE65KY',
  },
  analyticsId: 'G-2FGRVE65KY',
  paypalClientId:
    'AWJKfd7vul_8tsHmXPJPpHoend3srfQ3N8fVOzLsyR2iDazKMV2P1wigsuyTH-Nvng2mKg5FwMhHcDmo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
