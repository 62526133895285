<ng-container *transloco="let t">
  <div class="flexible-row-centered">
    <div
      class="icon-container"
      [ngClass]="{ 'icon-container-mobile': isMobile }"
    >
      <mat-icon
        class="icon"
        [ngClass]="{ 'color-white': isMobile }"
        svgIcon="icon_language"
      ></mat-icon>
    </div>

    <ng-container
      *ngFor="let item of languages; let first = first; let last = last"
    >
      <span
        class="text language-item"
        [ngClass]="{
          'language-selected': isActiveLanguage(item),
          'text-7': !isMobile,
          'color-white': isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        (click)="languageSelected(item)"
        >{{ item.value }}</span
      >
      <span
        *ngIf="!last"
        class="text language-separator"
        [ngClass]="{
          'text-7': !isMobile,
          'color-white': isMobile,
          'text-5-small': isSmall,
          'text-5-xsmall': isXSmall
        }"
        >/</span
      >
    </ng-container>
  </div>
</ng-container>
