import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-core-header-top',
  templateUrl: './core-header-top.component.html',
  styleUrls: ['./core-header-top.component.scss']
})
export class CoreHeaderTopComponent extends BaseComponent implements OnInit {
  @Output() onOpenMobileMenu = new EventEmitter<void>();

  openMobileMenu() {
    this.onOpenMobileMenu.emit();
  }
  
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

}
