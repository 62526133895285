<div
  *transloco="let t"
  class="flexible-column"
  [ngClass]="{ content: !isMobile, 'content-mobile': isMobile }"
>
  <div class="container-path">
    <app-products-navigation-path
      *ngIf="path != undefined"
      [path]="path"
    ></app-products-navigation-path>
  </div>

  <div class="flexible">
    <h2
      class="text-align-left title-container"
      [ngClass]="{
        'text-3': !isMobile,
        'text-3-small': isSmall,
        'text-3-xsmall': isXSmall
      }"
    >
      {{ category }}
      <span class="text-4 title">{{ getLocalizedString(product.name) }}</span>
    </h2>

    <div *ngIf="!loading" class="icon-container" (click)="onClickFavorite()">
      <mat-icon
        *ngIf="!isFavorite()"
        class="icon"
        svgIcon="icon_heart"
      ></mat-icon>
      <mat-icon
        *ngIf="isFavorite()"
        class="icon"
        svgIcon="icon_heart_filled"
      ></mat-icon>
    </div>
  </div>

  <p
    class="text-align-left price"
    [ngClass]="{
      'text-4': !isMobile,
      'text-4-small': isSmall,
      'text-4-xsmall': isXSmall
    }"
  >
    {{ product.price }} €
  </p>

  <!-- Metal -->
  <div
    class="text-align-left"
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
  >
    <span>{{ t("products.products_detail.section_a.metal") }}</span>
    <p>
      <mat-chip-list>
        <mat-chip *ngFor="let metal of filters.metal" class="chip"
        (click)="onClickMetal(metal)"
        [ngClass]="{
          'chip-selected': product.metal == metal
        }"
        >
          <img
            [src]="getMetalIcon(metal)"
            alt="{{ metal }}"
            class="chip-icon"
            height="20"
            width="20"
          />
          <span
            class="chip-text"
            [ngClass]="{
              'text-7': !isMobile,
              'text-7-small': isSmall,
              'text-7-xsmall': isXSmall,
              'chip-text-selected': product.metal == metal
            }"
            >
            {{getMetalText(metal)}}</span
          >
        </mat-chip>
      </mat-chip-list>
    </p>
    <div class="separator"></div>
  </div>

  <!-- Gemstone -->
  <div
    class="text-align-left"
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
  >
    <span>{{ t("products.products_detail.section_a.gemstone") }}</span>

    <p>
      <mat-chip-list>
        <mat-chip *ngFor="let gemstone of filters.gemstone" class="chip"
        (click)="onClickGemstone(gemstone)"
        [ngClass]="{
          'chip-selected': product.gemstone == gemstone
        }"
        >
          <img
            [src]="getGemstoneIcon(gemstone)"
            alt="{{ gemstone }}"
            class="chip-icon"
            height="20"
            width="20"
          />
          <span
            class="chip-text"
            [ngClass]="{
              'text-7': !isMobile,
              'text-7-small': isSmall,
              'text-7-xsmall': isXSmall,
              'chip-text-selected': product.gemstone == gemstone
            }"
            >
            {{getGemstoneText(gemstone)}}</span
          >
        </mat-chip>
      </mat-chip-list>
    </p>
    <div class="separator"></div>
  </div>

  <!-- Measure -->
  <div
    class="text-align-left"
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
  >
    <span
      [innerHTML]="'products.products_detail.section_a.measure' | transloco"
    ></span>

    <p>
      <mat-chip-list>
        <mat-chip *ngFor="let measure of filters.measure" class="chip"
        (click)="onClickMeasure(measure)"
        [ngClass]="{
          'chip-selected': product.measure == measure
        }"
        >
          <span
            class="chip-text"
            [ngClass]="{
              'text-7': !isMobile,
              'text-7-small': isSmall,
              'text-7-xsmall': isXSmall,
              'chip-text-selected': product.measure == measure
            }"
            >
            {{getMeasureText(measure)}}</span
          >
        </mat-chip>
      </mat-chip-list>
    </p>
    <div class="separator"></div>
  </div>

  <!-- Packaging -->
  <div
    class="text-align-left"
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
  >
    <span
      [innerHTML]="'products.products_detail.section_a.packaging.title' | transloco"
    ></span>
    <!-- <span class="package-info-title" [ngClass]="{
      'text-7': !isMobile,
      'text-7-small': isSmall,
      'text-7-xsmall': isXSmall
    }" (click)="onClickPackagingInfo()">{{t('products.products_detail.section_a.packaging.info')}}</span> -->
    <p>
      <mat-chip-list>
        <mat-chip *ngFor="let packaging of filters.packaging" class="chip"
        (click)="onClickPackaging(packaging)"
        [ngClass]="{
          'chip-selected': product.packaging == packaging
        }"
        >
          <span
            class="chip-text"
            [ngClass]="{
              'text-7': !isMobile,
              'text-7-small': isSmall,
              'text-7-xsmall': isXSmall,
              'chip-text-selected': product.packaging == packaging
            }"
            >
            {{getPackagingText(packaging)}}</span
          >
        </mat-chip>
      </mat-chip-list>
    </p>
    <div class="separator"></div>
  </div>

  <!-- Contents -->
  <div
    class="text-align-left"
    [ngClass]="{
      'text-6': !isMobile,
      'text-6-small': isSmall,
      'text-6-xsmall': isXSmall
    }"
  >
    <span
      [innerHTML]="'products.products_detail.section_a.description' | transloco"
    ></span
    ><br />
    <span
      [ngClass]="{
        'text-7': !isMobile,
        'text-7-small': isSmall,
        'text-7-xsmall': isXSmall
      }"
      >{{ t("products.products_detail.section_a.description_1") }}</span
    >
    <div class="separator"></div>
  </div>

  <button
    *ngIf="!addedToCart"
    class="button"
    [ngClass]="{
      'button-3': !isMobile,
      'button-3-small': isSmall,
      'button-3-xsmall': isXSmall
    }"
    (click)="onClickAddCart()"
    mat-stroked-button
  >
    {{ t("products.products_detail.section_a.button") }}
  </button>

  <button
    *ngIf="addedToCart"
    class="button"
    [ngClass]="{
      'button-2': !isMobile,
      'button-2-small': isSmall,
      'button-2-xsmall': isXSmall
    }"
    mat-stroked-button
  >
    {{ t("products.products_detail.section_a.button_1") }}
  </button>
</div>
