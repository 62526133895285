<div class="header-margin flexible-column-centered" *transloco="let t">
  <h1
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-2': !isMobile,
      'text-2-small': isSmall,
      'text-2-xsmall': isXSmall
    }"
  >
    {{ t("home.about_us.partners.title") }}
  </h1>

  <div
    *ngIf="items != undefined"
    [ngClass]="{ 'flexible-column-centered content-items': !isMobile }"
    class="animated-fade-in-from-bottom animation-delay-2 to-animate item"
  >
    <app-home-about-us-partners-item
      class="item-flex-properties"
      *ngFor="let item of items"
      (click)="onClickItem(item)"
      [item]="item"
    >
    </app-home-about-us-partners-item>
  </div>
</div>
