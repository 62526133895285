import { Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export class HomeInfoItem {
  image: string;
  title: string;
  description: string;
  button: string;
  route: string;
}

@Component({
  selector: 'app-home-info-item',
  templateUrl: './home-info-item.component.html',
  styleUrls: ['./home-info-item.component.scss']
})
export class HomeInfoItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  @Input()
  item: HomeInfoItem;

  @Input()
  imageFirst: boolean;

  ngOnInit(): void {
    
  }
}
