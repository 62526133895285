<div
  class="content animated-fade-in-from-bottom animation-delay-1"
  [ngClass]="{ 'to-animate': addToAnimateClass }"
  *transloco="let t"
>
  <div
    *ngFor="let item of items"
    [ngClass]="{ item: !isMobile, 'item-mobile': isMobile }"
  >
    <app-product-categories-item [item]="item"></app-product-categories-item>
  </div>
</div>
