import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-core-header-mobile-menu-search',
  templateUrl: './core-header-mobile-menu-search.component.html',
  styleUrls: ['./core-header-mobile-menu-search.component.scss'],
})
export class CoreHeaderMobileMenuSearchComponent
  extends BaseComponent
  implements OnInit
{
  controller: FormControl = new FormControl();
  
  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router
  ) {
    super(responsive, transloco);
  }

  @Output() onCloseMenu = new EventEmitter<void>();

  ngOnInit(): void {}

  search() {
    const query = this.controller.value;
    this.onCloseMenu.emit();
    this.router.navigate(['/products/products-search'], { queryParams: { query: query } });
  }
}