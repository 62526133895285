import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ProductAddedDialogComponent } from 'src/app/modules/products/components/product-added-dialog/product-added-dialog.component';
import { ProductsNavigationPathItem } from 'src/app/modules/products/shared/components/products-navigation-path/products-navigation-path.component';
import { Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import {
  UserPreferences,
  LocalStorageService,
} from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupMenuCartComponent } from 'src/app/shared/modules/popup-menu/components/popup-menu-cart/popup-menu-cart.component';
import { PopupRequestMailService } from 'src/app/shared/modules/popup-request-mail/services/popup-request-mail/popup-request-mail.service';
import { PopupPackageInfoComponent } from './components/popup-package-info/popup-package-info.component';

@Component({
  selector: 'app-products-detail-section-a-content',
  templateUrl: './products-detail-section-a-content.component.html',
  styleUrls: ['./products-detail-section-a-content.component.scss'],
})
export class ProductsDetailSectionAContentComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  product: Product;

  @Input()
  products: Array<Product>;

  @Input()
  filters: {
    metal: Set<string>,
    gemstone: Set<string>,
    packaging: Set<string>,
    measure: Set<string>,
  };

  category: string;
  metal: string;
  gemstone: string;

  loading: boolean;
  favorites: Product[];

  path: ProductsNavigationPathItem[];

  addedToCart: boolean = false;

  productCategories: any;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private authService: AuthenticationService,
    private userApi: UserService,
    private router: Router,
    private favoritesService: FavoritesService,
    private cartService: CartService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private popupRequestMailService: PopupRequestMailService
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
    this.getProductAttributes();
    this.getFavorites();
  }

  getProductAttributes() {
    this.transloco
      .selectTranslateObject('products.product_attributes')
      .subscribe((result) => {
        this.productCategories = result;

        this.category = result.categories.find(
          (e: any) => e.key == this.product.category
        ).value;
        this.metal = result.metals.find(
          (e: any) => e.key == this.product.metal
        ).value;
        this.gemstone = result.gemstones.find(
          (e: any) => e.key == this.product.gemstone
        ).value;
      });

    this.transloco
      .selectTranslateObject('products.products_detail.section_a.paths')
      .subscribe((result) => {
        this.path = result.find(
          (e: any) => e.category == this.product.category
        ).path;
      });
  }

  getMetalIcon(metal: string): any {
    let imagePath = '';
  
    switch (metal) {
      case 'GOLD':
        imagePath = 'assets/images/img_product_detail/metal_gold.jpg';
        break;
      case 'WHITE_GOLD':
        imagePath = 'assets/images/img_product_detail/metal_white_gold.jpg';
        break;
      case 'SILVER':
        imagePath = 'assets/images/img_product_detail/metal_silver.jpg';
        break;
      default:
        break;
    }
  
    return imagePath;
  }

  getMetalText(metal: string): any {
    return this.productCategories.metals.find(
      (e: any) => e.key == metal
    ).value;
  }

  onClickMetal(metal: string) {
    const selectedProduct = this.findProduct(this.product.category!,
      metal, this.product.gemstone!, this.product.packaging!, this.product.measure!);
    if (selectedProduct) {
      this.router.navigate(['products/product-detail', selectedProduct.id]);
    }
  }

  getGemstoneIcon(gemstone: string): any {
    let imagePath = '';
  
    switch (gemstone) {
      case 'PEARL':
        imagePath = 'assets/images/img_product_detail/gemstone_pearl.jpg';
        break;
      case 'BLACK_QUARTZ':
        imagePath = 'assets/images/img_product_detail/gemstone_black_quartz.jpg';
        break;
      default:
        break;
    }
  
    return imagePath;
  }

  getGemstoneText(gemstone: string): any {
    return this.productCategories.gemstones.find(
      (e: any) => e.key == gemstone
    ).value;
  }

  onClickGemstone(gemstone: string) {
    const selectedProduct = this.findProduct(this.product.category!,
      this.product.metal!, gemstone, this.product.packaging!, this.product.measure!);
    if (selectedProduct) {
      this.router.navigate(['products/product-detail', selectedProduct.id]);
    }
  }

  getMeasureText(measure: string): any {
    return this.productCategories.measures.find(
      (e: any) => e.key == measure
    ).value;
  }

  onClickMeasure(measure: string) {
    const selectedProduct = this.findProduct(this.product.category!,
      this.product.metal!, this.product.gemstone!, this.product.packaging!, measure);
    if (selectedProduct) {
      this.router.navigate(['products/product-detail', selectedProduct.id]);
    }
  }

  getPackagingText(packaging: string): any {
    return this.productCategories.packaging.find(
      (e: any) => e.key == packaging
    ).value;
  }

  onClickPackaging(packaging: string) {
    const selectedProduct = this.findProduct(this.product.category!,
      this.product.metal!, this.product.gemstone!, packaging, this.product.measure!);
    if (selectedProduct) {
      this.router.navigate(['products/product-detail', selectedProduct.id]);
    }
  }

  onClickPackagingInfo() {
    this.dialog.open(PopupPackageInfoComponent, {
      autoFocus: false,
      hasBackdrop: true,
      width: !this.isMobile ? '35em' : '80%',
      minWidth: !this.isMobile ? '45em' : '80%',
    });
  }

  findProduct(category: string, metal: string, gemstone: string, packaging: string, measure: string) {
    return this.products.find(product =>
      product.category === category &&
      product.metal === metal &&
      product.gemstone === gemstone &&
      product.packaging === packaging &&
      product.measure === measure
    );
  }

  private async getFavorites() {
    this.loading = true;

    try {
      this.favorites = await this.favoritesService.getFavorites();
    } catch (error) {
      this.favorites = [];
    }

    this.loading = false;
  }

  isFavorite() {
    return (
      this.favorites?.map((e) => e.id).indexOf(this.product.id) > -1 ?? false
    );
  }

  async onClickFavorite() {
    this.popupRequestMailService.resetDialogTimer();

    try {
      if (this.isFavorite()) {
        await this.deleteFavorite();
      } else {
        await this.postFavorite();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onClickAddCart() {
    this.popupRequestMailService.resetDialogTimer();

    try {
      await this.cartService.postCartItem({
        product: this.product,
        shared: true,
      });

      this.addedToCart = true;
      setTimeout(() => {
        this.addedToCart = false;
      }, 2500);

      this.openDialogs();

      window.dataLayer.push({
        event: 'product_added_cart',
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async postFavorite(): Promise<void> {
    await this.favoritesService.postFavorite(this.product);
    this.favorites.push(this.product);
  }

  private async deleteFavorite(): Promise<void> {
    await this.favoritesService.deleteFavorite(this.product.id!);

    this.favorites.splice(
      this.favorites.map((e) => e.id).indexOf(this.product.id),
      1
    );
  }

  private dialogRef: any;
  private dialogSubscription: any;
  private closePopupTimer: NodeJS.Timeout;

  private clearDialogData() {
    clearTimeout(this.closePopupTimer);
    this.dialogSubscription?.unsubscribe();
    this.dialogRef?.close();
  }

  startTimeout(time: number) {
    this.closePopupTimer = setTimeout(() => {
      this.clearDialogData();
    }, time);
  }

  openDialogs() {
    if (!this.authService.isAuthenticated()) {
      const userPreferences: UserPreferences =
        this.localStorageService.userPreferences.get();

      if (userPreferences.email_requested_cart == false) {
        userPreferences.email_requested_cart = true;
        this.localStorageService.userPreferences.set(userPreferences);

        setTimeout(() => {
          this.dialog.open(ProductAddedDialogComponent, {
            panelClass: 'dialog-container-border',
            disableClose: true,
            autoFocus: true,
            width: !this.isMobile ? '25em' : '80%',
          });
        }, 4000);
      }
    }

    if (!this.isMobile) {
      this.clearDialogData();

      this.dialogRef = this.dialog.open(
        PopupMenuCartComponent,
        {
          disableClose: true,
          autoFocus: false,
          hasBackdrop: false,
          position: {
            top: '5em',
            right: '4em',
          },
          data: {},
        }
      );

      this.dialogSubscription =
        this.dialogRef.componentInstance.isFocused.subscribe(
          (isFocused: boolean) => {
            if (isFocused) {
              clearTimeout(this.closePopupTimer);
            } else {
              this.startTimeout(250);
            }
          }
        );

      this.startTimeout(4000);
    }
  }
}
