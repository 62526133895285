import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { UserGuest, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import {
  LocalStorageService,
  UserPreferences,
} from 'src/app/services/local-storage/local-storage.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { UserGuestService } from 'src/app/services/user-guest/user-guest.service';
import { BaseComponent } from 'src/app/shared/components/base-component';
import { PopupRequestMailService } from 'src/app/shared/modules/popup-request-mail/services/popup-request-mail/popup-request-mail.service';

@Component({
  selector: 'app-product-added-dialog',
  templateUrl: './product-added-dialog.component.html',
  styleUrls: ['./product-added-dialog.component.scss'],
})
export class ProductAddedDialogComponent
  extends BaseComponent
  implements OnInit
{
  loading: boolean = false;
  error: string;
  form: FormGroup;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ProductAddedDialogComponent>,
    private userGuestService: UserGuestService,
    @Inject(MAT_DIALOG_DATA) data: any,
    private popupRequestMailService: PopupRequestMailService
  ) {
    super(responsive, transloco);

    this.form = formBuilder.group({
      email: [
        null,
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'submit',
        },
      ],
    });
  }

  ngOnInit() {
    this.popupRequestMailService.cancelDialogTimer();
  }

  getFormController(controller: string): FormControl {
    return this.form.controls[controller] as FormControl;
  }

  onClickCloseDialog() {
    this.dialogRef.close();
    this.popupRequestMailService.launchDialog();
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loading = true;

      const email = this.getFormController('email').value;

      const userGuest: UserGuest = {
        email: email,
        language: this.transloco.getActiveLang() as 'it' | 'en',
      };

      try {
        await this.userGuestService.postUserGuest(userGuest, 'NEWSLETTER');

        this.loading = false;
        this.dialogRef.close();

        this.popupRequestMailService.launchDialog();
      } catch (error) {
        this.error;
      }

      this.loading = false;
    }
  }
}
