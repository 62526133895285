import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomeAboutUsPartnerItem {
  title: string;
  link: string,
  description: string;
  image: string;
}

@Component({
  selector: 'app-home-about-us-partners-item',
  templateUrl: './home-about-us-partners-item.component.html',
  styleUrls: ['./home-about-us-partners-item.component.scss']
})
export class HomeAboutUsPartnersItemComponent extends BaseComponent implements OnInit {
  @Input()
  item: HomeAboutUsPartnerItem;

  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }
}
