import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Product } from 'src/app/openapi/api';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-product-detail-section-b-description',
  templateUrl: './product-detail-section-b-description.component.html',
  styleUrls: ['./product-detail-section-b-description.component.scss']
})
export class ProductDetailSectionBDescriptionComponent extends BaseComponent implements OnInit {
  @Input()
  product: Product;
  
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }
}
