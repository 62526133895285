<div class="content flexible-column-centered" *transloco="let t">
  <div class="separator"></div>

  <h1
    class="title animated-fade-in-from-bottom animation-delay-1 to-animate"
    [ngClass]="{
      'text-2': !isMobile,
      'text-2-small': isSmall,
      'text-2-xsmall': isXSmall
    }"
  >
    {{ t("home.home_page.section_c.title") }}
  </h1>
  <h2
    class="subtitle animated-fade-in-from-bottom animation-delay-2 to-animate"
    [ngClass]="{
      'text-5': !isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
  >
    {{ t("home.home_page.section_c.subtitle") }}
  </h2>

  <app-product-categories [addToAnimateClass]="true"></app-product-categories>

  <div class="separator"></div>
</div>
