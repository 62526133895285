<div
  [ngClass]="!isMobile ? 'header-margin' : 'header-margin-mobile'"
  class="flexible-column-centered"
  *transloco="let t"
>
  <img
    [ngClass]="{
      image: !isMobile,
      'image-mobile-small': isSmall,
      'image-mobile-xsmall': isXSmall
    }"
    src="{{ homePageHeader.imgSrc }}"
  />
  <h1
    [ngClass]="{
      'text-2': !isMobile,
      'text-2-small': isSmall,
      'text-2-xsmall': isXSmall
    }"
    class="title animated-fade-in-from-bottom animation-delay-1"
  >
    {{ t(homePageHeader.titleTranslocoKey) }}
  </h1>
  <p
    class="animated-fade-in-from-bottom animation-delay-2"
    [ngClass]="{
      'text-5 description': !isMobile,
      'description-mobile': isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    [innerHTML]="homePageHeader.descriptionTranslocoKey | transloco"
  ></p>
</div>
