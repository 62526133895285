<ng-container *ngIf="!isMobile; else mobile">
  <div class="fill">
    <div class="image-container">
      <!--<img class="image-fill-cover" src="{{ multimedia[selectedIndex].url }}" /> -->

      <lib-ngx-image-zoom
        class="image-zoom"
        [thumbImage]="multimedia[selectedIndex].url!"
        [fullImage]="multimedia[selectedIndex].url!"
        [magnification]="0.75"
        [enableScrollZoom]="true"
        [enableLens]="true"
        [lensWidth]="200"
        [lensHeight]="200"
      ></lib-ngx-image-zoom>
    </div>

    <div class="flexible images-container">
      <div
        *ngFor="let item of multimedia"
        class="image-miniature-container"
        (click)="selectItem(item)"
      >
        <img class="image-fill-cover" src="{{ item.url }}" />
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="fill">
    <swiper [slidesPerView]="1" [pagination]="true" class="fill">
      <ng-template *ngFor="let item of multimedia" swiperSlide>
        <!-- <img class="image-fill-cover" src="{{ item.url }}" /> -->

        <lib-ngx-image-zoom
          class="image-zoom"
          [thumbImage]="item.url!"
          [fullImage]="item.url!"
          [magnification]="0.75"
          [enableScrollZoom]="true"
          [enableLens]="true"
          [lensWidth]="200"
          [lensHeight]="200"
        ></lib-ngx-image-zoom>
      </ng-template>
    </swiper>
  </div>
</ng-template>
