import { Injectable } from '@angular/core';
import { lastValueFrom, retry } from 'rxjs';
import { Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

const favoritesKey = 'favorites';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(
    private authService: AuthenticationService,
    private userApi: UserService,
    private localStorageService: LocalStorageService
  ) {}

  async getFavorites(): Promise<Product[]> {
    if (this.authService.isAuthenticated()) {
      const favorites = await this.getOnlineFavorites();
      const merged = await this.mergeFavorites(favorites);
      return merged;
    } else {
      return this.localStorageService.favorites.getAll();
    }
  }

  async postFavorite(product: Product): Promise<void> {
    if (this.authService.isAuthenticated()) {
      await this.authService.updateApiCredentials();
      const favorite = await lastValueFrom(
        this.userApi.postUserProfileFavorite(product).pipe(retry(3))
      );
    } else {
      this.localStorageService.favorites.add(product);
    }
  }

  async deleteFavorite(favorite_id: number): Promise<void> {
    if (this.authService.isAuthenticated()) {
      await this.authService.updateApiCredentials();
      const favorite = await lastValueFrom(
        this.userApi.deleteUserProfileFavorite(favorite_id).pipe(retry(3))
      );
    } else {
      this.localStorageService.favorites.delete(favorite_id);
    }
  }

  private async getOnlineFavorites(): Promise<Product[]> {
    await this.authService.updateApiCredentials();
    const favorites = await lastValueFrom(
      this.userApi.getUserProfileFavorites().pipe(retry(3))
    );
    return favorites;
  }

  // Merge online & offline favorites
  // https://stackoverflow.com/a/54134237
  private async mergeFavorites(onlineFavorites: Product[]): Promise<Product[]> {
    let offline = this.localStorageService.favorites.getAll();

    var ids = new Set(onlineFavorites.map((d) => d.id));
    var newElements = offline.filter((d) => !ids.has(d.id));

    var merged = [...onlineFavorites, ...newElements];

    await this.authService.updateApiCredentials();
    for (let item of newElements) {
      await lastValueFrom(
        this.userApi.postUserProfileFavorite(item).pipe(retry(3))
      );
    }

    this.localStorageService.favorites.clear();

    return merged;
  }
}
