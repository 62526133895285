import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface HomeAboutUsSectionBItem {
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-home-about-us-section-b-item',
  templateUrl: './home-about-us-section-b-item.component.html',
  styleUrls: ['./home-about-us-section-b-item.component.scss']
})
export class HomeAboutUsSectionBItemComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }
  @Input()
  item: HomeAboutUsSectionBItem;

  @Input()
  imageFirst: boolean = true;

  items: HomeAboutUsSectionBItem[];

  ngOnInit(): void {
  }
}
