import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { Product, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss'],
})
export class ProductsDetailComponent extends BaseComponent implements OnInit {
  loaded = false;
  error = false;

  product: Product;
  //products with same category of product above
  products: Array<Product>;

  filters = {
    metal: new Set(),
    gemstone: new Set(),
    packaging: new Set(),
    measure: new Set()
  };

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private route: ActivatedRoute,
    private router: Router,
    private userApi: UserService,
    private authService: AuthenticationService,
  ) {
    super(responsive, transloco);

    //https://stackoverflow.com/a/41678403
    route.params.subscribe((params) => {
      try {
        let id = this.getId(params);
        this.retrieveProduct(id);
      } catch (error) {
        if (error instanceof Error && error.message == 'invalid-id') {
          this.navigatePageNotFound();
        }
      }
    });
  }

  ngOnInit(): void {}

  private getId(params: any): number {
    if (params.id != undefined) {
      let id = +params.id!;
      if (isNaN(id)) {
        throw new Error('invalid-id');
      } else {
        return id;
      }
    }

    throw new Error('invalid-id');
  }

  private retrieveProduct(id: number) {
    this.loaded = false;
    
    this.userApi
      .getUserProduct(id)
      .pipe(retry(3))
      .subscribe({
        next: async (product) => {
          this.product = product;
          this.products = await this.getSameCategoryProducts(this.product);
        },
        error: (e) => {
          this.handleError(e);
        },
        complete: () => {
          this.loaded = true;
        },
      });
  }

  private async getSameCategoryProducts(product: Product): Promise<Product[]> {
    await this.authService.updateApiCredentials();
    let products = await lastValueFrom(
      this.userApi.getUserProducts().pipe(retry(3))
    );

    products = products.filter((e) => e.category == product.category);

    products.forEach(product => {
      this.filters.metal.add(product.metal);
      this.filters.gemstone.add(product.gemstone);
      this.filters.packaging.add(product.packaging);
      this.filters.measure.add(product.measure);
    });

    return products;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == HttpStatusCode.NotFound) {
      this.navigatePageNotFound();
    } else {
      this.error = true;
    }
  }

  navigatePageNotFound() {
    this.router.navigate(['/home/page-not-found']);
  }
}
