<header
  [ngClass]="{ header: !isMobile, 'header-mobile': isMobile }"
  *transloco="let t"
>
  <app-core-header-top
    (onOpenMobileMenu)="openMobileMenu()"
  ></app-core-header-top>
  <ng-container *ngIf="!isMobile">
    <app-core-header-bottom></app-core-header-bottom>
  </ng-container>

  <div
    [ngClass]="{
      'menu-mobile-expanded': showMobileMenu,
      'menu-mobile-collapsed': !showMobileMenu
    }"
  >
    <app-core-header-mobile-menu
      (onCloseMenu)="closeMobileMenu()"
      [showMobileMenu]="showMobileMenu"
    ></app-core-header-mobile-menu>
  </div>
</header>
