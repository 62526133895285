import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface MobileMenuItem {
  title: string;
  route: string;
  items: MobileMenuItem[];
}

@Component({
  selector: 'app-core-header-mobile-menu-item',
  templateUrl: './core-header-mobile-menu-item.component.html',
  styleUrls: ['./core-header-mobile-menu-item.component.scss'],
})
export class CoreHeaderMobileMenuItemComponent
  extends BaseComponent
  implements OnInit
{
  @Output() onCloseMenu = new EventEmitter<void>();

  @Input()
  item: MobileMenuItem;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    private router: Router,
  ) {
    super(responsive, transloco);
  }

  ngOnInit(): void {
  }

  onClickMainItem() {
    this.onCloseMenu.emit();
    this.router.navigate([this.item.route]);
  }

  onClickChildItem(item: MobileMenuItem) {
    this.onCloseMenu.emit();
    this.router.navigate([item.route]);
  }
}
