import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { lastValueFrom, retry } from 'rxjs';
import { ContactUs, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

@Component({
  selector: 'app-home-contacts-section-b',
  templateUrl: './home-contacts-section-b.component.html',
  styleUrls: ['./home-contacts-section-b.component.scss'],
})
export class HomeContactsSectionBComponent
  extends BaseComponent
  implements OnInit
{
  public form: FormGroup;

  loading = false;
  error = false;
  completed = false;

  constructor(
    public override responsive: ResponsiveService,
    public override transloco: TranslocoService,
    public formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private userApi: UserService
  ) {
    super(responsive, transloco);

    this.form = formBuilder.group({
      name: [
        null,
        {
          validators: [Validators.required, Validators.maxLength(30)],
          updateOn: 'submit',
        },
      ],
      surname: [
        null,
        {
          validators: [Validators.required, Validators.maxLength(30)],
          updateOn: 'submit',
        },
      ],
      email: [
        null,
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'submit',
        },
      ],
      phone: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern('[- +()0-9]+'), //https://stackoverflow.com/a/65589987
            Validators.minLength(8),
          ],
          updateOn: 'submit',
        },
      ],
      message: [
        null,
        {
          validators: [Validators.required, Validators.maxLength(1000)],
          updateOn: 'submit',
        },
      ],
    });
  }

  ngOnInit(): void {}

  getFormController(controller: string): FormControl {
    return this.form.controls[controller] as FormControl;
  }

  async submit() {
    this.loading = true;

    this.form.markAllAsTouched();

    if (this.form.valid) {
      try {
        const contactUs: ContactUs = {
          email: this.getFormController('email').value,
          name: this.getFormController('name').value,
          surname: this.getFormController('surname').value,
          phone: this.getFormController('phone').value,
          message: this.getFormController('message').value,
        };

        await this.authService.updateApiCredentials();
        await lastValueFrom(
          this.userApi.postUserContactUs(contactUs).pipe(retry(3))
        );

        this.completed = true;
      } catch (error) {
        this.error = true;
      }
    }

    this.loading = false;
  }
}
