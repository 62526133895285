<div [ngClass]="!isMobile ? 'content' : 'content-mobile'" class="margin">
  <p
    [ngClass]="{
      'text-5 description': !isMobile,
      'description-mobile': isMobile,
      'text-5-small': isSmall,
      'text-5-xsmall': isXSmall
    }"
    class="text-content"
    [innerHTML]="contentTranslocoKey | transloco"
  ></p>
</div>
