import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { BaseComponent } from 'src/app/shared/components/base-component';

export interface FormInputField {
  label: string;
  errorMessage: string;
  controller: FormControl;
  type: string;
  maxLength: string;
}

@Component({
  selector: 'app-form-input-field',
  templateUrl: './form-input-field.component.html',
  styleUrls: ['./form-input-field.component.scss']
})
export class FormInputFieldComponent extends BaseComponent implements OnInit {
  constructor(public override responsive: ResponsiveService, public override transloco: TranslocoService) {
    super(responsive, transloco);
  }
  
  @Input()
  input: FormInputField;

  ngOnInit(): void {
  }

  isInvalid(): boolean {
    return this.input.controller.invalid && (this.input.controller.dirty || this.input.controller.touched);
  }
}
