import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { PopupConfirmComponent } from 'src/app/shared/modules/options-share-item/components/popup-confirm/popup-confirm.component';
import { ProductsDetailComponent } from './components/products-detail/products-detail.component';
import { ProductsFavoritesComponent } from './components/products-favorites/products-favorites.component';
import { ProductsHomeComponent } from './components/products-home/products-home.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProductsSearchComponent } from './components/products-search/products-search.component';
import { ProductsGuard } from './guard/products.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProductsHomeComponent,
      },
      {
        path: 'product-list/:id',
        component: ProductsListComponent,
        //TODO remove to restore products-list/1
        canActivate: [ProductsGuard],
      },
      {
        path: 'product-detail/:id',
        component: ProductsDetailComponent,
      },
      {
        path: 'products-favorites',
        component: ProductsFavoritesComponent,
      },
      {
        path: 'products-search',
        component: ProductsSearchComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), MatDialogModule],
  exports: [RouterModule],
})
export class ProductsRoutingModule {
  constructor(private dialog: MatDialog) {}
}
