import { Injectable } from '@angular/core';
import { lastValueFrom, retry } from 'rxjs';
import { UserGuest, UserService } from 'src/app/openapi/api';
import { AuthenticationService } from '../authentication/authentication.service';
import {
  LocalStorageService,
  UserPreferences,
} from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuestService {
  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {}

  async postUserGuest(user: UserGuest, obtainedBy: 'CHARM' | 'NEWSLETTER'): Promise<UserGuest> {
    await this.authService.updateApiCredentials();
    const result = await lastValueFrom(
      this.userService.postUserGuest(user, obtainedBy).pipe(retry(3))
    );

    const userPreferences: UserPreferences =
      this.localStorageService.userPreferences.get();
    userPreferences.email_sent = true;
    this.localStorageService.userPreferences.set(userPreferences);

    return result;
  }
}
